import React, { useState, useEffect } from "react";
// import useSetupData from "hooks/useSetupData";
import adminUser from "assets/images/loginLogo.svg";

import SetupLayout from "./SetUpLayout";
import { useDispatch, useSelector } from "react-redux";
import { handleConfirm } from "redux/setupStore";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { textField } from "components/InputFields";
import { isOrderExist } from "../../api/api";
import * as Yup from "yup";
import messages from "utils/messages";

export default function WelcomeScreen() {
  // const setupData = useSetupData();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const setupStore = useSelector((state) => state.setup);

  useEffect(() => {
    console.log("setupStore.shopifyJson :>> ", setupStore.shopifyJson);
    console.log("setupStore :>> ", setupStore);
    if (setupStore.data.order_id) {
      console.log("comingg");
      isOrderExist(setupStore.data)
        .then((result) => {
          if (!result) {
            navigate("/");
          }
          // console.log("result", result);
        })
        .catch(() => {
          navigate("/");
        })
        .finally(() => {
          // setLoading(false);
        });
    } else {
    }
  }, [setupStore]);
  let given_name = `${setupStore?.shopifyJson?.customer?.first_name} ${setupStore?.shopifyJson?.customer?.last_name}`;

  const WelcomeSchema = Yup.object().shape({
    // name: Yup.string()
    //   .required(messages.required.name)
    //   .trim()
    //   .max(50, messages.maxLength)
    //   .test(
    //     "is-confirm",
    //     (d) => `Name Doesn't Match`,
    //     (value) =>
    //       value == null || value.toLowerCase() === given_name.toLowerCase()
    //   ),
    pfaGender: Yup.string().required("Please Select Gender"),
  });

  const formik = useFormik({
    initialValues: {
      name: setupStore?.data?.name || given_name,
      pfaGender: setupStore?.data?.pfaGender || "",
    },
    validationSchema: WelcomeSchema,

    onSubmit: (values) => {
      const castValues = WelcomeSchema.cast(values);
      handleNext(castValues);
      navigate("/setup/UploadProfile");
    },
  });

  const handleNext = async (values) => {
    console.log("values :>> ", values);
    let payload = {
      data: { name: values.name, pfaGender: values.pfaGender },
    };
    dispatch(handleConfirm(payload));
  };

  let gender_options = [
    { label: "Male", value: "1" },
    { label: "Female", value: "2" },
    { label: "Prefer Not To Say", value: "3" },
  ];
  const getRadioBoxFields = () =>
    gender_options.map((o, i) => (
      <div key={i}>
        <input
          id={o.value}
          type="radio"
          name="pfaGender"
          className="mr-1"
          onChange={formik.handleChange}
          value={o.value}
          checked={formik.values.pfaGender === o.value}
        />
        <label htmlFor={o.value} className="mx-2">
          {o.label}
        </label>
        <br />
      </div>
    ));

  return (
    <SetupLayout heading="Setup your BigPurplePhone">
      <div className="profilebox">
        <div className="userprofile userProfileLogo">
          <img src={adminUser} className="" alt="" title="" />
        </div>
      </div>
      <div className="contentBody fulogin nextBtnBotmWelcome">
        <div className="row fmsignup">
          <div className="col-lg-12">
            <h2 className="topTileHeading">
              Welcome, <span>{given_name}</span>
            </h2>

            <p>
              Setting up your Loved One's BigPurplePhone only takes a few minutes.
              {/* Firstly, let's check we have your name right. */}
              Firstly, Please select your gender.
            </p>
          </div>
          <form onSubmit={formik.handleSubmit}>
            <div className="col-lg-12">
              {/* <div className="mb-3 mt-3">
                {textField(
                  formik,
                  "name",
                  "Please confirm your name",
                  "Name",
                  "name"
                )}
              </div> */}

              <div className="circlebox">
                <div className="form-group mb-3 mt-3 addNextRole">
                  {getRadioBoxFields()}
                  {formik.errors.pfaGender && formik.touched.pfaGender ? (
                    <div className="error">{formik.errors.pfaGender}</div>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="fullBtnBottomFix">
              <button type="submit" className="btn btn-primary w-100  ">
                Next
              </button>
            </div>{" "}
          </form>
        </div>
      </div>
    </SetupLayout>
  );
}
