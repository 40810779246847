import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

export default function useLoginUser() {
  const navigate = useNavigate();
  const params = useParams();
  const user = useSelector((state) => state.user);

  useEffect(() => {
    if (!user.isAuthorized) return navigate("/LovedOneList");

    // if (url.substring(0, 13) != "/authenticate") {
    //   if (!user.isAuthorized) return navigate("/LovedOneList");
    // }
  }, []);

  return user;
}
