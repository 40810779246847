import { createSlice } from "@reduxjs/toolkit";
import { getProfile, getToken, setProfile } from "../utils/localStorage";

export const user = createSlice({
  name: "user",
  initialState: {
    isAuthorized: getToken() ? true : false,
    profile: getProfile(),
  },
  reducers: {
    signin: (state) => {
      console.log("state :>> ", state);
      state.isAuthorized = getToken() ? true : false;
      state.profile = getProfile();
    },

    updateProfileDetails: (state, action) => {
      console.log("action.payload :>> ", action.payload);
      setProfile({ ...getProfile(), ...action.payload });
      state.profile = { ...getProfile(), ...action.payload };
    },
  },
});

export const { signin, updateProfileDetails } = user.actions;

export default user.reducer;
