import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useNavigate, useLocation, NavLink } from "react-router-dom";
import blackTop from "../assets/images/blackTop.svg";
import bigPurplePhoneLogo from "../assets/images/bigPurpleLogo-dark.svg";
import useSelectedLovedOne from "../hooks/useSelectedLovedOne";
import useLoginUser from "../hooks/useLoginUser";
import { getContactDetails } from "../api/api";
import ContactForm from "components/ContactForm";
import { useErrorHandler } from "react-error-boundary";
import loader from "assets/images/loader.gif"


function ContactEdit() {
  const location = useLocation();
  console.log("location", location.state)
  const [isLoading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    id: "",
    name: "",
    email: "",
    phone: "",
    gender: "",
    photo: "",
    photoName: "",
    finalRole: "",
    verified_contact: 0,
    role: {
      fm: 0,
      fa: 0,
      ec: 0,
      hp: 0,
      lo: 0,
      ca: 0,
      pfa: 0,
    },
  });
  const [prevUrl, setPrevUrl] = useState("");
  const handleError = useErrorHandler();
  useEffect(() => {
    setLoading(true);
    getContactDetails(location.state.edited_id)
      .then((result) => {
        const { data } = result;
        console.log("data.data :>> ", data.data);
        setPrevUrl(data.data.photo);
        setFormData({
          editedId: location.state.edited_id,
          id: data.data.id,
          name: data.data.name,
          gender: data.data.gender,
          email: data.data.email,
          phone: data.data.mobile,
          role: data.data.role,
          verified_user: data.data.verified_user,
          finalRole: data.data.final_role,
          verified_contact: data.data.verified_contact,
          is_landline: data.data.is_landline
        });
      })
      .catch((error) => {
        console.log(error);
        return error.response.data.statusCode != 400 ? handleError(error) : "";
      })
      .finally(() => {
        setLoading(false);
      });
  }, [location.state.edited_id]);

  if (isLoading)
    return (
      <div
        style={{
          minHeight: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {/* <Spinner animation="border" variant="primary" size="100px" /> */}
        <div className="loader-container">
          <img src={loader} alt="Loading..." className="custom-loader" />
        </div>
      </div>
    );

  return (
    <div className="pt80">
      <div className="mainPage loginPage">
        <div className="arrowfixed">
          <NavLink to="/SearchContact">
            <img src={blackTop} className="arrowleft" alt="back" />
          </NavLink>
        </div>

        <ContactForm formData={formData} edit prevUrl={prevUrl} />
        <div className="bigpurplebottom text-center py-3 bgWhite">
          <img src={bigPurplePhoneLogo} alt="bigPurpleLogo" />
        </div>
      </div>
    </div>
  );
}

export default ContactEdit;
