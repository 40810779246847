import React from "react";
import defaultImg from "../assets/images/defaultUser.jpg";

export default function LovedOnePhoneName({ loved_one, className }) {
  return (
    <div className={`parent parentheight`}>
      {/* ${className} */}
      <div className="contentBody contentbox mt0" style={{"border-bottom-left-radius":"0" ,"border-bottom-right-radius":"0"}}>
        <div className="d-flex">
          <div className="media">
            <img
              className="align-self-center"
              src={loved_one?.photo ? `${loved_one.photo}` : defaultImg}
              alt={loved_one ? loved_one.first_name : ""}
              title={loved_one ? loved_one.first_name : ""}
            />
            <div className="media-body">
              <h5 className="mt-0 fsrlight">
                <b>{loved_one ? loved_one.first_name : "Loved One"}'s </b> Big
                <b>Purple</b>Phone
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
