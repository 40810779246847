// updateSettingsHandler.js
// import { store } from './redux/store'; // Ensure this path is correct
// import { updateSettings } from './redux/settingsSlice';

import { updateSettingsSlice } from "redux/settingsSlice";
import store from "redux/store";

export const handleSettingsUpdate = (data) => {
  store.dispatch(updateSettingsSlice(data));
};
