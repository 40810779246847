import React from "react";
import user1 from "../../assets/images/user1.svg";

export default function SharePhotos() {
  return (
    // <div className="pt80">
    //   <div className="mainPage">
    <div className="contentBody contentboxgray text-center positioncenter">
      <div className="contentbodypadd pt-4">
        <div className="col-md-12">
          <div className="popupbox">
            <div className="popcontent">
              <h2 className="fs24" style={{ textAlign: "left" }}>
                Select
              </h2>

              <div className="media boxmediawhite mt-1 m-0 slectBlogNormal p-0">
                <div className="checkBoxSelect d-flex w-100">
                  <input
                    type="checkbox"
                    id="one"
                    style={{ visibility: "collapse" }}
                  />
                  <label for="one" className="d-flex ">
                    <div className="imghoverbox">
                      <img
                        className="align-self-center imageover"
                        src={user1}
                        alt=""
                        title=""
                      />
                    </div>
                    <div className="media-body">
                      <h5 className="mt-0 mb-0">Jan</h5>
                    </div>
                  </label>
                </div>
              </div>

              <div className="media boxmediawhite m-0  slectBlogNormal p-0">
                <div className="checkBoxSelect d-flex w-100">
                  <input
                    type="checkbox"
                    id="two"
                    style={{ visibility: "collapse" }}
                  />
                  <label for="two" className="d-flex ">
                    <div className="imghoverbox">
                      <img
                        className="align-self-center imageover"
                        src={user1}
                        alt=""
                        title=""
                      />
                    </div>
                    <div className="media-body">
                      <h5 className="mt-0 mb-0">Johnson</h5>
                    </div>
                  </label>
                </div>
              </div>

              <div className="media boxmediawhite m-0  slectBlogNormal p-0">
                <div className="checkBoxSelect d-flex w-100">
                  <input
                    type="checkbox"
                    id="there"
                    style={{ visibility: "collapse" }}
                  />
                  <label for="there" className="d-flex ">
                    <div className="imghoverbox">
                      <img
                        className="align-self-center imageover"
                        src={user1}
                        alt=""
                        title=""
                      />
                    </div>
                    <div className="media-body">
                      <h5 className="mt-0 mb-0">Austin</h5>
                    </div>
                  </label>
                </div>
              </div>

              <button type="button" className="btn btn-primary w-100 mt-3">
                Send
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    //   </div>
    // </div>
  );
}
