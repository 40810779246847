import { notifyLoRejectCall } from "api/api";
import React, { useRef, useState, useEffect } from "react";
import useLoginUser from "hooks/useLoginUser";

const VideoJitsi = ({
  room_id,
  is_join,
  handleJoinParticipant,
  handleHangupCall,
  users,
  params,
}) => {
  const jitsiContainerId = "jitsi-container-id";
  const [jitsi, setJitsi] = React.useState(false);
  const { profile } = useLoginUser();

  const loadJitsiScript = () => {
    let resolveLoadJitsiScriptPromise = null;

    const loadJitsiScriptPromise = new Promise((resolve) => {
      resolveLoadJitsiScriptPromise = resolve;
    });

    const script = document.createElement("script");
    script.src = "https://jitsi.bigpurplephone.com/external_api.js";
    script.async = true;
    script.onload = () => resolveLoadJitsiScriptPromise(true);
    document.body.appendChild(script);

    return loadJitsiScriptPromise;
  };

  const initialiseJitsi = async () => {
    if (!window.JitsiMeetExternalAPI) {
      await loadJitsiScript();
    }

    const _jitsi = new window.JitsiMeetExternalAPI("jitsi.bigpurplephone.com", {
      parentNode: document.getElementById(jitsiContainerId),
      roomName: room_id,
      configOverwrite: {
        subject: "BigPurplePhone",
        hideConferenceSubject: false,
        deeplinking: { disabled: true },
        prejoinPageEnabled: false,
        disableDeepLinking: true,
        disableDeepLinking: true,
        toolbarButtons: [
          "camera",
          "closedcaptions",
          "fullscreen",
          "hangup",
          "microphone",
          "toggle-camera",
          "tileview",
        ],
        backgroundImageUrl: profile?.photo,
        // Array with avatar URL prefixes that need to use CORS.
        corsAvatarURLs: [profile?.photo],

        // Setup for Gravatar-compatible services.
        // gravatar: {
        //   // Defaults to Gravatar.
        //   baseUrl: profile?.photo,
        //   // True if Gravatar should be disabled.
        //   disabled: false,
        // },
      },
      interfaceConfigOverwrite: {
        DISABLE_JOIN_LEAVE_NOTIFICATIONS: true,
        TILE_VIEW_MAX_COLUMNS: 5,
      },
      userInfo: {
        displayName: profile?.name,
        avatarURL: profile?.photo,
        avatar: profile?.photo,
      },
    });

    setJitsi(_jitsi);
  };

  React.useEffect(() => {
    initialiseJitsi();

    // return () => jitsi?.dispose?.();
  }, []);

  console.log("room_id in jitsi component :>> ", room_id);
  console.log("is_join in jitsi component :>> ", is_join);
  console.log("params :>> ", params);
  useEffect(() => {
    if (jitsi) {
      // jitsi?.executeCommand("setTileView", { enabled: true });
      jitsi?.setTileView?.({ enabled: true });

      jitsi?.on?.("participantJoined", (payload) => {
        console.log("payload participantJoined :>> ", payload);
        handleJoinParticipant(payload);
      });
      jitsi?.on?.("participantLeft", (payload) => {
        let number_participants = jitsi.getNumberOfParticipants();

        console.log(
          "payload participantLeft :>> ",
          payload,
          number_participants
        );
        // if (number_participants <= 1) {
        //   let data = {
        //     room_id,
        //   };
        //   notifyLoRejectCall(data).then((res) => {
        //     console.log("res :>> ", res);
        //   });

        //   handleHangupCall(payload);
        // } else {
        //   console.log("ppppppppppppppppppp");
        // }
      });

      jitsi?.on?.("videoConferenceLeft", (payload) => {
        console.log("payload videoConferenceLeft :>> ", payload);
        let number_participants = jitsi.getNumberOfParticipants();
        console.log(
          "payload videoConferenceLeft number_participants :>> ",
          number_participants
        );
        handleHangupCall(payload);
      });
      jitsi?.on?.("readyToClose", (payload) => {
        console.log("payload readyToClose :>> ", payload);

        handleHangupCall(payload);
      });

      // jitsi?.on?.("filmstripDisplayChanged", (event) => {
      //   console.log("event :>> ", event);
      //   if (!event.visible) {
      //     jitsi.executeCommand("toggleFilmStrip");
      //   }
      // });

      jitsi?.on?.("tileViewChanged", (payload) => {
        let number_participants = jitsi.getNumberOfParticipants();

        console.log(
          "payload tileViewChanged :>> ",
          payload,
          number_participants
        );
      });
    }
  }, [jitsi]);

  return (
    <div
      id={jitsiContainerId}
      style={{ height: "85vh", width: "100%" }}
      className={`${is_join ? "callJoining" : "callWaiting"}`}
    />
  );
};

export default VideoJitsi;
