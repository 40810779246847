import React from "react";
// import useSetupData from "hooks/useSetupData";
import SetupLayout from "./SetUpLayout";
import { useDispatch, useSelector } from "react-redux";
import { handleConfirm } from "redux/setupStore";
import { useNavigate } from "react-router-dom";
import UploadProfilePhoto from "components/UploadProfilePhoto";

import { useFormik } from "formik";
import { textField, selectField, PhoneComponent } from "components/InputFields";
import * as Yup from "yup";
import messages from "utils/messages";
import { inputValidation } from "utils/validations";
export default function PortForm() {
  // const setupData = useSetupData();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const setupStore = useSelector((state) => state.setup);

  let {
    data: { lovedOne },
  } = setupStore;

  let carrier_options = [
    { name: "Optus", value: "Optus" },
    { name: "Telstra", value: "Telstra" },
    { name: "Virgin Mobile", value: "Virgin Mobile" },
    { name: "People Telecom", value: "People Telecom" },
    { name: "Soul TPG", value: "Soul TPG" },
    { name: "3 Mobile", value: "3 Mobile" },
    { name: "DODO", value: "DODO" },
    { name: "Austar", value: "Austar" },
    { name: "M2 Telco", value: "M2 Telco" },
    { name: "Savvytel", value: "Savvytel" },
    { name: "SavvtMax", value: "SavvtMax" },
    { name: "Woolworths Everyday Mobile", value: "Woolworths Everyday Mobile" },
    { name: "Southern Cross Telco", value: "Southern Cross Telco" },
    { name: "Vodafone", value: "Vodafone" },
  ];

  const ContactSchema = Yup.object().shape({
    mobile_type: Yup.string(),
    account_holder: Yup.string().required(messages.required.name).trim(),
    account_holder_dob: Yup.string().when("mobile_type", {
      is: "postpaid",
      then: Yup.string().required(messages.required.dob),
    }),
    prev_carrier: Yup.string().required(messages.required.carrier),
    prev_account_no: Yup.string().when("mobile_type", {
      is: "postpaid",
      then: Yup.string().required(messages.required.accountNo).trim(),
    }),
    phone: Yup.string()
      .required(messages.required.phone)
      .test({
        name: "validator-custom-name",
        // eslint-disable-next-line object-shorthand
        test: function (value) {
          return inputValidation(value, this);
        },
      }),
  });
  let mobile_type = setupStore?.data?.lovedOne?.mobile_type;
  const formik = useFormik({
    initialValues: {
      mobile_type: setupStore?.data?.lovedOne?.mobile_type,
      account_holder: setupStore?.data?.lovedOne?.account_holder || "",
      account_holder_dob:
        mobile_type && mobile_type == "postpaid"
          ? setupStore?.data?.lovedOne?.account_holder_dob
          : "",
      prev_carrier: setupStore?.data?.lovedOne?.prev_carrier || "",
      prev_account_no:
        mobile_type && mobile_type == "postpaid"
          ? setupStore?.data?.lovedOne?.prev_account_no
          : "",
      phone: setupStore?.data?.lovedOne?.phone || "",
    },
    validationSchema: ContactSchema,

    onSubmit: (values) => {
      const castValues = ContactSchema.cast(values);
      handleNext(castValues);
    },
  });

  const handleNext = async (values) => {
    let payload = {
      data: {
        lovedOne: {
          ...setupStore.data.lovedOne,
          ...values,
        },
      },
    };

    console.log("payload :>> ", payload);
    dispatch(handleConfirm(payload));
    navigate(`/setup/UploadId`);
  };

  return (
    <SetupLayout
      heading={`${lovedOne.first_name}'s Mobile Number`}
      backNav={"selectMobileType"}
    >
      <UploadProfilePhoto handleUpload={false} value={lovedOne.loPrevUrl} />

      <div className="profilTileHeading ">{lovedOne.first_name}</div>

      <div className="contentBody fulogin">
        <div className="row fmsignup">
          <div className="col-lg-12">
            <h2 className="topTileHeading">
              Port {lovedOne.first_name}'s old mobile number to BigPurplePhone
            </h2>

            <p>
              We will port {lovedOne.first_name}'s old mobile number to{" "}
              {lovedOne.gender == 1 ? "his" : "her"} new BigPurplePhone for you.
              We just need a few details:
            </p>
          </div>
          <form onSubmit={formik.handleSubmit}>
            <div className="col-lg-12">
              <div className="mb-3 mt-3">
                <PhoneComponent
                  formik={formik}
                  name={"phone"}
                  label={false}
                  placeholder={"The mobile number you wish to keep"}
                  icon={"phone"}
                />
              </div>
            </div>

            <div className="col-lg-12">
              <div className="mb-3 mt-3">
                {textField(
                  formik,
                  "account_holder",
                  false,
                  // "Account Holders Name as it appears on their mobile phone bill",
                  "Account Holders Name",
                  "name"
                )}
              </div>
            </div>

            {formik.values.mobile_type == "postpaid" ? (
              <div className="col-lg-12">
                <div className="mb-3 mt-3">
                  {textField(
                    formik,
                    "account_holder_dob",
                    false,
                    "The account holders date of birth (DDMMYYYY)",
                    "dob",
                    "date"
                  )}
                </div>
              </div>
            ) : (
              ""
            )}

            <div className="col-lg-12">
              <div className="mb-3 mt-3 leftArrowSelect">
                {selectField(
                  formik,
                  "prev_carrier",
                  false,
                  "Who is their previous carrier?",
                  carrier_options,
                  "downArrow"
                )}
              </div>
            </div>

            {formik.values.mobile_type == "postpaid" ? (
              <div className="col-lg-12">
                <div className="mb-3 mt-3">
                  {textField(
                    formik,
                    "prev_account_no",
                    false,
                    "Previous account number",
                    "email"
                  )}
                </div>
              </div>
            ) : (
              ""
            )}
            <p>
              This won't take effect until the day of delivery so{" "}
              {lovedOne.first_name} still has{" "}
              {lovedOne.gender == 1 ? " his" : " her"} mobile number until then.
            </p>
            <div className="row">
              <div className="d-flex mt-3 mb-3">
                <button type="submit" className="btn btn-primary w-100  ">
                  Next
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </SetupLayout>
  );
}
