import { getFullUrl } from "api/api";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

export default function ShortUrl() {
    const navigate = useNavigate();
    const param = useParams();
    useEffect(() => {
        getFullUrl(param.short_url)
            .then((response) => {
                navigate(response)
            }).catch((error) => {
                console.log('error', error)
                navigate("/NotFound")
            })
    }, [])
    return "";

}