import { setToken, getToken, setProfile } from "../utils/localStorage";
import { toast } from "react-toastify";
import { baseUrl } from "utils/service";
import { wrapper } from "utils/helpers";
import { GET, PUT, POST, DELETE } from "./request";

const header = {};

export const login = async (data) => {
  return wrapper(async () => {
    const response = await POST(`/auth/login`, { data });
    setToken(response.data.data.token);
    setProfile(response.data.data);
    return response;
  }, true);
};

export const readPWA = async () => {
  return wrapper(async () => {
    const response = await PUT(`/user/editPwaPopup`);
    return response.data.data;
  });
};

export const getDataForVerification = async (string) => {
  return wrapper(async () => {
    const response = await GET(
      `/user/getDataForVerification?verify_string=${string}`
    );
    return response.data;
  });
};

export const isOrderExist = async (params) => {
  return wrapper(async () => {
    const response = await GET(`/user/getIsOrderExist`, {
      params,
    });
    return true;
  });
};
export const updateVerify = async (data) => {
  return wrapper(async () => {
    const response = await PUT(`/user/updateVerify`, { data });
    return response.data.data;
  }, true);
};
export const setDeviceToken = async (data) => {
  return wrapper(async () => {
    const response = await PUT(`/user/setDeviceToken`, { data });

    return response;
  });
};

export const uploadUserCsv = async (data) => {
  return wrapper(async () => {
    const response = await POST(`/user/importUser`, { data });

    return response;
  });
};

export const searchLovedOne = async (searchParam) => {
  return wrapper(async () => {
    const response = await GET(
      `/user/searchLovedOneList?search=${searchParam}`
    );
    return response.data.data;
  });
};

export const getFullUrl = async (shortUrl) => {
  return wrapper(async () => {
    const response = await GET(`/videoCall/getFullUrl?short_url=${shortUrl}`);
    return response.data.data;
  });
};

export const searchLovedOneContactList = async (lovedOneId, searchParam) => {
  const fn = async () => {
    const response = await GET(
      `/user/searchContactList?lovedOneId=${lovedOneId}&search=${searchParam}`
    );
    return response.data.data;
  };
  return wrapper(fn);
};

export const getSettings = async (lovedOneId) => {
  const fn = async () => {
    const response = await GET(`/settings?lovedOneId=${lovedOneId}`);
    return response.data.data;
  };
  return wrapper(fn);
};

export const updateSettings = async (data) => {
  return wrapper(async () => {
    const response = await PUT(`/settings`, {
      data,
    });
    toast(response.data.data.message, { type: "success" });

    return response;
  }, true);
};

export const updateSpeakerSetting = async (data) => {
  return wrapper(async () => {
    const response = await PUT(`/settings/updateSpeaker`, {
      data,
    });
    toast(response.data.data.message, { type: "success" });

    return response;
  }, true);
};

export const updateFindMyDevice = async (data) => {
  return wrapper(async () => {
    const response = await POST("/settings/SendFindMyDeviceNotification", {
      data,
    });
    toast(response.data.data.message, { type: "success" });
    return response;
  }, true);
};

export const updateTalkBackSetting = async (data) => {
  return wrapper(async () => {
    const response = await PUT("/settings/UpdateTalkBack", {
      data,
    });
    toast(response.data.data.message, { type: "success" });
    return response;
  }, true);
};

export const updateSelectToSpeak = async (data) => {
  return wrapper(async () => {
    const response = await PUT("/settings/UpsertSelectToSpeak", {
      data,
    });
    toast(response.data.data.message, { type: "success" });
    return response;
  }, true);
};

export const resendInvite = async (data) => {
  return wrapper(async () => {
    const response = await GET(
      `/user/sendInvite?lovedOneId=${data.lovedOneId}&id=${data.id}`
    );
    toast("Invitation sent successfully", { type: "success" });

    return response;
  }, true);
};

export const getVerifiedContactList = async (params) => {
  const fn = async () => {
    const response = await GET(`/user/getVerifiedContact`, { params });
    localStorage.removeItem('callingUrl');
    return response.data.data;
  };
  return wrapper(fn);
};

export const getContactDetails = async (editedId) => {
  const fn = async () => {
    const response = await GET(`/user/getContactDetail?editedId=${editedId}`);
    return response;
  };
  return wrapper(fn);
};

export const editContactDetails = async (data) => {
  console.log("dataaa line no 178", data);
  return wrapper(async () => {
    const response = await PUT(`/user/editContact`, { data });
    toast("Contact updated successfully", { type: "success" });
    return response;
  }, true);
};

export const sendOtpMobile = async (data) => {
  return wrapper(async () => {
    const response = await POST(`/user/forgetPasswordMobile`, {
      data,
    });
    toast(response.data.data.message, { type: "success" });

    return response;
  }, true);
};

export const verifyOtpMobile = async (data) => {
  return wrapper(async () => {
    const response = await PUT(`/user/verifyOtp`, {
      data,
    });
    return response;
  });
};

export const resetPassword = async (data) => {
  return wrapper(async () => {
    const response = await PUT(`/user/resetPassword`, {
      data,
    });
    return response;
  });
};

export const deleteContact = async (lovedOneId, contactId, editedId) => {
  return wrapper(async () => {
    const response = await DELETE(
      `/user/deleteContact?lovedOneId=${lovedOneId}&userId=${contactId}&deletedId=${editedId}`
    );
    return response;
  });
};

// ===============================Sprint 2=========================================================

export const editLovedOneDetails = async (data) => {
  return wrapper(async () => {
    const response = await PUT(`/user/lovedOneProfileEdit`, { data });
    toast(response.data.message, { type: "success" });

    return response;
  });
};

export const editLovedOneMenu = async (data) => {
  return wrapper(async () => {
    const response = await PUT(`/menu/editLovedOneMenu`, { data });
    return response;
  });
};

export const sendInvite = async (params) => {
  return wrapper(async () => {
    const response = await GET(`/user/sendInvite`, {
      params,
    });

    return response;
  });
};

export const addNewLovedOneContact = async (data, lovedOneId) => {
  return wrapper(async () => {
    const response = await POST(`/user/addContact?lovedOneId=${lovedOneId}`, {
      data,
    });

    return response;
  }, true);
};

export const removeBigPurplePhone = async (data) => {
  return wrapper(async () => {
    const response = await PUT(`/user/removeBigPurplePhone`, { data });

    return response;
  });
};

export const updatePFAUserDetails = async (data) => {
  return wrapper(async () => {
    const response = await PUT(`/user/editProfile`, { data });
    toast(response.data.message, { type: "success" });
    return response;
  }, true);
};

export const changePFAPassword = async (data) => {
  console.log("data in change password", data);
  return wrapper(async () => {
    const response = await PUT(`/user/profileChangePassword`, {
      data,
    });
    toast(response.data.message, { type: "success" });
    return response;
  });
};
// =======================================Sprint 3=======================================================

//#region reminder apis

export const addReminder = async (data) => {
  return wrapper(async () => {
    const response = await POST(`/reminder/add`, { data });
    toast(response.data.data, { type: "success" });

    return response;
  }, true);
};

export const updateReminder = async (data) => {
  return wrapper(async () => {
    const response = await PUT(`/reminder/edit`, { data });
    toast(response.data.data, { type: "success" });
    return response;
  }, true);
};
export const getReminder = async (params) => {
  return wrapper(async () => {
    const response = await GET(`/reminder/reminders`, {
      params,
    });
    return response;
  });
};

export const changeReminderStatus = async (data) => {
  return wrapper(async () => {
    const response = await PUT(`/reminder/changeStatus`, { data });
    toast(response.data.data, { type: "success" });

    return response;
  });
};

export const deleteReminder = async (data) => {
  return wrapper(async () => {
    const response = await DELETE(`/reminder/delete`, { data });
    toast(response.data.data, { type: "success" });

    return response;
  });
};

export const deleteAnnualReminder = async (data) => {
  return wrapper(async () => {
    const response = await DELETE(`/annualReminder/delete`, { data });
    toast(response.data.data, { type: "success" });

    return response;
  });
};

//#endregion

export const getCertificate = async (lovedOneId, key) => {
  return wrapper(async () => {
    const response = await GET(
      `/certificate/list?lovedOneId=${lovedOneId}&key=${key}`
    );

    return response;
  });
};

export const addCertificate = async (data) => {
  return wrapper(async () => {
    const response = await POST(`/certificate/addfile`, { data });
    toast(response.data.message, { type: "success" });

    return response;
  });
};

export const deleteCertificate = async (data) => {
  return wrapper(async () => {
    const response = await PUT(`/certificate/delete`, { data });
    toast(response.data.message, { type: "success" });

    return response;
  });
};

// =============================photos===========================================================

export const getGallaryPhotos = async ({ user_id, lovedOneId, role }) => {
  console.log("page, data, lovedOneId, role ", user_id, lovedOneId, role);
  return wrapper(async () => {
    const response = await GET(`/gallery/pwaGalleryList`, {
      params: { user_id, lovedOneId, role },
    });

    return response;
  });
};

export const getGallaryPhotoById = async (params) => {
  return wrapper(async () => {
    const response = await GET(`/gallery/getPhotoById`, {
      params,
    });

    return response;
  });
};

export const getLoGalleryPhotos = async (params) => {
  return wrapper(async () => {
    const response = await GET(`/gallery/galleryList`, {
      params,
    });
    return response;
  });
};

export const deletePFAphotos = async (data) => {
  return wrapper(async () => {
    const response = await PUT(`/gallery/deletePhoto`, { data });
    toast(response.data.message, { type: "success" });

    return response;
  });
};
export const updateSharePhotos = async (data) => {
  return wrapper(async () => {
    const response = await PUT(`/gallery/updateSharedPhotoChecked`, { data });
    // toast(response.data.message, { type: "success" });

    return response;
  });
};

export const likeDislikeFAphotos = async (data) => {
  console.log("data in api", data);
  return wrapper(async () => {
    const response = await PUT(`/gallery/photoLikeDislike`, { data });
    return response;
  });
};

export const addPhotos = async (data) => {
  return wrapper(async () => {
    const response = await POST(`/gallery/addPhoto`, { data });
    toast(response.data.data, { type: "success" });

    return response;
  }, true);
};

export const getLOMenuEditable = async (lovedOneId) => {
  return wrapper(async () => {
    const response = await GET(`/menu/getAllMenuPfa?id=${lovedOneId}`, {
      headers: { id: lovedOneId },
    });
    return response;
  });
};

export const getDeviceDetails = async (lovedOneId) => {
  return wrapper(async () => {
    const response = await GET(
      `/user/getDeviceDetails?lovedOneId=${lovedOneId}`
    );
    return response;
  });
};

//#region video call

export const sendVideoRequest = async (data) => {
  return wrapper(async () => {
    const response = await POST(`/videoCall/addVideoCall`, { data });
    // toast(response.data.data, { type: "success" });

    return response;
  }, true);
};

export const sendGroupVideoRequest = async (data) => {
  return wrapper(async () => {
    const response = await POST(`/videoCall/groupVideoCall`, { data });
    // toast(response.data.data, { type: "success" });

    return response;
  }, true);
};

export const rejectCall = async (data) => {
  return wrapper(async () => {
    const response = await POST(`/videoCall/notifyCallReject`, { data });
    // toast(response.data.data, { type: "success" });
    localStorage.removeItem('callingUrl');
    return response;
  }, true);
};

export const notifyLoRejectCall = async (data) => {
  return wrapper(async () => {
    const response = await POST(`/videoCall/notifyCallHangup`, { data });
    // toast(response.data.data, { type: "success" });
    return response;
  }, true);
};

export const callStatus = async (params) => {
  return wrapper(async () => {
    const response = await GET(`/videoCall/callStatus`, {
      params,
    });

    return response;
  }, true);
};
//#endregion

//#region annual reminder

export const addAnnualReminder = async (data) => {
  return wrapper(async () => {
    const response = await POST(`/annualReminder/add`, { data });
    toast(response.data.message, { type: "success" });

    return response;
  }, true);
};

export const updateAnnualReminder = async (data) => {
  return wrapper(async () => {
    const response = await PUT(`/annualReminder/edit`, { data });
    toast(response.data.message, { type: "success" });

    return response;
  }, true);
};
export const getAnnualReminder = async (params) => {
  return wrapper(async () => {
    const response = await GET(`/annualReminder/list`, {
      params,
    });
    return response;
  });
};

export const changeAnnualReminderStatus = async (data) => {
  return wrapper(async () => {
    const response = await PUT(`/annualReminder/changeIsActive`, { data });
    toast(response.data.message, { type: "success" });

    return response;
  });
};

//#endregion

export const getLovedOneById = async (params) => {
  return wrapper(async () => {
    header.id = params.lovedOneId;
    const response = await GET(`/user/lovedOneProfile`, {
      params,
      headers: { id: params.lovedOneId },
    });
    return response;
  });
};

//#region setuwalkthrough

export const setupWalkThrough = async (data) => {
  return wrapper(async () => {
    const response = await POST(`/user/setupNewPhone`, { data });
    return response;
  });
};

export const getShopifyOrder = async (params) => {
  return wrapper(async () => {
    const response = await GET(`/user/shopify`, {
      params,
    });
    return response;
  }, true);
};

//#endregion

//#region newregion

export const getUserProfile = async (params) => {
  return wrapper(async () => {
    const response = await GET(`/user/userProfile`, {
      params,
    });
    return response;
  });
};

export const getUserProfileWithLovedOne = async (params) => {
  return wrapper(async () => {
    const response = await GET(`/user/userProfileWithLovedOne`, {
      params,
    });
    return response;
  });
};
export const logout = async (data) => {
  return wrapper(async () => {
    const response = await POST(`/auth/logout`, { data });
    return response;
  });
};

//#endregion

export const getWebSetting = async () => {
  return wrapper(async () => {
    const response = await GET(`/settings/web-setting`);
    return response;
  });
};

export const getUserDetail = async (params) => {
  return wrapper(async () => {
    const response = await GET(`/user/getUserDetail`, {
      params,
    });
    return response.data.data;
  });
};
//#Web Url apis
export const getWebUrlDetails = async (params) => {
  return wrapper(async () => {
    const response = await GET(
      `/menu/getPfaExternalMenu?country=${params.country}`,
      {
        headers: { id: params.id },
      }
    );
    return response;
  });
};
export const updateWebUrlDetails = async (data) => {
  return wrapper(async () => {
    const response = await POST(`/menu/savePfaExternalMenu`, { data });
    return response;
  });
};

export const setPin = async (data) => {
  return wrapper(async () => {
    const response = await POST(`/menu/setPin`, { data });
    return response;
  });
};

export const setAppProtected = async (data) => {
  return wrapper(async () => {
    const response = await POST(`/menu/setAppProtected`, { data });
    return response;
  });
};

export const getLovedOneLocation = async (data) => {
  return wrapper(async () => {
    const response = await POST(`user/getLovedOneLocation`, { data });
    return response;
  });
};

export const getCarrierList = async () => {
  return wrapper(async () => {
    // header.id = params.lovedOneId;
    const response = await GET(`/settings/getCarerList`);
    return response;
  });
};

export const lovedOneSelectedCarrier = async (payload) => {
  console.log("payload in api", payload);
  return wrapper(async () => {
    const response = await GET(
      `/settings/lovedOneSelectedCarrier?lovedOneId=${payload.LovedOneId}`
    );
    return response;
  });
};

export const setCarrier = async (data) => {
  return wrapper(async () => {
    const response = await POST(`/settings/AssignLovedOneCarer`, { data });
    return response;
  });
};
