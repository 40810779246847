import React, { useState, useEffect } from "react";

const PinInput = () => {
  const [pin, setPin] = useState("");

  useEffect(() => {
    const savedPin = localStorage.getItem("pin");
    if (savedPin) {
      setPin(savedPin);
    }
  }, []);

  const validateAndSetPin = (newPin) => {
    if (/^\d{0,4}$/.test(newPin)) {
      if (newPin.length === 4) {
        setPin(newPin);
        localStorage.setItem("pin", newPin); // Save PIN to localStorage
      } else {
        setPin(newPin);
      }
    }
  };

  const handlePinChange = (newPin) => {
    validateAndSetPin(newPin);
  };

  const handlePinPadClick = (digit) => {
    if (pin.length < 4) {
      handlePinChange(pin + digit);
    }
  };

  const handleDelete = () => {
    handlePinChange(pin.slice(0, -1));
  };

  return (
    <div className="pin-input-container">
      <input
        className="inputclass"
        type="password"
        value={pin}
        onChange={(e) => handlePinChange(e.target.value)}
        maxLength={4}
      />
      <div className="pin-pad">
        {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((digit) => (
          <button
            className="buttonpin"
            key={digit}
            onClick={() => handlePinPadClick(digit)}
          >
            {digit}
          </button>
        ))}
        <button className="buttonpin" onClick={handleDelete}>
          Delete
        </button>
        <button className="buttonpin" onClick={() => handlePinPadClick(0)}>
          0
        </button>
      </div>
    </div>
  );
};

export default PinInput;
