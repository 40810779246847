import React, { useState, useEffect } from "react";
// import useSetupData from "hooks/useSetupData";
import SetupLayout from "./SetUpLayout";
import { useDispatch, useSelector } from "react-redux";
import { handleConfirm } from "redux/setupStore";
import { useLocation, useNavigate } from "react-router-dom";
import UploadProfilePhoto from "components/UploadProfilePhoto";
import { useFormik } from "formik";
import { textField } from "components/InputFields";
import * as Yup from "yup";
import messages from "utils/messages";
export default function LoLocation() {
  // const setupData = useSetupData();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const setupStore = useSelector((state) => state.setup);

  const LocationSchema = Yup.object().shape({
    street: Yup.string().required(messages.required.street).trim(),
    suburb: Yup.string().required(messages.required.suburb).trim(),
    state: Yup.string().required(messages.required.state).trim(),
    postal_code: Yup.number().required(messages.required.postal_code),
    // .max(9999, "Postal Code Should be 4 digit")
    // .min(1000, "Postal Code Should be 4 digit"),
  });

  const formik = useFormik({
    initialValues: {
      street: setupStore?.data?.lovedOne?.street || "",
      suburb: setupStore?.data?.lovedOne?.suburb || "",
      state: setupStore?.data?.lovedOne?.state || "",
      postal_code: setupStore?.data?.lovedOne?.postal_code || "",
    },
    validationSchema: LocationSchema,

    onSubmit: (values) => {
      console.log("values :>> ", values);
      const castValues = LocationSchema.cast(values);
      handleNext(castValues);
    },
  });

  const handleNext = async (values) => {
    let payload = {
      data: {
        lovedOne: {
          ...setupStore.data.lovedOne,
          ...values,
        },
      },
    };
    dispatch(handleConfirm(payload));
    // navigate("/setup/SelectMobile");
    navigate("/setup/AllDone");
  };

  return (
    <SetupLayout heading="My Loved One" backNav={"ShowLoProfilePhoto"}>
      <div className="">
        <UploadProfilePhoto
          handleUpload={false}
          value={setupStore.data.lovedOne.loPrevUrl}
        />

        <div className="profilTileHeading">
          {setupStore.data.lovedOne.first_name}
        </div>

        <div className="contentBody fulogin">
          <div className="row fmsignup">
            <div className="col-lg-12">
              <h2 className="topTileHeading">
                Set {setupStore.data.lovedOne.first_name}'s <br />
                Location
              </h2>

              <p>
                {`We always show ${setupStore.data.lovedOne.first_name} ${
                  setupStore.data.lovedOne.gender == 1 ? "his" : "her"
                } local
                weather. This is the address we sent their phone too. Is this
                the best location to check the weather?`}
              </p>
            </div>
            <form onSubmit={formik.handleSubmit}>
              <div className="col-lg-12">
                <div className="mb-3">
                  {textField(formik, "street", "Address", "Address", "city")}
                </div>
              </div>

              <div className="col-lg-12">
                <div className="mb-3">
                  {textField(formik, "suburb", "Suburb", "Suburb", "city")}
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-6">
                  {textField(formik, "state", "State", "State", "street")}
                </div>
                <div className="col-6">
                  {textField(
                    formik,
                    "postal_code",
                    "Postcode",
                    "Postcode",
                    "street",
                    "number",
                    "4"
                  )}
                </div>
              </div>

              <div className="row">
                <div className="d-flex mt-3 mb-3">
                  <button type="submit" className="btn btn-primary w-100  ">
                    OK
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </SetupLayout>
  );
}
