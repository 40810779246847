import React, { useEffect, useState } from "react";
import logo from "assets/images/purple_circle_logo.png";
import purpleLogo from "assets/images/purple_arrow.png";
import "assets/scss/pwa.scss";
import { readPWA } from "api/api";
import { getProfile } from "utils/localStorage";
import footerBPPLogo from "assets/images/bigpurple-logo.svg";


const IphonePWAPopup = () => {
  let bpp_profile = getProfile();

  const [browserName, setBrowserName] = useState("");
  const [osName, setOSName] = useState("");
  const [is_read, setIsRead] = useState(false);
  const [platform, setPlatform] = useState("");
  const [is_pwa, setIsPWA] = useState({
    supportsPWA: false,
    promptInstall: null,
  });
  useEffect(() => {
    console.log("bpp_profile", bpp_profile);
    setIsRead(bpp_profile.read_pwa_popup);
  }, []);

  useEffect(() => {
    if (is_pwa.promptInstall == null) {
      console.log("use pwa useEffect is calling");

      const handler = async (e) => {
        console.log("e beforeinstallpromot event", e);
        e.preventDefault();
        console.log("we are being triggered :D");

        // // If the user has not already installed...
        // e.userChoice.then((choiceResult) => {
        //   console.log("choiceResult", choiceResult);
        //   if (choiceResult === undefined) {
        //     // Update UI notify the user they can add to home screen
        //     console.log("choiceResult PWA is not defined", choiceResult);
        //   }
        // });

        // });
        setIsPWA({
          supportsPWA: true,
          promptInstall: e,
        });
      };
      let event = new Event("beforeinstallprompt");
      window.dispatchEvent(event);
      window.addEventListener("beforeinstallprompt", handler);
      return () => window.removeEventListener("beforeinstallprompt", handler);
    }
  });

  function fnBrowserDetect() {
    let userAgent = navigator.userAgent;
    let b_name;
    console.log("userAgent", userAgent);
    if (userAgent.match(/chrome|chromium|crios/i)) {
      b_name = "chrome";
    } else if (userAgent.match(/firefox|fxios/i)) {
      b_name = "firefox";
    } else if (userAgent.match(/safari/i)) {
      b_name = "safari";
    } else if (userAgent.match(/opr\//i)) {
      b_name = "opera";
    } else if (userAgent.match(/edg/i)) {
      b_name = "edge";
    } else {
      b_name = "No browser detection";
    }
    setBrowserName(b_name);
    // window.alert("You are using " + browserName + " browser");
  }

  function fnOSDetect() {
    let appVersion = navigator.appVersion;
    let os_name;
    let platform_name = navigator.platform;
    setPlatform(platform_name);

    if (appVersion.indexOf("Win") != -1) os_name = "Windows OS";
    if (appVersion.indexOf("Mac") != -1) os_name = "MacOS";
    if (appVersion.indexOf("X11") != -1) os_name = "UNIX OS";
    if (appVersion.indexOf("Linux") != -1) os_name = "Linux OS";

    setOSName(os_name);
  }
  console.log("is_pwa", is_pwa);
  console.log("osName", osName);
  useEffect(() => {
    fnBrowserDetect();
    fnOSDetect();
    //Syntax for the first method
    let appVersion = navigator.appVersion;

    //Syntax for the second method
    let userAgent = navigator.userAgent;
    console.log("navigator", navigator);
    console.log("appVersion", appVersion);
    console.log("userAgent", userAgent);
  }, []);

  const handleReadPWA = async () => {
    await readPWA().then((res) => {
      console.log("res", res);
      setIsRead(true);
    });
  };
  const IPhonePopup = () => (
    <div className="pwaPopup">
      <div className="popup_container">
        <div className="white_pop_container">
          <a href="#" className="">
            <img src={logo} />
          </a>

          <h1 className="pop_heading mt-3">
            <span className="boldtext text-dark">Save</span> BigPurplePhone{" "}
            <span className="boldtext text-dark">to your home screen</span>
          </h1>

          <p className="my-3">
            Just tap{" "}
            <span className="arrowimg">
              <img src={purpleLogo} />
            </span>{" "}
            on your Chrome or Safari taskbar, then 'Add to Home Screen'
          </p>
          <div className="d-flex btn_fullwidth">
            <button
              type="button"
              onClick={handleReadPWA}
              // className="btn_outline"
              className="btn_fill "
            >
              Ok
            </button>
          </div>
        </div>
      </div>
      <div className="bottomLogo-color">
        <a href="#">
          <img src={footerBPPLogo} />
        </a>
      </div>
    </div>
  );
  console.log("browserName", browserName);
  return platform == "iPhone" && !is_read ? <IPhonePopup /> : "";
};

export default IphonePWAPopup;
