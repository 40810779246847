import { Modal } from "react-bootstrap";

function CustomModal({
  toggleModal,
  showInvalidNumberModal,
  isOpen,
  title,
  description,
  prompt,
  close = true,
}) {
  console.log("isOpen", isOpen, showInvalidNumberModal)
  return (
    <Modal centered show={isOpen} onHide={toggleModal(false)}>
      <Modal.Header closeButton style={{ width: "100%" }}>
        {title ? (
          <Modal.Title style={{ width: "100%" }}>{title}</Modal.Title>
        ) : (
          ``
        )}
      </Modal.Header>
      <Modal.Body>{description}</Modal.Body>
      <Modal.Footer>
        {prompt ? (
          <>
            <button
              type="button"
              className="btn btn-primary"
              onClick={toggleModal(false)}
            // onClick={() => toggleModal(false)}
            >
              {prompt.cancel ? prompt.cancel : "Cancel"}
            </button>
            {prompt.title && (
              <button
                type="button"
                className="btn btn-danger"
                onClick={prompt.handle}
              >
                {prompt.title}
              </button>
            )}

          </>
        ) : close ? (
          <button
            type="button"
            className="btn btn-primary"
            onClick={toggleModal(false)}
          >
            Close
          </button>
        ) : (
          ""
        )}
      </Modal.Footer>
    </Modal>
  );
}

export default CustomModal;
