import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { Spinner, Modal } from "react-bootstrap";
import back_white from "../assets/images/back_white.svg";
import uploadCertifcate from "../assets/images/uploadCertifcate.svg";
import delete_icons from "../assets/images/delete_icons.svg";
import LovedOnePhoneName from "../components/LovedPhoneName";
import { addCertificate, getCertificate, deleteCertificate } from "../api/api";
import useSelectedLovedOne from "../hooks/useSelectedLovedOne";
import { toast } from "react-toastify";

import deleteConfirmIcon from "../assets/images/delete_confrm.svg";
import UploadProfilePhoto from "./UploadProfilePhoto";
import CustomModal from "./Modal/modal";
import { useErrorHandler } from "react-error-boundary";
import { speakText } from "utils/utils";
import useLoginUser from "hooks/useLoginUser";

export default function Certificate({ certificateKey }) {
  let lovedone = useSelectedLovedOne();
  console.log("loved_one", lovedone)
  const [list, setList] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");
  const [prevUrl, setPrevUrl] = useState(uploadCertifcate);
  const { profile } = useLoginUser();
  const [file, setDocument] = useState({
    isSelected: false,
    document_type: "",
    document: "",
    fileName: "",
    type: "image",
  });
  const [showModal, setShowModal] = useState(false);
  useEffect(() => {
    if (lovedone && lovedone.id) {
      getCertificateList();
    }
  }, [lovedone && lovedone.id]);
  const handleError = useErrorHandler();
  const getCertificateList = () => {
    getCertificate(lovedone.id, certificateKey)
      .then((response) => {
        setList(response.data.data);

        if (response.data.data && response.data.data.document) {
          setPrevUrl(response.data.data.document);
        } else {
          setPrevUrl(uploadCertifcate);
        }
      })
      .catch((error) =>
        error.response.data.statusCode != 400 ? handleError(error) : ""
      )
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleSubmit = () => {
    let err = "";

    if (!file.isSelected) {
      err = "Please upload Image";
    }

    if (err.length > 0) {
      setError(err);
    } else {
      setError("");

      let formData = new FormData();

      formData.append("key", certificateKey);
      formData.append("lovedOneId", lovedone.id);
      formData.append("document_type", "image");
      formData.append("photo", file.document);

      addCertificate(formData)
        .then(() => {
          setDocument({
            isSelected: false,
            document_type: "",
            document: "",
            fileName: "",
          });

          setTimeout(() => {
            getCertificateList();
          }, 2000);
        })
        .catch((error) =>
          error.response.data.statusCode != 400 ? handleError(error) : ""
        );
    }
  };

  const onClickDelete = () => {
    let data = {
      lovedOneId: lovedone.id,
      key: certificateKey,
    };
    deleteCertificate(data)
      .then(() => {
        setShowModal(false);
        return getCertificateList();
      })
      .catch((error) =>
        error.response.data.statusCode != 400 ? handleError(error) : ""
      );
  };
  const handleUpload = (photo, preview_url) => {
    setDocument({
      isSelected: true,
      document: photo,
      preview: preview_url,
    });
    setError("");
  };

  const handleModal = (status) => () => {
    setShowModal(status);
  };

  const handlePhotoError = (error) => {
    setError(error);
  };
  console.log("list", list.document ? true : false)
  return (
    <div className="pt80">
      <div className="mainPage">
        <div className="Homeheaderbg text-white headerfixed">
          <div className="d-flex align-self-center">
            <NavLink to="/MenuScreen">
              <img src={back_white} alt="" title="" onClick={() => speakText('Back', true)} />
            </NavLink>
            <h2 className="fs20 mb-0 text-center mx-auto">
              {certificateKey == "covid_check_in"
                ? "Covid Certificate"
                : "Vaccination Certificate"}
            </h2>
          </div>
        </div>

        <LovedOnePhoneName loved_one={lovedone} />
        <div className="contentBody contentboxgray">
          <div className="contentbodypadd pt-4">
            {list.document ? (
              <div className="col-md-12 text-right">
                <button
                  onClick={handleModal(true)}
                  className="mediaarrow removebox btn deleteCer"
                >
                  <img src={delete_icons} className="" />
                </button>
              </div>
            ) : (
              ""
            )}
            <span style={{ color: "red" }}>{error}</span>

            <div className="col-md-12">
              <div className="certificate-container">
                {file.isSelected || list.document ? (
                  <img
                    src={file.preview || list.document}
                    alt=""
                    title=""
                    className="preview_img"
                  />
                ) : (
                  <div
                    className={`${file.isSelected ? "" : "uploadbox"
                      } vaccineuploadbox mt-3`}
                  >
                    <UploadProfilePhoto
                      prevUrl={prevUrl}
                      handleUpload={handleUpload}
                      imParentCls={"text-center"}
                      showIcon={list.document ? true : false}
                      addPhoto={list.document ? true : false}
                      certificate
                      handlePhotoError={handlePhotoError}
                      // profile={profile.lovedOneList[0]}
                      profile={lovedone}
                    />
                  </div>
                )}
              </div>
              {/* )} */}
            </div>
            {list.document ? (
              ""
            ) : (
              <div className="row">
                <div className="d-flex mt-3 mb-4">
                  <button
                    onClick={() => {
                      setDocument({
                        isSelected: false,
                        document_type: "",
                        document: "",
                        fileName: "",
                      });
                      setPrevUrl(uploadCertifcate);
                      setError("");
                    }}
                    type="button"
                    className="btn btn-primary w-100 me-2 btnwhite textred"
                  >
                    Reset
                  </button>

                  <button
                    onClick={handleSubmit}
                    type="button"
                    className="btn btn-primary w-100 m2-2 "
                  >
                    Upload
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <CustomModal
        toggleModal={handleModal}
        isOpen={showModal}
        title={"Delete"}
        description={
          <div>
            <p className="mb-3 text-center">
              <img src={deleteConfirmIcon} alt="delete confirmation" />
            </p>
            <p className="text-center">
              Are you sure you want to delete this ?
            </p>
          </div>
        }
        prompt={{
          title: "Yes",
          cancel: "No",
          handle: () => {
            onClickDelete();
          },
        }}
      />
    </div>
  );
}
