import React, { useState } from "react";
import { useNavigate, NavLink } from "react-router-dom";

import back_white from "../assets/images/back_white.svg";
import editProfile from "../assets/images/editProfile.svg";
import selectSecurityList from "../assets/images/selectSecurityList.svg";
import adminUser from "../assets/images/admin-user.svg";
import changePasswosrd from "../assets/images/changePasswosrd.svg";
import listRightArrow from "../assets/images/listRightArrow.svg";

import CustomTextField from "../components/CustomTextField";
import useLoginUser from "../hooks/useLoginUser";
import UploadProfilePhoto from "components/UploadProfilePhoto";
import { PhoneComponent } from "components/InputFields";

export default function PFAProfile() {
  const navigate = useNavigate();
  const user = useLoginUser();

  const onClickResetPass = () => {
    navigate("/ChangePassword");
  };
  console.log("profile", user)
  return (
    <div className="mainPage">
      <div className="Homeheaderbg text-white" style={{ minHeight: "250px" }}>
        <div className="d-flex align-self-center">
          <NavLink to="/PFASetting">
            <img alt="" src={back_white} />
          </NavLink>
          <h2 className="fs20 mb-0 text-center mx-auto">My Profile</h2>
          <NavLink to="/PFAEditProfile">
            <img alt="" src={editProfile} />
          </NavLink>
        </div>
      </div>
      <div className="contentboxgraynotfixedheader">
        <br />
      </div>

      <div className="contentBody contentbodymedia mtminus">
        {/* <UploadProfilePhoto prevUrl={user.profile.photo} handleUpload={false} /> */}
        <div className="profilebox">
          <div className="userprofile ">
            <img
              src={
                user.profile.photo.length ? `${user.profile.photo}` : adminUser
              }
              className="profileimg"
              alt=""
              title=""
            />
            <span className="myprofile myprofileadmin">
              <img src={selectSecurityList} alt="" title="" />
            </span>
          </div>
        </div>
        <h3 className="text-center pb-2 pt-3 username">{user.profile.name}</h3>
        <div className="contentBody">
          <div className="row">
            <div className="col-lg-12">
              <div className="mb-3 mt-4">
                <CustomTextField
                  type="email"
                  label="Email"
                  defaultValue={user.profile.email}
                  disabled
                />
              </div>
              <div className="mb-3">
                <PhoneComponent
                  formik={{
                    values: { mobile: user.profile.mobile },
                    errors: {},
                    touched: {},
                  }}
                  name="mobile"
                  disabled={true}
                  label="Mobile"
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <button
                type="button"
                onClick={onClickResetPass}
                className="btn btn-primary w-100 passwordbtn"
              >
                <img src={changePasswosrd} className="me-3" alt="" title="" />
                Change Password
                <span className="iconEye" style={{ top: "7px" }}>
                  <img src={listRightArrow} alt="" />
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
