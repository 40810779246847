import React, { useEffect, useState } from "react";
import useLoginUser from "../hooks/useLoginUser";
import { useFormik } from "formik";
import { textField, PhoneComponent } from "components/InputFields";
import * as Yup from "yup";
import messages from "utils/messages";
import UploadProfilePhoto from "components/UploadProfilePhoto";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import userGender from "assets/images/user-gender.svg";
import deleteConfirmIcon from "assets/images/delete_confrm.svg";
import mobileIcon from "assets/images/mobileIcon.svg";
import landlineIcon from "assets/images/telephoneIcon.svg";

import infoFeather from "assets/images/infoFeather.svg";
import useSelectedLovedOne from "hooks/useSelectedLovedOne";
import {
  addNewLovedOneContact,
  sendInvite,
  editContactDetails,
  deleteContact,
} from "api/api";
import { inputValidation } from "utils/validations";
import { getAutoLoginUserid, getProfile, getRole } from "utils/localStorage";
import CustomModal from "./Modal/modal";
import {
  addContact,
  addContact_actual_role,
  designation_role,
  editContact,
  editContact_actual_role,
} from "json/roles";
import { genders } from "json/gender";
import { useErrorHandler } from "react-error-boundary";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

let initial_data = {
  name: "",
  email: "",
  phone: "",
  gender: "1",
  photo: "",
  photoName: "",
  verified_contact: 0,
  role: {
    fm: 1,
    fa: 0,
    ec: 0,
    hp: 0,
    lo: 0,
    ca: 0,
    pfa: 0,
  },
};

function ContactForm({ formData = initial_data, edit, prevUrl }) {
  const navigate = useNavigate();
  const lovedOne = useSelectedLovedOne();
  const user = useLoginUser();
  const [showInvite, setShowInvite] = useState(false);
  const role = getRole();
  const [showFamilyModel, setShowFamilyModel] = useState(false);
  const [showDeleteModel, setShowDeleteModel] = useState(false);
  const [roleId, setRoleID] = useState(0);
  const [userId, setUserId] = useState(0);
  const auto_login_id = getAutoLoginUserid();
  const handleError = useErrorHandler();
  const { profile } = useLoginUser();
  console.log("edit !!!!!!!!!!!!!!!!!!!!!!!", edit);
  console.log("editContact !!!!!!!!!!!!!!!!!!!!!!!", editContact);
  console.log("addContact !!!!!!!!!!!!!!!!!!!!!!!", addContact);
  let role_options = edit ? editContact : addContact;
  let actual_role = edit ? editContact_actual_role : addContact_actual_role;
  let gender_options = genders;
  const [activeTab, setActiveTab] = useState(
    formData.is_landline === 1 ? "Landline" : "Mobile"
  );
  const ContactSchema = Yup.object().shape({
    name: Yup.string().required(messages.required.name),
    gender: Yup.string().required(messages.required),
    email: Yup.string().email(messages.invalidEmail),
    role: Yup.object().test(
      "role",
      "Please select at least one role",
      (val) => {
        if (edit) {
          return !Object.values(val).every((v) => v === 0);
        }
        return true;
      }
    ),

    phone: Yup.string()
      .required(messages.required.phone)
      .test({
        name: "validator-custom-name",
        // eslint-disable-next-line object-shorthand
        test: function (value) {
          return inputValidation(value, this, true);
        },
      }),
  });

  const formik = useFormik({
    initialValues: {
      ...formData,
    },
    validationSchema: ContactSchema,

    onSubmit: (values) => {
      const isLandline = activeTab === "Landline" ? 1 : 0;
      if (activeTab === "Landline") {
        values.role.fm = 1;
        values.role.lo = 0;
        console.log("values line no 127", values);
      }
      handleSave({ ...values, is_landline: isLandline });
    },
    enableReinitialize: true,
  });

  const handleSave = (data) => {
    data.phone = data.phone.replaceAll(" ", "");
    data.mobile = data.phone.replaceAll(" ", "");
    data.name = data.name.trim();
    data.lovedOneId = lovedOne.id;

    console.log("data line no 122", data.roles);

    data.role = JSON.stringify(data.role);
    let formData = new FormData();
    Object.keys(data).map((key) => {
      if (key === "photo") {
        // Change the key name to 'photos'
        formData.append("photos", data[key]);
      } else {
        formData.append(`${key}`, data[key]);
      }
    });

    if (edit) {
      editContactDetails(formData)
        .then((result) => {
          setTimeout(() => {
            navigate("/SearchContact");
          }, 1500);
        })
        .catch((error) => {
          return error.response.data.statusCode != 400
            ? handleError(error)
            : "";
        });
    } else {
      addNewLovedOneContact(formData, lovedOne.id)
        .then((result) => {
          console.log("result", result);
          setUserId(result.data.data.data.id);
          setRoleID(result.data.role);
          if (
            !result.data.data.data.is_lite_lo &&
            !result.data.data.data.is_landline
          ) {
            setShowInvite(true);
          } else {
            navigate("/AddContactSuccessfull");
          }
          if (data.role.pfa === 1) {
            navigate("/LovedOneList");
          }
        })
        .catch((error) => {
          console.log(error);
          return error.response.data.statusCode != 400
            ? handleError(error)
            : "";
        });
    }
  };

  const handleCheckRole = (event) => {
    const { name, checked } = event.target;
    // let currentRoles = {};
    let currentRoles = { ...formik.values.role };

    // Special case for the "Emergency Contact" role

    // Special case for the "Big Purple Phone User" role
    if (name === "lo" && checked) {
      // Ensure "Emergency Contact" role is unchecked
      currentRoles["ec"] = 0;
      currentRoles["fm"] = 0;
    }

    if (name === "fm" && checked) {
      // Ensure "Emergency Contact" role is unchecked
      currentRoles["lo"] = 0;
    }

    if (name === "ec") {
      if (checked) {
        // Check if at least one of pfa, fa, or fm is selected in edit mode
        if (
          edit &&
          ![currentRoles.pfa, currentRoles.fa, currentRoles.fm].some(
            (role) => role === 1
          )
        ) {
          // Prevent selecting "Emergency Contact" if the condition is not met
          event.preventDefault();
          return;
        }
        // Ensure "Big Purple Phone User" role is unchecked
        currentRoles["lo"] = 0;
      }
    }
    if ((name == "fa" || name == "pfa") && checked) {
      if (user.profile.id != formData.id) {
        setShowFamilyModel(name);
      }
    } else {
      if (actual_role.includes(name)) {
        if (checked) {
          actual_role
            .filter((role) => role != name)
            .map((r) => (currentRoles[r] = 0));
          currentRoles[name] = 1;
          designation_role.map((r) => (currentRoles[r] = 0));
        } else {
          let remaining_roles = actual_role.filter((r) => r != name);
          let isFalseRole = remaining_roles.every(
            (r) => formik.values.role[r] === 0
          );
          if (isFalseRole) {
            currentRoles = {
              ca: 0,
              hp: 0,
              ec: 0,
              [name]: 0,
            };
          } else {
            currentRoles = {
              [name]: 0,
            };
          }
        }
      } else {
        currentRoles = {
          ...currentRoles,
          [name]: checked ? 1 : 0,
        };
      }
    }
    const allUnchecked = Object.values(currentRoles).every(
      (value) => value === 0
    );
    if (allUnchecked) {
      if (!edit) {
        currentRoles.fm = 1; // Reset fm to 1 if all others are unchecked
      }
    }

    formik.setFieldValue("role", { ...formik.values.role, ...currentRoles });
  };
  console.log("formik.values **", formik.values);
  console.log("role_options **", role_options);
  const filteredEditContact =
    formik.values.verified_contact === 0
      ? role_options.filter(
          (option) => option.name !== "pfa" && option.name !== "fa"
        )
      : role_options;
  console.log("formik.values ***********", formik.values);
  console.log("filteredEditContact **", filteredEditContact);
  const getCheckBoxFields = () =>
    filteredEditContact.map((o, i) => (
      <>
        {console.log("formik.values.role **", formik.values.role)}
        <input
          id={o.name}
          type="checkbox"
          name={o.name}
          onChange={handleCheckRole}
          className="form-check-input"
          checked={
            formik.values.role && formik.values.role[o.name] == 1 ? true : false
          }
          disabled={
            edit
              ? designation_role.includes(o.name) &&
                formik.values.role.fm === 0 &&
                formik.values.role.fa === 0 &&
                formik.values.role.pfa === 0
              : ""
          }
        />
        <label
          htmlFor={o.name}
          className={
            actual_role.includes(o.name) ||
            designation_role.includes(o.name) ||
            formik.values.role.fm === 1 ||
            formik.values.role.fa === 1 ||
            formik.values.role.pfa === 1 ||
            formik.values.role.lo === 1
              ? ""
              : edit
              ? "label-disable"
              : "form-check-label"
          }
        >
          {o.label}
        </label>
        <br />
      </>
    ));

  const getRadioBoxFields = () =>
    gender_options.map((o, i) => (
      <div key={i} className="form-check  form-check-inline ps-5">
        <input
          id={o.value}
          type="radio"
          name="gender"
          onChange={formik.handleChange}
          value={o.value}
          className="form-check-input"
          checked={o.value == formik.values.gender}
        />
        <label htmlFor={o.value} className="form-check-label">
          {o.label}
        </label>
        <br />
      </div>
    ));

  const handleUpload = (photo, preview) => {
    formik.setFieldValue("photo", photo);
  };
  const handleModal = (status) => () => {
    formik.setFieldValue("role", {
      ...formik.values.role,
      [showFamilyModel]: 0,
    });

    setShowFamilyModel(false);
  };

  const handleInvitationModal = (status) => () => {
    navigate("/AddContactSuccessfull");
    setShowInvite(status);
  };

  const handleDeleteModal = (status) => () => setShowDeleteModel(status);
  const onClickDelete = () => {
    deleteContact(lovedOne.id, formData.id, formData.editedId)
      .then((result) => {
        navigate("/SearchContact");
      })
      .catch((error) => {
        console.log(error);
        return error.response.data.statusCode != 400 ? handleError(error) : "";
      });
  };

  console.log("formData", formData.role.lo === 0);

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        {!auto_login_id && (
          <div className="contentBody contentboxgray contentbodymedia text-center">
            <div className="contentbodypadd pt-4">
              <UploadProfilePhoto
                prevUrl={prevUrl}
                handleUpload={handleUpload}
                imParentCls={edit ? "userprofile1" : "col-md-12"}
                showIcon={edit ? true : false}
                addPhoto={edit ? true : false}
                // profile={profile.lovedOneList[0]}
                profile={lovedOne}
              />
            </div>
          </div>
        )}
        <div
          className={
            edit
              ? "row fmsignup"
              : "fmsignup contentbodypadd formupload contentbodymedia"
          }
        >
          <div className="col-lg-12">
            <div className="mb-3 mt-3">
              {textField(
                formik,
                "name",
                false,
                "Name",
                "name",
                "text",
                false,
                formData.verified_contact ? true : false,
                true
              )}
            </div>
          </div>

          <div className="col-lg-12">
            <div className="mb-3 ">
              {textField(
                formik,
                "email",
                false,
                "Email Address",
                "email",
                "email",
                false,
                formData.verified_user ? true : false

                // true
              )}
            </div>
          </div>

          {!formData.verified_contact && formData.role.lo === 0 ? (
            <Tabs
              activeKey={activeTab}
              onSelect={(k) => setActiveTab(k)} // Update state on tab change
              id="uncontrolled-tab-example"
              className="mb-3 contactNoTabsUI"
            >
              <Tab
                eventKey="Mobile"
                title={
                  <>
                    <img
                      src={mobileIcon}
                      alt="Mobile Icon"
                      style={{ width: "14px", marginRight: "4px" }}
                    />{" "}
                    Mobile
                  </>
                }
              ></Tab>
              <Tab
                eventKey="Landline"
                title={
                  <>
                    <img
                      src={landlineIcon}
                      alt="Landline Icon"
                      style={{ width: "16px", marginRight: "4px" }}
                    />{" "}
                    Landline
                  </>
                }
              ></Tab>
            </Tabs>
          ) : null}

          <div className="col-lg-12">
            <div className="mb-3 mt-3">
              <PhoneComponent
                country={lovedOne.country}
                formik={formik}
                name={"phone"}
                label={false}
                placeholder={"Mobile"}
                icon={"phone"}
                type="text"
                disabled={
                  formData.verified_user || (edit && formData.role.lo)
                    ? true
                    : false
                }
                required
              />
              <div>
                {edit ? (
                  ""
                ) : (
                  <small className="fs12">
                    <span className=" pe-2">
                      <img src={infoFeather} alt="" title="" />
                    </span>
                    Please provide a mobile number so we can send this new
                    contact a link to join the secure Family & Friends network
                    for {lovedOne?.first_name}
                  </small>
                )}
              </div>
            </div>
          </div>
          {edit ? (
            ""
          ) : (
            <div className="mb-3">
              <div className="inputIcon labelborder">
                <div className="d-inline-flex align-items-center">
                  <div className="inputIcon">
                    <label className="form-control iconInputLeft bordernone">
                      Gender
                    </label>
                    <span className="icons">
                      <img src={userGender} alt="" title="" />
                    </span>
                  </div>
                  <span style={{ display: "flex" }}>{getRadioBoxFields()}</span>
                </div>
                {formik.errors.gender && formik.touched.gender ? (
                  <div className="error">{formik.errors.gender}</div>
                ) : null}
              </div>
            </div>
          )}
          {formData.role.lo ||
          formData.finalRole == 7 ||
          activeTab === "Landline" ? (
            ""
          ) : (
            <div className="col-lg-12">
              <div className="mb-4  ">
                <div className="mb-3">
                  <h5 className="pt-3 pb-2 fsmed">
                    Add a tick next to the role (if relevant) :
                  </h5>
                  <div className="form-check">{getCheckBoxFields()}</div>
                  <span style={{ color: "red" }}>{formik.errors?.role}</span>
                </div>
              </div>
            </div>
          )}
          <br />
          {edit ? (
            <div className="d-flex mb-5 mt-3">
              {!(formik.values.role.fa || formik.values.role.pfa) &&
              (formData.role.lo || formData.finalRole != 7) ? (
                <button
                  onClick={handleDeleteModal(true)}
                  type="button"
                  className="btn btn-primary w-100 me-2 btnwhite textred"
                  disabled={user.profile.id == formData.id}
                >
                  Delete
                </button>
              ) : (
                ""
              )}
              <button type="submit" className="btn btn-primary w-100 m2-2 ">
                Update
              </button>
            </div>
          ) : (
            <div className="col-lg-12">
              <button type="submit" className="btn btn-primary w-100 m2-2 ">
                Save
              </button>
            </div>
          )}
        </div>
      </form>

      {/* Invitation model */}

      <CustomModal
        toggleModal={handleInvitationModal}
        isOpen={showInvite}
        title={"Invitation"}
        description={
          <p className="text-center">
            Are you sure you wish to send an invitation to this person to join
            your family & friends network?
          </p>
        }
        prompt={{
          title: "Yes",
          cancel: "No thanks",
          handle: () => {
            let params = {
              lovedOneId: lovedOne?.id,
              id: userId,
              roleId,
              role: role,
              pfaName: getProfile().first_name,
            };
            sendInvite(params)
              .then(() => {
                navigate("/AddContactSuccessfull");
              })
              .catch((error) => {
                return error.response.data.statusCode != 400
                  ? handleError(error)
                  : "";
              });
          },
        }}
      />

      {/* Role model */}

      <CustomModal
        toggleModal={handleModal}
        isOpen={showFamilyModel}
        title={"Confirmation"}
        description={
          showFamilyModel == "pfa" ? (
            <p className="text-center">
              You are about to promote {formData.name} to be the primary Carer
              for {lovedOne.first_name} BigPurplePhone.
              <br />
              There can only be ONE primary Carer.
              <br />
              Are you sure?
            </p>
          ) : (
            <p>
              You are about to invite {formData.name} to be an administrator of
              this account. They will have full permissions for your Loved One,
              with the exception of Billing information. Are you sure?
            </p>
          )
        }
        prompt={{
          title: "Yes",

          handle: () => {
            let currentRole = {};
            actual_role
              .filter((role) => role != showFamilyModel)
              .map((r) => (currentRole[r] = 0));

            formik.setFieldValue("role", {
              ...formik.values.role,
              [showFamilyModel]: 1,
              ...currentRole,
            });

            setShowFamilyModel(false);
          },
        }}
      />

      {/* Delete model */}

      <CustomModal
        toggleModal={handleDeleteModal}
        isOpen={showDeleteModel}
        title={"Delete"}
        description={
          <>
            <p className="mb-3 text-center">
              <img src={deleteConfirmIcon} alt="delete confirmation" />
            </p>
            <p className="text-center">
              {edit && formData.role.lo
                ? "This will delete contact from both lo. "
                : ""}
              Are you sure you want to delete this contact ?
            </p>
          </>
        }
        prompt={{
          title: "Delete",
          handle: onClickDelete,
        }}
      />
    </>
  );
}

export default ContactForm;
