import { createSlice } from "@reduxjs/toolkit";

export const lovedOne = createSlice({
  name: "lovedOne",
  initialState: { value: {} },
  reducers: {
    changeLovedOne: (state, action) => {
      console.log('changeLovedOne', action.payload)
      state.value = action.payload;
      console.log("action.payload :>> ", action.payload);
      sessionStorage.setItem("lovedOne", JSON.stringify(action.payload));
    },
    updateLovedOnePhoto: (state, action) => {
      const { payload } = action;
      console.log("{ ...state.value, ...payload } :>> ", {
        ...state.value,
        ...payload,
      });
      state.value = { ...state.value, ...payload };
      sessionStorage.setItem(
        "lovedOne",
        JSON.stringify({ ...state.value, ...payload })
      );
    },
  },
});

export const { changeLovedOne, updateLovedOnePhoto } = lovedOne.actions;

export default lovedOne.reducer;
