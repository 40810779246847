import React, { useRef, useState } from "react";
import OtpInput from "react-otp-input";
import { useNavigate, useLocation, NavLink } from "react-router-dom";

import blackTop from "../assets/images/blackTop.svg";
import loginLogo from "../assets/images/loginLogo.svg";
import bigPurplePhoneLogo from "../assets/images/bigPurpleLogo-dark.svg";
import { verifyOtpMobile, sendOtpMobile } from "../api/api";
import { number } from "yup";

export default function OtpVerify() {
  const location = useLocation();
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const inputRefs = useRef([]);

  const [otp, setOtp] = useState(""); // Initial state as an array of empty strings
  const onChangeInput = (otp) => setOtp(otp);

  const onClickResendOtp = () => {
    sendOtpMobile({ mobile: location.state.mobile })
      .then((result) => {
        console.log(result);
        navigate("/OtpVerify", { state: { mobile: location.state.mobile } });
      })
      .catch((error) => {
        console.log(error);
        // setError("Invalid Number");
      });
  };
  const onClickOtpVerify = () => {
    console.log("otp", otp);
    if (otp > 999) {
      let data = {
        otp,
        mobile: location.state.mobile,
      };
      verifyOtpMobile(data)
        .then((result) => {
          console.log(result);

          navigate("/PFAResetPassword", {
            state: { mobile: location.state.mobile, otp },
          });
        })
        .catch((error) => {
          console.log(error);
          setError("Invalid Otp");
        });
    } else if (!otp) {
      setError("Please Enter OTP.");
    } else {
      setError("Invalid otp");
    }
  };
  if (!(location.state && location.state.mobile)) {
    return "";
  }
  return (
    <div className="mainPage loginPage">
      <div className="headerTop">
        <NavLink to="/LoginScreen" className="backIconTop">
          <img alt="" src={blackTop} />
        </NavLink>
      </div>
      <div className="loginLogo">
        <img src={loginLogo} alt="" />
      </div>
      <div className="contentBody">
        <h1>
          <span>Verify Mobile Number</span>
        </h1>
        <p className="mb-4">
          Please enter CODE you receive on your Mobile Number
        </p>
        <div className=" mb-3">
          <div className="d-flex opt-code-gp">
            <OtpInput
              className="otpbox otpInput"
              value={otp}
              onChange={onChangeInput}
              numInputs={4}
              renderSeparator={<span></span>}
              renderInput={(props) => <input {...props} type="text" />}
              inputType={'number'}
            />
          </div>
          <span id="span">{error}</span>
          <p className="pt-3">
            Didn't Receive CODE ? Please wait. It can take up to 60 seconds for
            your CODE to arrive.&nbsp;
            {/* <span className="clrblue fsregular mx-1" onClick={onClickResendOtp}>
              Resend CODE
            </span> */}
            <a className="clrblue fsregular mx-1 crsrPointer" onClick={onClickResendOtp} style={{ fontFamily: "helveticaneuelight" }}>Resend CODE</a>
          </p>
        </div>
        <div className="button-margin">
          <div className="col-lg-12 mb-4  ">
            <button
              onClick={onClickOtpVerify}
              type="button"
              className="btn btn-primary w-100"
            >
              Submit
            </button>
            <p className="text-center">Click Only Once</p>
          </div>
        </div>
      </div>
      <div className="bigpurplebottom text-center pb-4">
        <img src={bigPurplePhoneLogo} alt="" />
      </div>
    </div>
  );
}
