import { imgInputFilePicker, speakText } from "utils/utils";
import React, { useState, useEffect } from "react";
import myProfileIcon from "assets/images/myProfileIcon.svg";
import editIcon from "assets/images/editProfile.svg";
import { toast } from "react-toastify";
import imageCompression from "browser-image-compression";
import uploadPhoto from "assets/images/upload-photo.svg";
import { useSelector } from "react-redux";

export default function UploadProfilePhoto({
  handleUpload,
  value,
  prevUrl = false,
  imParentCls = false,
  showIcon = true,
  addPhoto = true,
  gallery = false,
  certificate = false,
  handlePhotoError,
  profile,
}) {
  const [formData, setFormData] = useState();
  const settings = useSelector((state) => state.settings);

  console.log("editProfile", profile);
  console.log("showIcon", showIcon);

  const [showPrevUrl, setShowPrevUrl] = useState(prevUrl);

  useEffect(() => {
    if (value) {
      setFormData(value);
    }
  }, [value]);
  const handleSpeakButtonClick = () => {
    speakText("Adding new photos", true);
  };
  const handleUploadFile = async (event) => {
    handleSpeakButtonClick();
    console.log("event", event);
    if (value) {
      return false;
    } else {
      console.log("sfjkfsjk");
      let imageFile = event.target.files[0];

      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
        // fileType: "image/png",
      };

      if (gallery) {
        let files = [];
        let previewUrls = [];
        let imgs = event.target.files;
        for (const im of Array.from(imgs)) {
          console.log("im :>> ", im);
          let compressedFile = await imageCompression(im, options);
          var previewUrl = URL.createObjectURL(compressedFile);
          var file = new File([compressedFile], im.name, {
            type: im.type,
            size: compressedFile.size,
          });

          files.push(file);
          previewUrls.push(previewUrl);
        }

        handleUpload(files, previewUrls);
      } else {
        try {
          const compressedFile = await imageCompression(imageFile, options);
          console.log("compressedFile :>> ", compressedFile);

          console.log(
            `compressedFile size ${compressedFile.size / 1024 / 1024} MB`
          ); // smaller than maxSizeMB
          let previewUrl = URL.createObjectURL(compressedFile);
          if (!certificate) {
            setFormData(previewUrl);
          }
          console.log("previewUrl :>> ", previewUrl);
          console.log("compressedFile :>> ", compressedFile);
          if (handleUpload) {
            if (!certificate) {
              setShowPrevUrl(false);
            }
            var file = new File([compressedFile], imageFile.name, {
              type: imageFile.type,
              size: compressedFile.size,
            });
            handleUpload(file, previewUrl);
          }
        } catch (error) {
          console.log("error.response :>> ", error);
          if (handlePhotoError) {
            return handlePhotoError("Please upload a valid image");
          }
        }
      }
    }
  };

  return gallery ? (
    <>
      <input
        type="file"
        onChange={handleUploadFile}
        // accept="image/x-png,image/gif,image/jpeg"
        accept="image/*, .heic"
        multiple
        className="crsrPointer"
      />
      <div
        className="media boxmediablue text-white"
        onClick={() => {
          handleUpload();
          handleSpeakButtonClick();
        }}
      >
        <img
          className="align-self-center me-3"
          src={uploadPhoto}
          alt=""
          title=""
        />
        <div className="media-body crsrPointer">
          <h5 className="mt-0">
            <b>Add New Photos</b>
          </h5>
        </div>
      </div>
    </>
  ) : (
    <div className="profilebox customprofilebox">
      <div className={imParentCls ? imParentCls : "userprofile "}>
        <input
          type="file"
          onChange={handleUploadFile}
          className={"file-input-hidden"}
          accept="image/*, .heic"
        />

        <div
          onClick={() => document.querySelector('input[type="file"]').click()} // Trigger file input click
          style={{ cursor: "pointer" }} // Make it clear that the image is clickable
        >
          <img
            src={showPrevUrl ? prevUrl : formData ? formData : myProfileIcon}
            className={addPhoto ? "profileimg" : ""}
            alt=""
            title=""
          />
        </div>

        {showIcon ? (
          <span className="editprofile customfilebx">
            <input
              type={value ? "text" : "file"}
              onChange={handleUploadFile}
              className={"file-input-hidden"}
              accept="image/*, .heic"
            />
            <img src={editIcon} alt="" title="" />
          </span>
        ) : (
          <p className="mb-0">{certificate ? "Upload Certificate" : ""}</p>
        )}
      </div>
    </div>
  );
}
