import React, { useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";

import blackTop from "../assets/images/blackTop.svg";
import myProfileSettings from "../assets/images/myProfileSettings.svg";
import manageBigPurple from "../assets/images/manageBigPurple.svg";
import billingDetails from "../assets/images/billingDetails.svg";
import IconHelp from "../assets/images/Icon-help.svg";
import logoutIcon from "../assets/images/logoutIcon.svg";
import { logout } from "api/api";
import { useErrorHandler } from "react-error-boundary";
import { deleteRole } from "utils/localStorage";

export default function PFASetting() {
  const navigate = useNavigate();
  const handleError = useErrorHandler();

  // Handle back button click
  const handleBackClick = () => {
    navigate(-1); // Go back in history stack
  };


  const handleLogout = async () => {
    let data = {
      role: "pfa",
    };
    await logout(data)
      .then((res) => {
        deleteRole();
        localStorage.removeItem("profile");
        localStorage.removeItem("api_token");
        sessionStorage.removeItem("lovedOne");
        localStorage.removeItem("callingUrl");
        // navigate("/LoginScreen");
        window.location.href = "/";
      })
      .catch((error) => {
        return error.response.data.statusCode != 400 ? handleError(error) : "";
      });
  };
  return (
    <div className=" pt80">
      <div className="mainPage loginPage topspace pt80">
        <div className="arrowfixed">
          <NavLink to="/LovedOneList">
            <img alt="" src={blackTop} className="arrowleft" />
          </NavLink>
          {/* <div className="back-button-container" onClick={handleBackClick}>
            <img src={blackTop} alt="Back" className="back-button" />
          </div> */}
        </div>
        <div className="contentBody fulogin ">
          <h1>
            <span className="fs36">Manage Settings</span>
          </h1>

          <div className="row  pt-4">
            <div className="col-lg-12">
              <div className="settingbox">
                <ul>
                  <li>
                    <NavLink to="/PFAProfile">
                      <img alt="" src={myProfileSettings} />
                      My Profile Settings
                    </NavLink>
                  </li>

                  <li>
                    <NavLink to="/PFABillingDetails">
                      <img alt="" src={billingDetails} />
                      Billing Details
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/PFAHelpSupport">
                      <img alt="" src={IconHelp} />
                      <div className="d-flex flex-column">
                        Help
                        <p className="subTitleBlog">
                          If you are looking for help click here
                        </p>
                      </div>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="#">
                      <img alt="" src={logoutIcon} />
                      <div
                        className="d-flex flex-column"
                        onClick={handleLogout}
                      >
                        Logout
                      </div>
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
