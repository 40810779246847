import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function useSetupData() {
  const navigate = useNavigate();

  const shopifySetup = useSelector((state) => state.setup.shopifyJson);

  useEffect(() => {
    if (shopifySetup) {
      return shopifySetup;
    } else {
      navigate("/");
    }
  }, []);

  return shopifySetup;
}
