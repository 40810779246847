import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import back_white from "../assets/images/back_white.svg";
import mailSupport from "../assets/images/mailSupport.svg";
import LocationSupport from "../assets/images/LocationSupport.svg";
import phoneSupport from "../assets/images/phoneSupport.svg";

import HelpSupport from "components/HelpSupport.js";
export default function PFAHelpSupport() {
  return (
    <body className="pt80">
      <div className="mainPage">
        <div className="Homeheaderbg headerfixed text-white">
          <div className="d-flex align-self-center">
            <NavLink to="/PFASetting">
              <img src={back_white} alt="" title="" />
            </NavLink>
            <h2 className="fs20 mb-0 text-center mx-auto">Help/Support</h2>
          </div>
        </div>
        <div className="parent">
          <div className="contentboxgray11">
            <br />
          </div>
        </div>

        <div className="contentBody contentbodymedia">
          <div className="contentBody">
            <h3 className="fsregular">Connect With Us</h3>
            <div className="row pt-3 support">
              <div className="col-lg-12">
                <div className="mb-4">
                  <h4>
                    {" "}
                    <span>
                      <img src={mailSupport} className="me-2" alt="" title="" />
                    </span>
                    <a href="mailto:hello@bigpurplephone.com.au">
                      hello@bigpurplephone.com.au
                    </a>
                  </h4>
                </div>
                <div className="mb-4">
                  <h4>
                    {" "}
                    <span>
                      <img
                        src={LocationSupport}
                        className="me-2"
                        alt=""
                        title=""
                      />
                    </span>
                    {/* PO Box 837 Moss Vale NSW 2577 */}
                    Big<b>Purple</b>Phone, Level 1, 118 Main Street, Mittagong NSW 2575
                    Australia
                  </h4>
                </div>
                <div className="mb-4">
                  <h4>
                    {" "}
                    <span>
                      <img
                        src={phoneSupport}
                        className="me-2"
                        alt=""
                        title=""
                      />
                    </span>
                    <a href="tel:+61291886998">(02) 9188 6998</a>
                  </h4>
                </div>
              </div>
              <HelpSupport />
            </div>
          </div>
        </div>
      </div>
    </body>
  );
}
