import React, { useState } from "react";
import { toast } from "react-toastify";
import Select, { components } from "react-select";
const CheckboxOption = ({ children, isSelected, ...props }) => (
  <components.Option {...props}>
    <input type="checkbox" checked={isSelected} />
    {children}
  </components.Option>
);

function TestUrl() {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const categoryOptions = {};
  const options = Object.keys(categoryOptions).map((category) => ({
    label: category,
    options: categoryOptions[category],
  }));
  return (
    <div className="mainPage">
      <div className="Homeheaderbg1">
        <div className="customer-info">
          <div className="col-md-12">
            <h3 className="fs32">Add</h3>
            <h1>Web URL</h1>
          </div>
        </div>
      </div>
      <div className="mb-10 contentBody contentboxgray  contentbodymedia">
        <div className="row justify-content-center">
          <div className="col-md-12">
            <div className="textstyle">
              <p className="pt-4">
                loved one never needs to worry they will miss an important
                Update. You can remind loved one about Important Web Url. Your
                chosen Top 5 Web Url will be Shown on their{" "}
                <b>Big Purple Phone</b> on the day you enter.
              </p>
            </div>
            <h5 className="mt-0" style={{ fontFamily: "helveticaneuemedium" }}>
              Please Select Upto 5 URL's
            </h5>
          </div>
        </div>
        <div className="selecturl-opt">
          <Select
            className="selectopt"
            value={selectedOptions}
            options={options}
            isMulti
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            isOptionSelected={(option) =>
              selectedOptions.some(
                (selectedOption) => selectedOption.value === option.value
              )
            }
            components={{
              Option: (optionProps) => (
                <CheckboxOption
                  {...optionProps}
                  isSelected={selectedOptions.some(
                    (selectedOption) =>
                      selectedOption.value === optionProps.data.value
                  )}
                />
              ),
            }}
            onChange={(selectedOption) => {
              if (selectedOption.length > 5) {
                return toast(
                  "Oops! You've reached the maximum limit of 5 web URL's",
                  {
                    type: "error",
                  }
                );
              } else {
                setSelectedOptions(selectedOption);
              }
            }}
          />
        </div>
        <button
          type="submit"
          className="btn btn-primary w-100 mt-3"
          onClick={""}
        >
          Update Selected URL's
        </button>
      </div>
    </div>
  );
}

export default TestUrl;
