import React from "react";

import user from "assets/images/user.svg";
import loginMail from "assets/images/loginMail.svg";
import mobile from "assets/images/mobile.svg";
import calendar from "assets/images/calendar.svg";
import downArrow from "assets/images/downarrow.svg";
import street from "assets/images/street_icons.svg";
function InputFieldImg({ type }) {
  const imgSrc = () => {
    switch (type) {
      case "name":
        return user;
      case "email":
        return loginMail;
      case "phone":
        return mobile;
      case "dob":
        return calendar;
      case "downArrow":
        return downArrow;
      case "street":
        return street;
      default:
        return null;
    }
  };

  return type ? <img src={imgSrc()} alt="" title="" /> : "";
}

export default function CustomTextField({
  type,
  onChange,
  value,
  placeholder,
  label,
  input_type = "text",
  ...rest
}) {
  let input_value = value;

  return (
    <>
      {label ? (
        <label htmlFor="" className="form-label">
          {label}
        </label>
      ) : null}
      <div className="inputIcon">
        <input
          {...rest}
          value={input_value ? input_value : value}
          onChange={onChange}
          style={{ color: "black" }}
          type={input_type}
          className="form-control iconInputLeft"
          placeholder={placeholder}
        />
        <span className="icons">
          <InputFieldImg type={type} />
        </span>
      </div>
    </>
  );
}
