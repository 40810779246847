import { configureStore } from "@reduxjs/toolkit";
import lovedOne from "./lovedOne";
import user from "./user";
import videoCall from "./videoCallData";
import setup from "./setupStore";
import settings from "./settingsSlice";

export default configureStore({
  reducer: {
    lovedOne: lovedOne,
    user: user,
    videoCall: videoCall,
    setup,
    settings,
  },
});
