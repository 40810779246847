import { useEffect, useState } from "react";
import { useErrorHandler } from "react-error-boundary";
import { io } from "socket.io-client";

const SOCKET_URL = "https://apibiguat.24livehost.com";

export default function SocketTest() {

    const [socket, setSocket] = useState(null);
    const handleError = useErrorHandler();

    // useEffect(() => {
    //     const socket = io(SOCKET_URL);

    //     socket.on("connection", () => {
    //         console.log("Connected to WebSocket server");
    //     });

    //     socket.on("error", (error) => {
    //         console.error("WebSocket connection error:", error);
    //         socket.disconnect(); // Disconnect the current socket
    //         // Attempt to reconnect after a delay
    //         setTimeout(connectToSocket, 3000); // Attempt to reconnect after 3 seconds
    //     });

    //     socket.on("reconnect", () => {
    //         console.log("Reconnected to WebSocket server");
    //     });

    //     socket.on("reconnect_attempt", (attemptNumber) => {
    //         console.log(`Attempting to reconnect (attempt ${attemptNumber})`);
    //     });

    //     socket.on('close', () => {
    //         console.log('Socket closed');
    //     });

    //     socket.on("coordinatesUpdated", (data) => {
    //         console.log("coordinatesUpdated", data)
    //         // Handle the received coordinates data
    //         // const newData = {
    //         //     lat: data.latitude,
    //         //     lng: data.longitude
    //         // }
    //         // const formatDate = formatDateTime(data?.updated_at)
    //         // setDate(formatDate);
    //         // setLocation(newData);
    //     });

    //     setSocket(socket);

    //     return () => {
    //         socket.disconnect();
    //     };
    // }, []);


    useEffect(() => {
        // Function to connect to the WebSocket server
        const connectToSocket = () => {
            const socket = io("https://apibiguat.24livehost.com");

            socket.on('connection', () => {
                console.log('Connected to server');
                console.log("socket", socket)
            });

            socket.on("reconnect", () => {
                console.log("Reconnected to WebSocket server");
            });

            socket.on("disconnect", () => {
                console.log("disconnect")
                if (!socket) {

                    connectToSocket();
                }
            })
            socket.on("reconnect_attempt", (attemptNumber) => {
                console.log(`Attempting to reconnect (attempt ${attemptNumber})`);
            });

            socket.on("coordinatesUpdated", (data) => {
                console.log("coordinatesUpdated", data)
                // Handle the received coordinates data
                // const newData = {
                //     lat: data.latitude,
                //     lng: data.longitude
                // }
                // const formatDate = formatDateTime(data?.updated_at)
                // setDate(formatDate);
                // setLocation(newData);
            });

            socket.on("error", (error) => {
                console.error("WebSocket connection error:", error);
                socket.disconnect(); // Disconnect the current socket
                // Attempt to reconnect after a delay
                setTimeout(connectToSocket, 3000); // Attempt to reconnect after 3 seconds
            });

            socket.emit("joinRoom", { roomId: `lovedOne_${4}` });

            setSocket(socket);
        };

        // Call the function to connect to the WebSocket server
        connectToSocket();

        // Clean up the socket connection when the component unmounts
        return () => {
            if (socket) {
                socket.disconnect();
            }
        };
    }, []);

    return (
        <>
            <h1>hello</h1>
        </>
    )
}