import { createSlice } from "@reduxjs/toolkit";

const saveSettingsToLocalStorage = (settings) => {
  try {
    const serializedSettings = JSON.stringify(settings);

    console.log("Setting in local storage", serializedSettings)

    localStorage.setItem("settings", serializedSettings);
  } catch (e) {
    console.warn("Could not save settings", e);
  }
};

export const loadSettingsFromLocalStorage = () => {
  try {
    const serializedSettings = localStorage.getItem("settings");
    // if (serializedSettings === null) {
    //   return undefined;
    // }
    return JSON.parse(serializedSettings);
  } catch (e) {
    console.warn("Could not load settings", e);
    return undefined;
  }
};


const initialState = loadSettingsFromLocalStorage() || {
  message: "0",
  user_id: "80",
  talk_back: "false",
  no_spam: "0",
  priority: "normal",
  is_auto_speaker: "false",
  voice_mail: "0",
  select_to_speak: "false",
  loved_one_id: "0",
  avatars_size: "0",
  show_recent_call: "0",
  type: "",
  sos_button: "0",
};

export const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    updateSettingsSlice: (state, action) => {
      const { lovedOneId, settings } = action.payload;
      state = { ...state[lovedOneId], ...settings };

      console.log("state", JSON.stringify(settings))
      saveSettingsToLocalStorage(state);
    },
  },
});

export const { updateSettingsSlice } = settingsSlice.actions;

export default settingsSlice.reducer;
