import splashLogo from "../assets/images/splashLogo.svg";

export default function SplashComponent ()  {
    return (
        <div className="mainPage purBg">
            <div className="logo spleshBlog">
                <a href="#">
                <img src={splashLogo} style={{ width: "230px" }} />
                </a>
            </div>
        </div>
    )
}