import React, { useState } from "react";
// import useSetupData from "../../hooks/useSetupData";
import SetupLayout from "./SetUpLayout";
import { useDispatch, useSelector } from "react-redux";
import { handleConfirm } from "../../redux/setupStore";
import { useNavigate } from "react-router-dom";
import UploadProfilePhoto from "components/UploadProfilePhoto";
import uploadCertifcate from "assets/images/uploadCertifcate.svg";
import { imgInputFilePicker } from "utils/utils";
import { useFormik } from "formik";
import * as Yup from "yup";
import messages from "utils/messages";
import { toast } from "react-toastify";

export default function UploadId() {
  // const setupData = useSetupData();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const setupStore = useSelector((state) => state.setup);
  const [prevUrl, setPrevUrl] = useState(uploadCertifcate);

  let {
    data: { lovedOne },
  } = setupStore;
  const handleNext = () => {
    let payload = {
      data: {
        identity_photo: formik.values.identity_photo,
        lovedOne: {
          ...setupStore.data.lovedOne,
          is_authorised: formik.values.is_authorised,
          identity_preview: formik.values.identity_preview,
        },
      },
    };
    dispatch(handleConfirm(payload));
    navigate("/setup/AllDone");
  };

  // const onClickPhotoUpdate = async () => {
  //   try {
  //     const imgData = await imgInputFilePicker();
  //     if (imgData) {
  //       let data = {
  //         photo: imgData.base64,
  //         photoName: imgData.name,
  //       };
  //       console.log("data :>> ", data);
  //       formik.setFieldValue("identity_photo", data);
  //     }
  //   } catch (e) {
  //     console.log(typeof e, "rfgkldflkjfgfgxkl");
  //     if (typeof e == "undefined") {
  //       toast("Please select only image", { type: "error" });
  //     } else {
  //       toast(e, { type: "error" });
  //     }
  //   }
  // };

  const formik = useFormik({
    initialValues: {
      is_authorised: setupStore?.data?.lovedOne?.is_authorised || false,

      identity_photo: "",
      identity_preview: "",
    },
    validationSchema: Yup.object().shape({
      is_authorised: Yup.bool()
        .oneOf([true], messages.required.check)
        .required(messages.required.name),
      identity_photo: Yup.mixed().required(messages.required.file),
    }),

    onSubmit: (values) => {
      console.log("values :>> ", values);
      handleNext(values);
    },
  });

  const handleUpload = (identity_photo, identity_preview) => {
    console.log(
      "identity_photo, identity_preview :>> ",
      identity_photo,
      identity_preview
    );
    formik.setFieldValue("identity_photo", identity_photo);
    formik.setFieldValue("identity_preview", identity_preview);
  };
  console.log("formik.values.is_authorised :>> ", formik.values.is_authorised);
  return (
    <SetupLayout heading="Your Identity" backNav={"PortForm"}>
      <UploadProfilePhoto handleUpload={false} value={lovedOne.loPrevUrl} />

      <div className="contentBody fulogin">
        <div className="row fmsignup">
          <div className="col-lg-12">
            <h2 className="topTileHeading">Confirm your identity</h2>

            <p>
              We need to confirm that {lovedOne.first_name} has authorised you
              to port their number over. Please confirm this and provide your
              ID. A Drivers License, Birth Certificate or passport are
              acceptable.
            </p>
          </div>
          <form onSubmit={formik.handleSubmit}>
            <div className="col-lg-12">
              <div className="mb-3 mt-3">
                <div className="circlebox">
                  <div className="form-group mb-0 authorisedacOuter">
                    <input
                      type="checkbox"
                      id="is_authorised"
                      name="is_authorised"
                      onChange={formik.handleChange}
                      value={formik.values.is_authorised}
                      checked={formik.values.is_authorised}
                    />
                    <label for="is_authorised">
                      {" "}
                      I am authorised by the Account holder to port this phone
                      number over to Big<b>Purple</b>Phone{" "}
                    </label>
                    <div className="error">
                      {formik.errors?.is_authorised &&
                      formik.touched?.is_authorised
                        ? formik.errors.is_authorised
                        : ""}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="contentbodypadd py-4">
                <div className="col-md-12">
                  {formik.values.identity_photo ? (
                    <div className="text-center">
                      <img
                        src={formik.values.identity_preview}
                        alt=""
                        title=""
                        className="preview_img"
                      />
                    </div>
                  ) : (
                    <div className={"uploadbox"}>
                      <UploadProfilePhoto
                        handleUpload={handleUpload}
                        imParentCls={"text-center"}
                        showIcon={false}
                        addPhoto={false}
                        prevUrl={prevUrl}
                      />
                    </div>
                  )}

                  {/* {formik.values.identity_photo?.photo ? (
                    <div className="text-center">
                      <img
                        src={`data:image/jpeg;base64,${formik.values.identity_photo?.photo}`}
                        alt=""
                        title=""
                      />
                    </div>
                  ) : (
                    <a
                      href="#"
                      className="uploadbox"
                      onClick={onClickPhotoUpdate}
                    >
                      <img src={uploadCertifcate} alt="" title="" />
                      <p className="mb-0 text-center">Upload Photo</p>
                    </a>
                  )} */}
                </div>
                <div className="error">
                  {formik.errors.identity_photo && formik.touched.identity_photo
                    ? formik.errors?.identity_photo?.photo
                    : ""}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="d-flex mt-3 mb-3">
                <button
                  type="submit"
                  // onClick={handleNext}
                  className="btn btn-primary w-100  "
                >
                  Next
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </SetupLayout>
  );
}
