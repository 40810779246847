import React, { useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import back_white from "../assets/images/back_white.svg";
import uploadCertifcate from "../assets/images/uploadCertifcate.svg";
import useSelectedLovedOne from "../hooks/useSelectedLovedOne";
import LovedOnePhoneName from "../components/LovedPhoneName";
import ContactForm from "components/ContactForm";

function AddNewContact() {
  const lovedOne = useSelectedLovedOne();

  return (
    <div className="pt80">
      <div className="mainPage">
        <div className="Homeheaderbg text-white headerfixed">
          <div className="d-flex align-self-center">
            <NavLink to="/Add_ImportContacts">
              <img src={back_white} alt="" title="" />
            </NavLink>

            <h2 className="fs20 mb-0 text-center mx-auto raleway_font">
              Add Contacts
            </h2>
          </div>
        </div>
        <LovedOnePhoneName loved_one={lovedOne} />
        <ContactForm prevUrl={uploadCertifcate} />
      </div>
    </div>
  );
}

export default AddNewContact;
