import React, { useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import back_white from "../assets/images/back_white.svg";
import infoFeather from "../assets/images/infoFeather.svg";
import CustomPasswordInput from "../components/CustomPasswordInput";
import { resetPassword } from "../api/api";
import { passwordRegex } from "utils/regex";
import { useFormik } from "formik";
import * as Yup from "yup";
import messages from "utils/messages";

export default function PFAResetPassword() {
  const navigate = useNavigate();
  const location = useLocation();
  const [state, setState] = useState({ password: "", confirmPassword: "" });
  const [error, setError] = useState({
    password: "",
    confirmPassword: "",
    isError: false,
  });

  const ResetPasswordSchema = Yup.object().shape({
    password: Yup.string()
      .required(messages.required.password)
      .matches(passwordRegex, messages.invalidPassword),

    confirmPassword: Yup.string()
      .required(messages.required.confirmPassword)
      .oneOf([Yup.ref("password"), null], "Passwords must match"),
  });

  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: ResetPasswordSchema,

    onSubmit: (values) => {
      console.log("values :>> ", values);
      const castValues = ResetPasswordSchema.cast(values);
      let data = {
        mobile: location.state.mobile,
        password: castValues.password,
      };
      resetPassword(data)
        .then((res) => {
          navigate("/PasswordUpdateSuccess");
        })
        .catch((err) => {
          console.log(err);
        });
    },
    enableReinitialize: true,
  });

  useEffect(() => {
    if (!(location.state && location.state.otp)) {
      // navigate("/LoginScreen");
      window.location = "/LoginScreen";
    }
  }, []);
  return (
    <div className="mainPage pt80">
      <div className="Homeheaderbg headerfixed text-white ">
        <div className="d-flex align-self-center">
          <NavLink to="/LoginScreen">
            <img src={back_white} alt="" title="" />
          </NavLink>
          <h2 className="fs20 mb-0 text-center mx-auto">Reset Password</h2>
        </div>
      </div>
      <div className="parent">
        <div className="contentboxgray11">
          <br />
        </div>
      </div>

      <div className="contentBody contentbodymedia ">
        <div className="contentBody">
          <h3 className="fsregular">Reset Password</h3>
          <form onSubmit={formik.handleSubmit}>
            <div className="row pt-3">
              <div className="col-lg-12">
                <div className="mb-3">
                  <CustomPasswordInput
                    formik={formik}
                    name="password"
                    label="New Password"
                    required
                  />
                </div>
                <div className="mb-3">
                  <CustomPasswordInput
                    formik={formik}
                    name="confirmPassword"
                    label="Confirm Password"
                    required
                  />
                </div>
                <small className="fs12">
                  <span className=" pe-2">
                    <img src={infoFeather} alt="" title="" />
                  </span>

                  {messages.invalidPassword}
                </small>

                <div className="">
                  <small className="fs12">
                    <span className="pe-2">
                      <b>*=Required fields</b>
                    </span>
                  </small>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <button
                  type="submit"
                  // onClick={onClickSubmit}
                  className="btn btn-primary w-100 mt-3"
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      {/* <div className="bigpurplebottom" style={{ textAlign: "center" }}>
                <img src={bigPurplePhoneLogo} alt="" />
            </div> */}
    </div>
  );
}
