// import React, { useState } from "react";
// import { useNavigate, NavLink } from "react-router-dom";
// import { csvUrl } from "../utils/service";

// import back_white from "../assets/images/back_white.svg";
// import addCircle from "../assets/images/addCircle.svg";
// import importContacts from "../assets/images/importContacts.svg";
// import CustomModal from "../components/Modal/modal";

// import useSelectedLovedOne from "../hooks/useSelectedLovedOne";
// import { csvInputFilePicker } from "../utils/utils";
// import { uploadUserCsv } from "../api/api";
// import { toast } from "react-toastify";
// import LovedOnePhoneName from "../components/LovedPhoneName";
// import errorsIcon from "../assets/images/errorsIcon.svg";
// import { useErrorHandler } from "react-error-boundary";
// import { getAutoLoginUserid } from "utils/localStorage";
// import selctContact from "../assets/images/selctContact.svg";

// export default function ImportContacts() {
//   const navigate = useNavigate();
//   const lovedOne = useSelectedLovedOne();
//   const [fileSelected, setFileSelected] = useState(0);
//   const [showModal, setShowModal] = useState(false);
//   const [importError, setImportError] = useState({
//     first_name: "",
//   });
//   const auto_login_id = getAutoLoginUserid()
//   const handleError = useErrorHandler();
//   const handleModal = (status) => () => {
//     setShowModal(status);
//     navigate("/SearchContact")
//   };
//   const onClickAddContacts = () => {
//     navigate("/AddNewContact");
//   };

//   const onClickImportBtn = async () => {
//     let selected = 0;
//     try {
//       const data = await csvInputFilePicker();
//       if (data) {
//         setFileSelected(1);
//         selected = 1;
//         let uploadData = {
//           data,
//           lovedOneId: lovedOne?.id,
//         };

//         console.log("uploadData", uploadData)
//         const result = await uploadUserCsv(uploadData);
//         console.log("result.data.data", result.data.data)
//         console.log("result.data.data", result.data.data.errors)
//         if (result.data.data.errors.length > 0) {
//           setImportError(result.data.data);
//           setShowModal(true);
//         } else navigate("/ContactUploadSucc", { state: result.data });
//       }
//     } catch (error) {
//       if (error.name === 'AbortError') {
//         // Handle the user aborted request specifically
//       } else {
//         if (selected) {
//           toast("Please select proper csv", { type: "error" });
//         }
//         if (error?.response?.data?.statusCode !== 400) {
//           handleError(error);
//         }
//       }
//     }
//   };

//   console.log("importError", importError)

//   return (
//     <div className="mainPage">
//       <div className="Homeheaderbg ">
//         <div className="">
//           <NavLink to="/SearchContact">
//             <img src={back_white} alt="" />
//           </NavLink>
//         </div>
//         <div className="customer-info">
//           <div className="col-md-12">
//             <h1>Add/Import</h1>
//             <h3>Contacts</h3>
//           </div>
//         </div>
//       </div>
//       <LovedOnePhoneName loved_one={lovedOne} />

//       <div className="contentBody contentboxgray contentbodymedia  text-center">
//         <div className="contentbodypadd pt-4">
//           <div className="col-md-12">
//             <h2>
//               Add Family and Friends to your Loved One's Big<b>Purple</b>Phone
//               here.
//             </h2>
//             <button
//               type="button"
//               className="btn btn-primary w-100 btngreen mt-3"
//               onClick={onClickAddContacts}
//             >
//               <img src={addCircle} className="me-2" alt="add contact" />
//               Add New Contacts
//             </button>
//             {!auto_login_id && (
//               <>

//                 <h4>OR</h4>

//                 <button
//                   type="button"
//                   className="btn btn-primary w-100 btnblue"
//                   onClick={onClickImportBtn}
//                 >
//                   <img src={importContacts} className="me-2" alt="import contact" />
//                   Import Contacts
//                 </button>

//                 <div className="customtextbbx">
//                   <h2>INSTRUCTIONS FOR IMPORTING CONTACTS</h2>
//                   <ol>
//                     <li>
//                       Download{" "}
//                       <a href={csvUrl} style={{ color: "blue" }}>
//                         {" "}
//                         Sample_excel{" "}
//                       </a>{" "}
//                       Please use the format exactly as the CSV provided to upload
//                       contacts.
//                     </li>
//                     <li>
//                       Adding gender will determine the image we assign to the
//                       contact until you add a photo.
//                     </li>
//                     <li>
//                       If you add more details such as Address, these will be added
//                       to your Loved One's contacts on their Big<b>Purple</b>Phone.
//                     </li>
//                   </ol>
//                 </div>
//               </>
//             )}
//           </div>
//         </div>
//       </div>

//       {/* <CustomModal
//         toggleModal={handleModal}
//         isOpen={showModal}
//         title={"Errors"}
//         description={
//           <>
// <div style={{ textAlign: "center" }}>
//   <img src={selctContact} alt="image" width="80" />
// </div>
//             <p className="mb-0 mt-3">
//               Following are the errors in imported excel
//               <br />
//               <ol>
//                 {importError.errors &&
//                   importError.errors.map((error, index) => (
//                     <li key={index}>
//                       {error.reasons.all}
//                     </li>
//                   ))}
//               </ol>
//             </p>
//             <p className="mb-0 mt-3">

//               Except for these contacts, all the others contacts have been successfully added.
//               <br />
//               <ol>
//                 {importError.errors && Object.keys(importError.count).map((key) => (
//                   <li key={key}>
//                     {key} = {importError.count[key]}
//                   </li>
//                 ))}
//               </ol>
//             </p>
//           </>
//         }
//       /> */}
//       <CustomModal
//         toggleModal={handleModal}
//         isOpen={showModal}
//         // title={"Errors"}
//         description={
//           <>
//             <div className="bluebox">
//               <div className="circlemark">
//                 <img src={selctContact} />
//               </div>
//               {/* <h5 className="text-white pt-4 mb-0">Contacts</h5> */}
//             </div>
//             <p className="mb-0 mt-3">
//               New contacts have been successfully imported.

//               Some Contacts already existed and so were not imported. To update a Contact, please do so in the Edit Contact page.
//               {/* <br />
//               <ol>
//                 {importError.errors &&
//                   importError.errors.map((error, index) => (
//                     <li key={index}>
//                       {error.reasons.all}
//                     </li>
//                   ))}
//               </ol> */}
//             </p>
//             {/* <p className="mb-0 mt-3">

//               Except for these contacts, all the others contacts have been successfully added.
//               <br />
//               <ol>
//                 {importError.errors && Object.keys(importError.count).map((key) => (
//                   <li key={key}>
//                     {key} = {importError.count[key]}
//                   </li>
//                 ))}
//               </ol>
//             </p> */}
//           </>
//         }
//       />
//     </div>
//   );
// }

import React, { useState } from "react";
import { useNavigate, NavLink } from "react-router-dom";
import { csvUrl } from "../utils/service";
import back_white from "../assets/images/back_white.svg";
import addCircle from "../assets/images/addCircle.svg";
import importContacts from "../assets/images/importContacts.svg";
import CustomModal from "../components/Modal/modal";
import useSelectedLovedOne from "../hooks/useSelectedLovedOne";
import { csvInputFilePicker } from "../utils/utils";
import { uploadUserCsv } from "../api/api";
import { toast } from "react-toastify";
import LovedOnePhoneName from "../components/LovedPhoneName";
import errorsIcon from "../assets/images/errorsIcon.svg";
import selctContact from "../assets/images/selctContact.svg";
import greenselect from "../assets/images/greenselect.svg";
import { useErrorHandler } from "react-error-boundary";
import { getAutoLoginUserid } from "utils/localStorage";

export default function ImportContacts() {
  const navigate = useNavigate();
  const lovedOne = useSelectedLovedOne();

  const [fileSelected, setFileSelected] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [importMessage, setImportMessage] = useState("");
  const [iconSrc, setIconSrc] = useState("");
  const auto_login_id = getAutoLoginUserid();
  const handleError = useErrorHandler();

  const handleModal = (status) => () => {
    setShowModal(status);
    if (!status) navigate("/SearchContact");
  };

  const onClickAddContacts = () => {
    navigate("/AddNewContact");
  };

  const onClickImportBtn = async () => {
    let selected = 0;
    try {
      const data = await csvInputFilePicker();

      if (data) {
        setFileSelected(1);
        selected = 1;
        let uploadData = {
          data,
          lovedOneId: lovedOne?.id,
        };

        const result = await uploadUserCsv(uploadData);
        const importData = result.data.data;

        if (importData.errors.length > 0) {
          // Check if no contacts were added
          if (importData.count["Added Contacts"] === 0) {
            setModalTitle("Error");
            setIconSrc(errorsIcon);

            // Collect error messages
            const errorMessages = importData.errors
              .map((error) => {
                const reasons = Object.values(error.reasons).join(", ");
                return `Row ${error.row}: ${reasons}`;
              })
              .join(" | "); // Join all error messages

            setImportMessage(`Errors: ${errorMessages}`);
          } else {
            setModalTitle("Notice");
            setIconSrc(greenselect);
            setImportMessage(
              "New contacts have been successfully imported. Some contacts already existed and so were not imported. To update a contact, please do so in the Edit Contact page."
            );
          }
          setShowModal(true);
        } else {
          navigate("/ContactUploadSucc", { state: importData });
        }
      }
    } catch (error) {
      if (error.name === "AbortError") {
        // Handle the user aborted request specifically
      } else {
        if (selected) {
          toast("Please select proper csv", { type: "error" });
        }
        if (error?.response?.data?.statusCode !== 400) {
          handleError(error);
        }
      }
    }
  };

  return (
    <div className="mainPage">
      <div className="Homeheaderbg">
        <div className="">
          <NavLink to="/SearchContact">
            <img src={back_white} alt="" />
          </NavLink>
        </div>
        <div className="customer-info">
          <div className="col-md-12">
            <h1>Add/Import</h1>
            <h3>Contacts</h3>
          </div>
        </div>
      </div>
      <LovedOnePhoneName loved_one={lovedOne} />

      <div className="contentBody contentboxgray contentbodymedia text-center">
        <div className="contentbodypadd pt-4">
          <div className="col-md-12">
            <h2>
              Add Family and Friends to your Loved One's Big<b>Purple</b>Phone
              here.
            </h2>
            <button
              type="button"
              className="btn btn-primary w-100 btngreen mt-3"
              onClick={onClickAddContacts}
            >
              <img src={addCircle} className="me-2" alt="add contact" />
              Add New Contacts
            </button>
            {!auto_login_id && (
              <>
                <h4>OR</h4>
                <button
                  type="button"
                  className="btn btn-primary w-100 btnblue"
                  onClick={onClickImportBtn}
                >
                  <img
                    src={importContacts}
                    className="me-2"
                    alt="import contact"
                  />
                  Import Contacts
                </button>
                <div className="customtextbbx">
                  <h2>INSTRUCTIONS FOR IMPORTING CONTACTS</h2>
                  <ol>
                    <li>
                      Download{" "}
                      <a href={csvUrl} style={{ color: "blue" }}>
                        Sample_excel
                      </a>{" "}
                      Please use the format exactly as the CSV provided to
                      upload contacts.
                    </li>
                    <li>
                      Adding gender will determine the image we assign to the
                      contact until you add a photo.
                    </li>
                    <li>
                      If you add more details such as Address, these will be
                      added to your Loved One's contacts on their Big
                      <b>Purple</b>Phone.
                    </li>
                  </ol>
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      <CustomModal
        toggleModal={handleModal}
        title={modalTitle}
        isOpen={showModal}
        description={
          <>
            <div style={{ textAlign: "center" }}>
              <img src={iconSrc} alt="icon" width="80" />
            </div>
            <p className="mb-0 mt-3 text-center">{importMessage}</p>
          </>
        }
      />
    </div>
  );
}
