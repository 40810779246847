import React, { useState, useReducer, useEffect } from "react";
import {
  Navigate,
  NavLink,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import back_white from "../../assets/images/back_white.svg";
import iconDelete from "../../assets/images/icon-delete.svg";
import left_arrow from "../../assets/images/left_arrow.svg";
import right_arrow from "../../assets/images/right_arrow.svg";
import IconfeatherHeart from "../../assets/images/Icon feather-heart.svg";
import heartFill from "../../assets/images/heartFill.svg";
import LovedOnePhoneName from "../../components/LovedPhoneName";
import {
  deletePFAphotos,
  getGallaryPhotoById,
  likeDislikeFAphotos,
} from "../../api/api";
import useSelectedLovedOne from "../../hooks/useSelectedLovedOne";
import deleteConfirmIcon from "../../assets/images/delete_confrm.svg";
import { useErrorHandler } from "react-error-boundary";
import useLoginUser from "hooks/useLoginUser";
import { useSelector } from "react-redux";
import { speakText } from "utils/utils";

export default function PFASingleImage({ handleUpdate }) {
  const navigate = useNavigate();
  const param = useParams();
  const [showDeleteModel, setShowDeleteModel] = useState(false);
  const [item, setItem] = useState({});
  const loved_one = useSelectedLovedOne();
  const [is_like, setIsLike] = useState(false);
  const handleError = useErrorHandler();
  const { profile } = useLoginUser();
  const location = useLocation();
  const settings = useSelector((state) => state.settings);


  console.log("location", location)

  const checkLo = profile.lovedOneList.length > 0 && (
    profile.lovedOneList[0].relation === 'Self' || profile.lovedOneList[0].lo_id
  );
  console.log("item :>> ", item);
  useEffect(() => {
    setIsLike(item.is_like);
  }, [item && item.id]);
  let user_id;
  useEffect(() => {
    if (profile.lovedOneList.length > 0) {
      user_id = profile.lovedOneList[0].id
    }
    else {
      user_id = profile.id;
    }

    console.log("user_id", user_id)
    let params = {
      user_id: user_id,
      lovedOneId: loved_one.id,
      // role: location?.state?.role,
      // role: "users",
      role:"loved_one",
      id: param.id,
    };


    getGallaryPhotoById(params)
      .then((res) => {
        const { data } = res.data;
        setItem(data);
        setIsLike(data.is_like);
      })
    // .catch((er) => navigate("/NotFound"));
  }, [param.id]);

  const onClickDeletePhoto = async () => {
    speakText("Delete", true)
    let user_id;
    if (profile.lovedOneList.length > 0) {
      user_id = profile.lovedOneList[0].id
    }
    else {
      user_id = profile.id;
    }
    deletePFAphotos({
      id: item.id,
      user_id: user_id,
      role: "lo",
      lovedOneId: loved_one.id,
    })
      .then(() => {
        setShowDeleteModel();
        navigate("/PFAGallaryImages");
      })
      .catch((error) => {
        return error.response.data.statusCode != 400 ? handleError(error) : "";
      });
  };

  const toggleDeleteModel = (item) => {
    speakText(item, true)
    setShowDeleteModel((prev) => !prev);
  }

  const toggleLike = () => {
    speakText(is_like ? "UnLike" : "like", true)
    console.log(
      "Location in Like",
      location?.state?.role
    )
    let user_id;

    if (profile.lovedOneList.length > 0) {
      console.log("if condition")
      user_id = profile.lovedOneList[0].id
    }
    else {
      console.log("else block", profile.lovedOneList)
      user_id = profile.pfa_id;
    }
    likeDislikeFAphotos({
      id: item.id,
      user_id: user_id,
      lovedOneId: loved_one.id,
      isLike: is_like === 0 ? 1 : 0,
      // role: location?.state?.role ? 'lo' : "users",
      // role: "lo"
      role:"users"
    })
      .then((res) => {
        setIsLike(is_like === 0 ? 1 : 0);
        // handleUpdate({ id: item.id, is_like: is_like === 0 ? 1 : 0 });
      })
      .catch((error) => {
        console.log("error", error)
        return error.response.data.statusCode != 400 ? handleError(error) : "";
      });
  };

  const onClickBack = () => {
    navigate("/PFAGallaryImages");
  };

  const handleArrow = (id) => () => {
    navigate(`/PFAGallaryImage/${id}`, {
      state: {
        role: item.shared_by_role // Assuming `item.role` contains the role you want to pass
      }
    });
  };
  return (
    <div className="pt80">
      <div className="mainPage">
        <div className="Homeheaderbg text-white headerfixed">
          <div className="d-flex align-self-center">
            <img src={back_white} alt="" title="" onClick={onClickBack} />

            <h2 className="fs20 mb-0 text-center mx-auto">View Photo</h2>
          </div>
        </div>
        <LovedOnePhoneName loved_one={loved_one} className="full-view-header" />

        <div className="contentBody contentbodymedia contentboxgray pt-4 bgGrayHeight">
          <div className="contentBody">
            <div className="row">
              <div className="col-12">
                <div className="fix-img-container">
                  <div className="fullimg">
                    <img
                      src={item.photo}
                      className="bigimgview img-full-height"
                    />
                    <img
                      src={iconDelete}
                      onClick={() => { toggleDeleteModel('Delete') }}
                      className="smallimgview"
                    />{" "}
                    <>
                      {/* {item.prev_id ? (
                        <img
                          src={left_arrow}
                          className="leftSlideArrow"
                          onClick={handleArrow(item.prev_id)}
                        />
                      ) : (
                        " "
                      )}
                      {item.next_id ? (
                        <img
                          src={right_arrow}
                          className="rightSlideArrow"
                          onClick={handleArrow(item.next_id)}
                        />
                      ) : (
                        ""
                      )} */}
                    </>
                  </div>
                </div>
                <div className="text-center">
                  <p className="photo-description">{item.description}</p>
                </div>
                <div className="shareIconsBlog">
                  <div className="wishlistbox ">
                    <img
                      src={is_like === 0 ? IconfeatherHeart : heartFill}
                      onClick={toggleLike}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal centered show={showDeleteModel} onHide={toggleDeleteModel}>
        <Modal.Header closeButton style={{ width: "100%" }}>
          <Modal.Title style={{ width: "100%" }}>
            <h3 className="text-center">Confirmation</h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="mb-3 text-center">
            <img src={deleteConfirmIcon} alt="delete confirmation" />
          </p>
          <p className="text-center">
            Are you sure you want to delete this photo ?
          </p>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => { toggleDeleteModel("Cancel") }}
          >
            Cancel
          </button>
          <button
            type="button"
            className="btn btn-danger"
            onClick={onClickDeletePhoto}
          >
            Delete
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
