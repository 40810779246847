import React from "react";
import { useNavigate } from "react-router-dom";

import selctContact from "../assets/images/selctContact.svg";

export default function RegSucessful() {
    const navigate = useNavigate();

    const onClick = () => {
        navigate("/SearchContact");
    };

    return (
        <div className="mainPage loginPage">
            <div className="successful positioncenter">
                <div className="card  text-center">
                    <div className="bluebox">
                        <div className="circlemark">
                            <img alt="" src={selctContact} />
                        </div>
                        <h5 className="text-white pt-4 mb-0">Contact</h5>
                    </div>
                    <div className="card-body">
                        <p className="card-text">Contact Added successfully.</p>

                        <button type="button" onClick={onClick} className="btn btn-primary w-100 mt-3">
                            Ok
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
