import React from "react";
import useSetupData from "../../hooks/useSetupData";
import SetupLayout from "./SetUpLayout";
// import { useDispatch, useSelector } from "react-redux";
// import { handleConfirm, setStatus } from "../../redux/setupStore";
import { useNavigate } from "react-router-dom";
import UploadProfilePhoto from "components/UploadProfilePhoto";

export default function UploadProfile() {
  const setupData = useSetupData();
  // const dispatch = useDispatch();
  const navigate = useNavigate();
  // const setupStore = useSelector((state) => state.setup);

  const handleUpload = (photo, prevUrl) => {
    let payload = {
      status: false,
    };
    // dispatch(setStatus(payload));
    console.log("photo :>> ", photo);
    if (photo) {
      navigate("/setup/ShowProfilePhoto", { state: { photo, prevUrl } });
    }
  };

  return (
    <SetupLayout backNav={"WelcomeScreen"}>
      <div className="">
        <UploadProfilePhoto handleUpload={handleUpload} />

        <div className="contentBody fulogin">
          <div className="row fmsignup">
            <div className="col-lg-12 mb-5">
              <h2 className="topTileHeading">
                Upload your profile
                <br /> picture
              </h2>

              <p>
                Your Loved One will use images to make finding friends and family
                easy.
              </p>

              <p>
                Please upload your profile picture above by clicking on the pen
                icon next to the profile picture. You can always change it
                later.
              </p>
            </div>
          </div>
        </div>
      </div>
    </SetupLayout>
  );
}
