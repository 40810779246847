import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import useSelectedLovedOne from "hooks/useSelectedLovedOne";
import { setAppProtected, setPin } from "api/api";

export default function CustomModel({ title, onHide, show, pinData: initialPinData, setPin: setParentPin, getWebData, setIsformSubmitted, formSubmitted, option }) {
  const lovedone = useSelectedLovedOne();
  const [pinValues, setPinValues] = useState(["", "", "", ""]);
  const pinRefs = [useRef(), useRef(), useRef(), useRef()];
  const [pinData, setPinData] = useState(initialPinData);


  console.log("show", show)

  useEffect(() => {
    setPinValues(initialPinData ? initialPinData.split("") : ["", "", "", ""]);
  }, [initialPinData]);

  const handlePinChange = (index, event) => {
    const inputValue = event.target.value;
    if (/^\d*$/.test(inputValue) && inputValue.length <= 4) {
      const newPinValues = [...pinValues];
      newPinValues[index] = inputValue;
      setPinValues(newPinValues);
      if (index < pinValues.length - 1 && inputValue.length === 1) {
        pinRefs[index + 1].current.focus();
      }
    }
  };

  const handleSubmit = () => {
    setIsformSubmitted(!formSubmitted)
    const newPinData = pinValues.join("");
    const requestData = {
      loved_one_id: lovedone.id,
      pin: newPinData
    };

    setPin(requestData).then((res) => {
      if (res?.data?.message) {
        if (!newPinData) {
          toast(`PIN Removed Successfully`, { type: "success" });
          setParentPin(newPinData);
          getWebData();
          onHide();
        }
        else {
          toast(`PIN Added Successfully`, { type: "success" });
          onHide();
          setParentPin(newPinData); // Update parent component's pinData state
          // getWebData();
        }
      }
      const requestBody = {
        loved_one_id: lovedone.id,
        external_menu_id: option.menuId,
        lock_status: option.is_locked === 1 ? 0 : 1
      }
      if (newPinData) {
        setAppProtected(requestBody).then((res) => {
          // toast(`${res?.data?.message}`, { type: "success" });
          getWebData();

        })
      }
    });
  };

  const removePin = () => {
    setPinValues(["", "", "", ""]);
    setPinData(""); // Clear pinData in this component
  };

  return (
    <Modal centered show={show} onHide={onHide} className="pinmodal" backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          {pinValues && pinValues.map((pin, index) => (
            <div className="col" key={index}>
              <input
                type="text"
                className="pin-code-input"
                value={pin}
                onChange={(event) => handlePinChange(index, event)}
                maxLength={1}
                ref={pinRefs[index]}
              />
            </div>
          ))}
        </div>
        {initialPinData && (
          <div className="text-center mt-4">
            <button onClick={removePin} className="rem-txt-link">Remove Pin</button>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className="btn btn-primary w-100"
          onClick={handleSubmit}
        >
          Submit
        </button>
      </Modal.Footer>
    </Modal>
  );
}

