import axios from "axios";
import { getToken } from "../utils/localStorage";
import { baseUrl } from "utils/service";
// Set config defaults when creating the instance
axios.defaults.baseURL = baseUrl;

const Request = (configs = {}) => {
  let headers = {
    "Content-Type": "application/json",
    ...configs.headers,
  };

  if (getToken()) {
    headers.authorization = `Bearer ${getToken()}`;
  }
  configs = { ...configs, url: configs.path, headers };
  return axios(configs);
};

export const GET = (path, configs = {}) =>
  Request({ ...configs, path, method: "GET" });
export const POST = (path, configs = {}) =>
  Request({ method: "POST", path, ...configs });

export const PUT = (path, configs = {}) =>
  Request({ ...configs, path, method: "PUT" });
export const DELETE = (path, configs = {}) =>
  Request({ ...configs, path, method: "DELETE" });
