import React, { useEffect, useState } from "react";
import back_white from "../assets/images/back_white.svg";
import { NavLink } from "react-router-dom";
import useSelectedLovedOne from "../hooks/useSelectedLovedOne";
import { Spinner, Modal } from "react-bootstrap";
import { getDeviceDetails } from "../api/api";
import { useErrorHandler } from "react-error-boundary";
import loader from "assets/images/loader.gif"


export default function PFABillingDetails() {
  const [isLoading, setLoading] = useState(false);
  const [deviceDetails, setDeviceDetails] = useState({
    imei: "",
    model_no: "",
    serial_no: "",
  });
  const lovedOne = useSelectedLovedOne();
  const handleError = useErrorHandler();
  useEffect(() => {
    setLoading(true);
    if (lovedOne) {
      getDeviceDetails(lovedOne.id)
        .then((result) => {
          setDeviceDetails(result.data.data);
        })
        .catch((error) => {
          setDeviceDetails({
            imei: "",
            model_no: "",
            serial_no: "",
          });
          console.log(error);
          return error.response.data.statusCode != 400
            ? handleError(error)
            : "";
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [lovedOne && lovedOne.id]);
  console.log("deviceDetails", deviceDetails);
  return (
    <div className="pt80">
      <div className="mainPage">
        <div className="Homeheaderbg headerfixed text-white">
          <div className="d-flex align-self-center">
            <NavLink to="/PFASetting">
              <img src={back_white} alt="" title="" />
            </NavLink>
            <h2 className="fs20 mb-0 text-center mx-auto raleway_font">
              Billing Details
            </h2>
          </div>
        </div>
        <div className="parent">
          <div className="contentboxgray11">
            <br />
          </div>
        </div>
        {isLoading ? (
          // <Spinner animation="border" variant="primary" />
          <div className="loader-container">
            <img src={loader} alt="Loading..." className="custom-loader" />
          </div>
        ) : (
          <div className="contentBody contentbodymedia">
            <div className="billdetails">
              <h5>Device Information</h5>
              <div className="list">
                <ul>
                  <li>
                    IMEI<span>{deviceDetails.imei}</span>
                  </li>
                </ul>
              </div>
              {/* <div className="pt-3">
                <h5>Next Payment</h5>
                <p>
                  Your next payment of $79 will be debited on 15th February 2022
                </p>
              </div> */}
              <div className="pt-3">
                <h5>Billing History</h5>
                <p>
                  Device Information. To view billing history and download statements {" "}
                  <a
                    href={process.env.REACT_APP_SHOPIFY_BILL_URL}
                    className="clrblue"
                    target="blank"
                  >
                    click here
                  </a>
                  .{" "}
                </p>
              </div>
              {/* <div className="pt-3">
                <h5>Billing Information</h5>
                <p>
                  To update billing details{" "}
                  <a
                    href={process.env.REACT_APP_SHOPIFY_BILL_URL}
                    className="clrblue"
                    target="blank"
                  >
                    click here
                  </a>
                  .{" "}
                </p>
              </div> */}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
