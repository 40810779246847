import { createSlice } from "@reduxjs/toolkit";

export const setup = createSlice({
  name: "setup",
  initialState: { shopifyJson: null, data: {}, status: false },
  reducers: {
    setData: (state, action) => {
      console.log("action.payload :>> ", action.payload);
      let name = `${action.payload.customer.first_name} ${action.payload.customer.last_name}`;
      action.payload.customer.name = name;
      state.shopifyJson = action.payload;
      console.log("action.payload :>> ", action.payload);
      state.data.order_id = JSON.stringify(action.payload.id);
      state.data.order_number = action.payload.name;
      console.log("action.payload.note :>> ", action.payload.note);
      state.data.imei = action.payload.note
        ? action.payload.note.split(":")[1]
        : null;
    },
    setStatus: (state, action) => {
      state.status = action.payload.status;
    },
    handleConfirm: (state, action) => {
      const { payload } = action;
      console.log("action.payload :>> ", payload);
      console.log("payload.data.photo :>> ", payload.data?.photo);
      state.data = { ...state.data, ...payload.data };
      state.status = true;
    },
  },
});

export const { setData, handleConfirm, setStatus } = setup.actions;

export default setup.reducer;
