import React, { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useLoginUser from "hooks/useLoginUser";
import { sendVideoRequest, sendGroupVideoRequest } from "api/api";
import useSelectedLovedOne from "hooks/useSelectedLovedOne";
import hangUpIcon from "assets/images/hangUp.svg";
import outgoing_ringtone from "assets/audio/outgoing_ringtone.mp3";
import { onMessageListener } from "utils/firebase";
import { speakText } from "utils/utils";
import { useSelector } from "react-redux";

export default function VideoDial({
  setRoomId,
  is_join,
  handleRejectCall,
  room_id,
  handleError,
  users,
}) {
  const lovedOne = useSelectedLovedOne();
  const settings = useSelector((state) => state.settings);
  const { profile } = useLoginUser();
  const navigate = useNavigate();
  const [playing, setPlaying] = useState(false);
  const [receivers, setReceivers] = useState(users);
  const [audio] = useState(new Audio(outgoing_ringtone));
  const playingRef = useRef(playing);

  playingRef.current = playing;
  useEffect(async () => {
    return () => {
      audio.pause();
      audio.currentTime = 0;
    };
  }, []);
  console.log("users :>> ", users);
  useEffect(async () => {
    let data = {};
    let receivers = users.map((u) => {
      return {
        receiver_id: u.id,
        receiver_role: u.role,
        user_role: u.user_role,
        mobile: u.mobile,
      };
    });
    data.receivers = JSON.stringify(receivers);
    data.role = "loved_one"
    setPlaying(true);
    sendGroupVideoRequest(data)
      .then((res) => {
        console.log("res :>> ", res);
        const { data } = res.data;
        console.log("data  sendGroupVideoRequest:>> ", data);
        setRoomId(data);
        setPlaying(true);
      })
      .catch((error) =>
        error.response.data.statusCode != 400 ? handleError(error) : ""
      );
  }, []);

  useEffect(() => {
    console.log("playing useEffect:>> ", playing, room_id);
    if (playing) {
      audio.play();
      audio.loop = true;
      setTimeout(() => {
        console.log("playingRef :>> ", playingRef);
        if (playingRef.current) {
          handleHangupBtn();
        } else {
          console.log("false playing cut call");
        }
      }, 180000);
      // 180000;
    } else {
      audio.pause();
      audio.loop = false;
      audio.currentTime = 0;
    }
  }, [playing]);

  const handleHangupBtn = () => {
    speakText("Hang Up", true)
    let data = {
      caller_role: "users",
      receiver_role: "loved_one",
      receiver_id: lovedOne.id,
      caller_id: profile?.id,
      // room_id,
      type: "caller",
    };
    handleRejectCall(data);
    setPlaying(false);
    audio.pause();
    audio.loop = false;
  };
  useEffect(() => {
    if (is_join) {
      setPlaying(false);
    }
  }, [is_join]);

  useEffect(() => {
    onMessageListener().then((payload) => {
      console.log("payload :>> ", payload);
      const { data } = payload;
      if (payload.data.type == "rejectVideoCall") {
        let updated_receivers = [...receivers];
        let index = updated_receivers.findIndex(
          (r) => r.id == data.receiver_id && r.role == data.receiver_role
        );
        updated_receivers[index].ring = false;
        setReceivers(updated_receivers);
        if (updated_receivers.every((u) => u.ring == false)) {
          setPlaying(false);
          audio.pause();
          audio.loop = false;
          navigate("/VideoContacts");
        }
      }
    });
  });
  return (
    <div
      className={`contentBody  pt-5 mt-0  text-center ${is_join ? "d-none" : ""
        }`}
    >
      <div className="callOuterContainer">
        <div className="row justify-content-center">
          <div className="col-8">
            <div className="effect newAnim">
              <span className="callAnimation"></span>
              <img
                src={
                  receivers.length > 1
                    ? `${profile.photo}`
                    : `${users[0]?.photo}`
                }
                className="videocallcircle"
                alt=""
                title=""
              />
            </div>
          </div>
        </div>

        {receivers.length > 1 ? (
          <>
            <div className="col-12">
              <h3 className="fs30 py-3 mb-0 text-white">
                Please wait a few minutes for others to join.
              </h3>
            </div>
            {/* <div className="contentboxgray bottomBlogBg">
            <div className="accordion" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingOne">
                  <button
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  ></button>
                </h2>
                <div
                  id="collapseOne"
                  className="accordion-collapse collapse show"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <div className="col-md-12">
                      <h5
                        className="pt-2 pb-1 fsmed"
                        style={{ textAlign: "left" }}
                      >
                        Video Call List
                      </h5>

                      <div className="mb-2 mt-2">
                        <a href="#"></a>
                        <img
                          className="callDeclineBtn"
                          width="100"
                          src={hangUpIcon}
                          alt=""
                          title=""
                          onClick={handleHangupBtn}
                        />
                      </div>

                      <div className="messagesBox">
                        People invited to a group call can join an ongoing group
                        call at anytime.
                      </div>
                      {receivers.map((u) => {
                        return (
                          <div className="media boxmediawhite m-0 mt-2">
                            <div className="imghoverbox">
                              <img
                                className="align-self-center imageover"
                                src={u.photo}
                                alt="receiver"
                              />
                            </div>
                            <div className="media-body">
                              <h5 className="mt-0 mb-0">
                                {u.name.substring(0, 8)}
                              </h5>
                            </div>
                            <div
                              className={`mediaarrow ${u.ring ? "ringBg" : ""}`}
                            >
                              <a href="#">RING</a>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          </>
        ) : (
          <div className="row justify-content-center pt-5">
            <div className="col-6">
              <img
                className="callImgHeight"
                src={`${profile?.photo}`}
                alt=""
                title=""
              />
            </div>
            <div className="col-12">
              <h3 className="fs30 py-3 mb-0 text-white calltext">
                Video Calling
                <div>{users[0]?.first_name || users[0].name}</div>
              </h3>
            </div>
          </div>
        )}

        <div className="row">
          <div className="col-12">
            <img
              className="callDeclineBtn"
              src={hangUpIcon}
              alt=""
              title=""
              onClick={handleHangupBtn}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
