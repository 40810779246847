// import { logout } from "api/api";
import { useEffect } from "react";
import NotFound from "screens/NotFound";

export default function ErrorFallback({ error, resetErrorBoundary }) {
  console.log("error  gdfgfdgdfgfd:>> ", error.response);
  let errorCodes = ["400", "401", 400, 401];
  useEffect(() => {
    console.log("error serverError :>> ", error);

    if (error?.response?.data?.statusCode == "401") {
      // if (localStorage.getItem("profile")) {
      //   logout().then((res) => {
      //     console.log("res :>> ", res);
      //   });
      // }
      localStorage.removeItem("profile");
      localStorage.removeItem("api_token");
      localStorage.removeItem("callingUrl");
      window.location.assign("/");
    }
  }, []);
  console.log(
    "errorCodes.includes(error?.response?.data?.statusCode) :>> ",
    errorCodes.includes(error?.response?.data?.statusCode),
    error?.response?.data?.statusCode
  );
  return errorCodes.includes(error?.response?.data?.statusCode) ? (
    false
  ) : (
    <div role="alert" className="text-center">
      <NotFound
        serverError
        resetErrorBoundary={resetErrorBoundary}
        error={error.response?.data}
      />
    </div>
  );
}
