import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { getUserProfileWithLovedOne } from "../api/api";
import { changeLovedOne } from "redux/lovedOne";
import {
  getProfile,
  setProfile,
  setToken,
  setAutoLoginUserId,
} from "utils/localStorage";
import { signin } from "redux/user";
import MenuScreen from "./MenuScreen";

export default function AutoLogin() {
  const [isAutoLoginComplete, setAutoLoginComplete] = useState(false);
  const [hasError, setHasError] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const profile = getProfile();
  const location = useLocation();
  const lovedOne = useSelector((state) => state.lovedOne.value);
  const user = useSelector((state) => state.user);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const auto_login_id = queryParams.get("auto_login_id");
    const auto_login_lovedoneid = queryParams.get("auto_login_lovedoneid");

    const performAutoLogin = async () => {
      if (auto_login_id && auto_login_lovedoneid) {
        try {
          const response = await getUserProfileWithLovedOne({
            authId: auto_login_id ? auto_login_id : profile?.id,
            lovedOneId: auto_login_lovedoneid,
            role: "pfa",
          });

          const { data } = response;
          console.log(
            "data.data[0].lovedOneList**",
            data.data[0].lovedOneList[0]
          );
          if (data.message === "Successful") {
            setAutoLoginUserId(auto_login_id);
            setProfile(data.data[0]);
            setToken(data.data.token);
            dispatch(signin());
            dispatch(changeLovedOne(data.data[0].lovedOneList[0]));
            navigate("/MenuScreen", {
              state: data.data[0].lovedOneList[0],
            });
          } else {
            throw new Error("Auto login failed");
          }
        } catch (error) {
          console.error(
            "Error fetching user profile with loved one data:",
            error
          );
          setHasError(true);
        } finally {
          setAutoLoginComplete(true);
        }
      } else {
        setAutoLoginComplete(true);
      }
    };

    performAutoLogin();
  }, [location.search]);

  if (!isAutoLoginComplete) {
    // You can show a loader or a splash screen here while the auto-login is in progress
    return <div>Loading...</div>;
  }

  if (hasError) {
    // Optionally handle error, e.g., redirect to login page
    navigate("/LoginScreen");
    return null;
  }

  return <MenuScreen />;
}
