import React, { useRef, useState, useEffect } from "react";
import { useNavigate, NavLink } from "react-router-dom";

import useLoginUser from "../../hooks/useLoginUser";
import { getLovedOneById, callStatus, getUserDetail } from "../../api/api";
import hangUpIcon from "../../assets/images/hangUp.svg";
import answerPhoneIcon from "../../assets/images/receive.svg";
import ringtone from "../../assets/audio/ringtone.mp3";
import SplashComponent from "../SplashComponent";
import { useErrorHandler } from "react-error-boundary";
import { useSelector } from "react-redux";

export default function VideoRecieve({
  queryParam,
  handleHangupCall,
  handleAnswerCall,
  is_join,
  handleRejectCall,
}) {
  const { profile } = useLoginUser();
  const navigate = useNavigate();

  const [callerDetail, setCallerDetail] = useState(queryParam);
  const [playing, setPlaying] = useState(false);
  const [audio] = useState(new Audio(ringtone));
  const timer = useRef();
  const handleError = useErrorHandler();
  const playingRef = useRef(playing);

  playingRef.current = playing;
  const user = useSelector((state) => state.user);
  useEffect(() => {
    if (!user.isAuthorized) {
      navigate("/LoginScreen");
    }
  }, []);
  useEffect(() => {
    callStatus({ room_id: queryParam.room_id })
      .then((res) => {
        const { data, status } = res.data;
        console.log("data :>> ", data);
        if (data.is_disconnected || data.is_received) {
          navigate("/");
        } else {
          // let params = {
          //   id: queryParam.caller_id,
          //   role: queryParam.role,
          // };
          // getUserDetail(params)
          //   .then((res) => {
          //     setPlaying(true);
          //     setCallerDetail(res);
          //   })
          //   .catch((error) => {
          //     return error.response.data.statusCode != 400
          //       ? handleError(error)
          //       : "";
          //   });
        }
      })
      .catch((error) => {
        return error.response.data.statusCode != 400 ? handleError(error) : "";
      });

    return () => {
      audio.pause();
      audio.currentTime = 0;
      audio.loop = false;
    };
  }, []);
  useEffect(async () => {
    if (playing) {
      audio.play();
      audio.loop = true;
      await new Promise((resolve, reject) =>
        setTimeout(() => {
          console.log("playing setTimeout:>> ", playing);
          if (playingRef.current) {
            handleHangupCall();
          }
          // resolve(true);
        }, 180000)
      );
    } else {
      audio.pause();
      audio.currentTime = 0;
      audio.loop = false;
    }
  }, [playing]);

// Log caller details
console.log("Caller details:", callerDetail);

// Construct the calling URL
const callingUrl = `PFAVideoCall/${callerDetail.role}/${callerDetail.caller_id}/${callerDetail.room_id}?d=${encodeURIComponent(JSON.stringify(callerDetail))}`;

  const alreadyLogin = localStorage.getItem("profile");
  if (!alreadyLogin) {
    // Store the calling URL in localStorage
    localStorage.setItem("callingUrl", callingUrl);
  }

// Retrieve and log the stored URL
console.log("Get Calling URL From the localStorage:", localStorage.getItem("callingUrl"));

  const handleRecieve = () => {
    handleAnswerCall();
    setPlaying(false);
  };

  // const stopAudio = () => {
  //   audio.pause();
  //   audio.currentTime = 0;
  // };
  const handleHangupBtn = () => {
    let data = {
      caller_role: "loved_one",
      receiver_role: "users",
      caller_id: queryParam.caller_id,
      receiver_id: profile?.id,
      room_id: queryParam.room_id,
      type: "receiver",
    };
    setPlaying(false);

    handleRejectCall(data);
  };

  useEffect(() => {
    if (is_join) {
      setPlaying(false);
    }
  }, [is_join]);
  return callerDetail ? (
    <div className={`contentBody pt-5  text-center ${is_join ? "d-none" : ""}`}>
      <div className="callOuterContainer">
        <div className="row justify-content-center">
          <div className="col-8">
            <div className="effect newAnim">
              <span className="callAnimation"></span>
              <img
                src={`${callerDetail?.caller_photo}`}
                className="videocallcircle"
                alt=""
                title=""
              />
            </div>
          </div>
        </div>

        <div className="row justify-content-center pt-5">
          <div className="col-6">
            <img
              className="callImgHeight"
              src={`${profile?.photo}`}
              alt=""
              title=""
            />
          </div>
          <div className="col-12">
            <h3 className="fs30 py-3 mb-0 text-white calltext">
              Video Calling
              <div>{callerDetail?.caller_name}</div>
            </h3>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <img
              className="callDeclineBtn"
              src={answerPhoneIcon}
              alt=""
              title=""
              onClick={handleRecieve}
            />

            <img
              className="callDeclineBtn"
              src={hangUpIcon}
              alt=""
              title=""
              // onClick={handleDecline}
              onClick={handleHangupBtn}
            />
          </div>
        </div>
      </div>
    </div>
  ) : (
    <SplashComponent />
  );
}
