import { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import useSelectedLovedOne from "hooks/useSelectedLovedOne";
import { getSettings, updateSettings } from "../api/api";

import back_white from "assets/images/back_white.svg";
import avatar_size from "assets/images/avatar_size.svg";
import { useErrorHandler } from "react-error-boundary";

const Accessibility = () => {
    const [isLoading, setLoading] = useState(false);
    const lovedOne = useSelectedLovedOne();
    const [settings, setSettings] = useState({});
    const handleError = useErrorHandler();

    useEffect(() => {
        setLoading(true);
        if (lovedOne && lovedOne.id) {

            getSettings(lovedOne?.id ?? "")
                .then((data) => {
                    setLoading(false);
                    setSettings(data)
                })
                .catch((error) => {
                    return error.response.data.statusCode != 400
                        ? handleError(error)
                        : "";
                });

        }
    }, [lovedOne && lovedOne.id]);

    const handelSettings = (value) => {
        console.log(value, "value:::::");
        value = settings.avatars_size ? 0 : 1;
        setSettings({ ...settings, avatars_size: value })
        updateSettings({ ...settings, lovedOneId: lovedOne?.id, avatars_size: value })
            .then((data) => {
            })
            .catch((error) => {
                return error.response.data.statusCode != 400
                    ? handleError(error)
                    : "";
            });
    }
    console.log(settings, "settings::::::::::")
    return (
        <div>
            <div className="Homeheaderbg text-white">
                <div className="d-flex align-self-center">
                    <NavLink to="/MenuScreen">
                        <img src={back_white} />
                    </NavLink>

                    <h2 className="fs20 mb-0 text-center mx-auto">
                        Accessibility
                    </h2>
                </div>
            </div>
            <div className="parent">
                <div className="contentboxgray11">
                    <br />
                </div>
            </div>
            <div className="contentBody contentbodymedia manageapps">
                <div className="">
                    <div className="row justify-content-center">



                        <div key={"Voicemail"} className="media radioCircleImages">
                            <img
                                className="align-self-center "
                                src={avatar_size}
                                alt=""
                                title=""
                            />
                            <div className="media-body">
                                <h5 className="mt-0 mb-0">{`Change the size of the ${lovedOne.first_name} phone`}</h5>
                                <input type="radio" name="size" onChange={handelSettings} checked={settings.avatars_size == 0 ? true : false} value={0} /> System default
                                &nbsp;&nbsp;<input type="radio" onChange={handelSettings} checked={settings.avatars_size == 1 ? true : false} name="size" value={1} /> Zoom
                            </div>

                        </div>



                    </div>
                </div>
            </div>
        </div>
    )
}
export default Accessibility;