import { fileOpen } from "browser-fs-access";
import imageCompression from "browser-image-compression";
import { loadSettingsFromLocalStorage } from "redux/settingsSlice";

export async function csvInputFilePicker() {
  const blob = await fileOpen({
    description: "Excel file",
    mimeTypes: [
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ],
    extensions: [".xlsx"],
  });

  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);

    reader.onload = () => {
      const base64 = reader.result.split(",")[1];
      const dataURL = reader.result.split(",")[0];

      if (
        dataURL.includes(
          "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64"
        )
      ) {
        resolve(base64);
      } else {
        reject();
      }
    };

    reader.onerror = () => {
      reject();
    };
  });
}
//old without compress image picker
// export async function imgsInputFilePicker() {
//   const blobs = await fileOpen({
//     multiple: true,
//     description: "Image files",
//     mimeTypes: ["image/jpg", "image/png"],
//     extensions: [".jpg", ".jpeg", ".png"],
//   });
//   let index = 0;
//   for (let image of blobs) {
//     if (image.size > 4194304) {
//       blobs.splice(index, 1);
//     }
//     index++;
//   }
//   if (blobs.length == 0) {
//     console.log("yes coming");
//     return new Promise((resolve, reject) => {
//       reject("Photo size should be less than 5 MB");
//     });
//   }
//   const promises = blobs.map((blob) => {
//     return new Promise((resolve, reject) => {
//       const reader = new FileReader();
//       const img = document.createElement("img");

//       reader.readAsDataURL(blob);

//       reader.onload = () => {
//         img.src = reader.result;
//       };

//       img.onload = () => {
//         resolve({ photo: reader.result.split(",")[1], photoName: blob.name });
//       };

//       img.onerror = () => {
//         reject();
//       };
//     });
//   });

//   const results = await Promise.allSettled(promises);

//   return results
//     .filter((res) => res.status === "fulfilled")
//     .map((res) => res.value);

//   // return results.reduce((newResult, result) => {
//   //   if (result.status === "fulfilled") {
//   //     return [...newResult, result.value];
//   //   } else {
//   //     return newResult;
//   //   }
//   // }, []);
// }

// export async function imgInputFilePicker() {
//   let blob = await fileOpen({
//     description: "Image files",
//     mimeTypes: ["image/jpg", "image/png", "image/jpeg"],
//     extensions: [".jpg", ".jpeg", ".png"],
//   });

//   return new Promise(async (resolve, reject) => {
//     const reader = new FileReader();
//     const img = document.createElement("img");

//     await reader.readAsDataURL(blob);

//     reader.onload = () => {
//       img.src = reader.result;
//     };

//     img.onload = (ev) => {
//       console.log("ev onload:>> ", ev);
//       if (blob.size > 4194304) {
//         console.log("blob :>> ", blob);
//         console.log(blob.size);
//         blob = {};
//         reject("Photo size should be less than 5 MB");
//       } else {
//         resolve({ base64: reader.result.split(",")[1], name: blob.name });
//       }
//     };

//     img.onerror = (e) => {
//       console.log("e onerrror :>> ", e);
//       reject();
//     };
//   });

//   // const input = document.createElement("input");
//   // const img = document.createElement("img");

//   // input.type = "file";
//   // input.setAttribute("accept", ".jpg, .jpeg, .png");
//   // const reader = new FileReader();
//   // let filename = "";

//   // input.onchange = (e) => {
//   //     filename = e.target.files[0].name;
//   //     reader.readAsDataURL(e.target.files[0]);
//   // };

//   // reader.onload = () => {
//   //     img.src = `data:image/jpeg;base64,${reader.result.split(",")[1]}`
//   //     // setFormData({ ...formData, photo: reader.result.split(",")[1], photoName: filename });
//   // };

//   // img.onload = () => {
//   //     setFormData({ ...formData, photo: reader.result.split(",")[1], photoName: filename });
//   // }

//   // img.onerror = () => {
//   //     console.log("error")
//   // }

//   // input.click();
// }

export async function imgsInputFilePicker() {
  const blobs = await fileOpen({
    multiple: true,
    description: "Image files",
    mimeTypes: ["image/jpg", "image/png"],
    extensions: [".jpg", ".jpeg", ".png"],
  });
  let index = 0;
  for (let image of blobs) {
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 450,
      useWebWorker: true,
    };
    try {
      image = await imageCompression(image, options);
      console.log("image instanceof image", image instanceof Blob); // true
      console.log(`image size ${image.size / 1024 / 1024} MB`); // smaller than maxSizeMB
    } catch (error) {
      console.log(error, "errorrrrrrrrr");
    }
    blobs[index] = image;
    index++;
  }
  if (blobs.length == 0) {
    return new Promise((resolve, reject) => {
      reject("Photo size should be less than 5 MB");
    });
  }
  const promises = blobs.map((blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      const img = document.createElement("img");

      reader.readAsDataURL(blob);

      reader.onload = () => {
        img.src = reader.result;
      };

      img.onload = () => {
        resolve({
          photo: reader.result.split(",")[1],
          photoName: blob.name.replace(/[^a-zA-Z .]/g, ""),
        });
      };

      img.onerror = () => {
        reject();
      };
    });
  });

  const results = await Promise.allSettled(promises);

  return results
    .filter((res) => res.status === "fulfilled")
    .map((res) => res.value);

  // return results.reduce((newResult, result) => {
  //   if (result.status === "fulfilled") {
  //     return [...newResult, result.value];
  //   } else {
  //     return newResult;
  //   }
  // }, []);
}

export async function imgInputFilePicker() {
  let blob = await fileOpen({
    description: "Image files",
    mimeTypes: ["image/jpg", "image/png"],
    extensions: [".jpg", ".jpeg", ".png"],
  });

  const options = {
    maxSizeMB: 1,
    maxWidthOrHeight: 450,
    useWebWorker: true,
  };
  try {
    blob = await imageCompression(blob, options);
    console.log("blob instanceof Blob", blob instanceof Blob); // true
    console.log(`blob size ${blob.size / 1024 / 1024} MB`); // smaller than maxSizeMB
    return new Promise((resolve, reject) => {
      if (blob.size > 4194304) {
        console.log(blob.size);
        blob = {};
        reject("Photo size should be less than 5 MB");
      }
      const reader = new FileReader();
      const img = document.createElement("img");

      reader.readAsDataURL(blob);

      reader.onload = () => {
        img.src = reader.result;
      };

      img.onload = () => {
        resolve({
          base64: reader.result.split(",")[1],
          // name: blob.name,
          name: blob.name.replace(/[^a-zA-Z .]/g, ""),
          type: "image",
        });
      };

      img.onerror = () => {
        reject();
      };
    });
  } catch (error) {
    console.log(error, "errorrrrrrrrr");
  }

  // const input = document.createElement("input");
  // const img = document.createElement("img");

  // input.type = "file";
  // input.setAttribute("accept", ".jpg, .jpeg, .png");
  // const reader = new FileReader();
  // let filename = "";

  // input.onchange = (e) => {
  //     filename = e.target.files[0].name;
  //     reader.readAsDataURL(e.target.files[0]);
  // };

  // reader.onload = () => {
  //     img.src = `data:image/jpeg;base64,${reader.result.split(",")[1]}`
  //     // setFormData({ ...formData, photo: reader.result.split(",")[1], photoName: filename });
  // };

  // img.onload = () => {
  //     setFormData({ ...formData, photo: reader.result.split(",")[1], photoName: filename });
  // }

  // img.onerror = () => {
  //     console.log("error")
  // }

  // input.click();
}

export async function certificateInputFilePicker() {
  let blob = await fileOpen({
    description: "Pdf and Image files",
    mimeTypes: ["application/pdf", "image/jpg", "image/png", "image/jpeg"],
    extensions: [".pdf", ".jpg", ".jpeg", ".png"],
  });
  const reader = new FileReader();

  const options = {
    maxSizeMB: 1,
    maxWidthOrHeight: 450,
    useWebWorker: true,
  };
  if (
    blob.type == "image/jpeg" ||
    blob.type == "image/jpg" ||
    blob.type == "image/png"
  ) {
    blob = await imageCompression(blob, options);
    console.log("blob instanceof Blob", blob instanceof Blob); // true
    console.log(`blob size ${blob.size / 1024 / 1024} MB`); // smaller than maxSizeMB
  }

  return new Promise((resolve, reject) => {
    reader.readAsDataURL(blob);
    reader.onload = async () => {
      let ext = reader.result.split(";")[0];
      console.log("ext :>> ", ext);

      console.log(reader.result, "readerrrrrrrrrrrrr");
      if (reader.result.split(";")[0] == "data:application/pdf") {
        resolve({
          base64: reader.result.split(",")[1],
          name: blob.name,
          type: "file",
        });
      } else if (
        ext === "data:image/jpeg" ||
        ext === "data:image/jpg" ||
        ext === "data:image/png"
      ) {
        console.log("yessssss");
        resolve({
          base64: reader.result.split(",")[1],
          name: blob.name,
          type: "image",
        });
      } else {
        reject();
      }

      reader.onerror = () => {
        reject();
      };
    };
  });
}

export function covertBase64ToDataUrl(base64) {
  return `data:image/jpeg;base64,${base64}`;
}

// export const formatDateTime = (dateTimeString) => {
//   const date = new Date(dateTimeString);
//   const day = date.getDate();
//   const month = date.getMonth() + 1; // Months are zero-based
//   const year = date.getFullYear();
//   const hours = date.getHours();
//   const minutes = date.getMinutes().toString().padStart(2, '0'); // Ensure two-digit minutes

//   return `${day}-${month}-${year}, ${hours}:${minutes}`;
// };

export const formatDateTime = (dateTimeString) => {
  const date = new Date(dateTimeString);
  const day = date.getDate().toString().padStart(2, '0'); // Ensure two-digit day
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Ensure two-digit month
  const year = date.getFullYear();
  const hours = date.getHours().toString().padStart(2, '0'); // Ensure two-digit hours
  const minutes = date.getMinutes().toString().padStart(2, '0'); // Ensure two-digit minutes

  return `${year}-${month}-${day}, ${hours}:${minutes}`;
};

export const speakText = (text, selectToSpeakEnabled) => {
  console.log("selectToSpeakEnabled", selectToSpeakEnabled)
  let setting = loadSettingsFromLocalStorage();
  // var text = textRef.current.innerText;
  if (window.Android && typeof window.Android.speakText === 'function') {
    window.Android.speakText(text);
  } else {
    // console.log("setting.select_to_speak", setting.select_to_speak)
    // if (setting.select_to_speak === "true") {
    //   speak(text);
    //   console.warn('Android interface not available');
    // }
  }
};

export const speak = (text) => {
  if ('speechSynthesis' in window) {
    const speech = new SpeechSynthesisUtterance(text);

    // Specify voice options for female voice
    const voices = window.speechSynthesis.getVoices();
    speech.voice = voices.find(voice => voice.name === 'Google UK English Female');

    window.speechSynthesis.speak(speech);
  } else {
    console.error('Speech synthesis not supported in this browser.');
  }
};