import React from "react";
import useSetupData from "hooks/useSetupData";
import SetupLayout from "./SetUpLayout";
import { useDispatch, useSelector } from "react-redux";
import { handleConfirm } from "redux/setupStore";
import { useNavigate } from "react-router-dom";
import UploadProfilePhoto from "components/UploadProfilePhoto";
import { useFormik } from "formik";
import * as Yup from "yup";
import messages from "utils/messages";
export default function SelectMobile() {
  const setupData = useSetupData();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const setupStore = useSelector((state) => state.setup);

  let {
    data: { lovedOne },
  } = setupStore;

  const radio_options = [
    { name: "Get a new mobile number", value: "1" },
    { name: "Keep old mobile number", value: "2" },
  ];

  const ContactSchema = Yup.object().shape({
    is_number_new: Yup.string().required(messages.atleastOne.option),
  });

  const formik = useFormik({
    initialValues: {
      is_number_new: setupStore?.data?.lovedOne?.is_number_new || "",
    },
    validationSchema: ContactSchema,

    onSubmit: (values) => {
      handleNext(values);
    },
  });

  const handleNext = async (values) => {
    let payload = {
      data: {
        lovedOne: {
          ...setupStore.data.lovedOne,
          ...values,
        },
      },
    };
    dispatch(handleConfirm(payload));
    navigate(
      `/setup/${
        formik.values.is_number_new == "1"
          ? "SetLoNewMobile"
          : "SelectMobileType"
      }`
    );
  };
  return (
    <SetupLayout
      heading={`${lovedOne.first_name}'s Mobile Number`}
      backNav="LoLocation"
    >
      <UploadProfilePhoto handleUpload={false} value={lovedOne.loPrevUrl} />

      <div className="profilTileHeading ">{lovedOne.first_name}</div>

      <div className="contentBody fulogin">
        <div className="row fmsignup">
          <div className="col-lg-12">
            <h2 className="topTileHeading">
              Set {lovedOne.first_name}'s <br />
              Mobile Number
            </h2>

            <p>
              At BigPurplePhone we deal with all the Telco admin for you
              including phone set up.
            </p>

            <p>
              Would you like to keep {lovedOne.first_name}'s old mobile number,
              or get a new mobile number?
            </p>
          </div>

          <div className="col-lg-12">
            <div className="mb-5">
              <form className="circlebox" onSubmit={formik.handleSubmit}>
                <div className="form-group mb-0 addNextRole">
                  {radio_options.map((option, i) => (
                    <>
                      <input
                        type="radio"
                        name="is_number_new"
                        onChange={formik.handleChange}
                        className="mx-2"
                        value={option.value}
                        id={option.name}
                        checked={option.value === formik.values.is_number_new}
                      />
                      <label for={option.name}>{option.name}</label>
                      <br />
                    </>
                  ))}
                  {formik.errors["is_number_new"] &&
                  formik.touched["is_number_new"] ? (
                    <div className="error">
                      {formik.errors["is_number_new"]}
                    </div>
                  ) : null}
                </div>
                <div className="row">
                  <div className="d-flex mt-3 mb-3">
                    <button type="submit" className="btn btn-primary w-100  ">
                      Next
                    </button>
                  </div>
                  {/* <div className="col-lg-12">
                    <p className="text-center">
                      <u>I don't know</u>
                    </p>
                  </div> */}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </SetupLayout>
  );
}
