import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import AuthorizedSplash from "./AuthorizedSplash";
import NonAuthorizedSplash from "./NonAuthorizedSplash";

export default function SplashScreen() {
  const user = localStorage.getItem("profile")
    ? JSON.parse(localStorage.getItem("profile"))
    : "";
  console.log(
    "window.location.path SplashScreen:>> ",
    window.location.pathname
  );

  if (user) {
    return <AuthorizedSplash />;
  } 
  else {
    return <NonAuthorizedSplash />;
  }
}
