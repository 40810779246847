import { useEffect, useState } from "react";

import useLoginUser from "../hooks/useLoginUser";
import Autosuggest from "react-autosuggest";

export default function AutoSuggest({
  data,
  filteredList,
  findKey,
  setIsSearched = false,
}) {
  const { profile } = useLoginUser();
  const [suggestions, setSuggestions] = useState([]);
  const [value, setValue] = useState("");

  // Teach Autosuggest how to calculate suggestions for any given input value.
  const getSuggestions = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0
      ? []
      : data.filter((lang) =>
          findKey
            ? lang[findKey].toLowerCase().slice(0, inputLength) === inputValue
            : lang.first_name.toLowerCase().slice(0, inputLength) ===
                inputValue ||
              lang.last_name.toLowerCase().slice(0, inputLength) === inputValue
        );
  };

  // When suggestion is clicked, Autosuggest needs to populate the input
  // based on the clicked suggestion. Teach Autosuggest how to calculate the
  // input value for every given suggestion.
  const getSuggestionValue = (suggestion) => suggestion.first_name;

  // Use your imagination to render suggestions.
  const renderSuggestion = (suggestion) => <div>{suggestion.first_name}</div>;

  const onChange = (event, { newValue }) => {
    if (event.key == "Enter") {
      // do nothing
    } else {
      setValue(newValue);
      if (setIsSearched) {
        newValue ? setIsSearched(true) : setIsSearched(false);
      }
    }
  };

  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  const onSuggestionsFetchRequested = ({ value }) => {
    console.log("getSuggestions(value)", getSuggestions(value));
    return setSuggestions(getSuggestions(value));
  };

  //   // Autosuggest will call this function every time you need to clear suggestions.
  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };
  // Autosuggest will pass through all these props to the input.
  const inputProps = {
    placeholder: "Search...",
    value,
    onChange: onChange,
    className: "form-control",
    id: "searchicon",
  };
  useEffect(() => {
    filteredList(value ? suggestions : data);
  }, [value]);

  return (
    <div className="searchbar pb-4">
      <div className="form-outline">
        <Autosuggest
          suggestions={suggestions}
          onSuggestionsFetchRequested={onSuggestionsFetchRequested}
          onSuggestionsClearRequested={onSuggestionsClearRequested}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          inputProps={inputProps}
          highlightFirstSuggestion
        />
      </div>
    </div>
  );
}
