import login_error from "assets/images/logo_404.svg";
// import back_icon from "assets/images/back_white.svg";
import { useNavigate } from "react-router-dom";

export default function Maintenance({ serverError, resetErrorBoundary }) {
  const navigate = useNavigate();
  return (
    <div className="mainPage">
      <div className="contentboxgraynotfixedheader">
        <br />
      </div>

      <div className="contentBody contentbodymedia mtminus">
        <div className="contentBody fulogin">
          <div className="row fmsignup mt-2">
            <div className="col-lg-12 mb-5 text-center mt-5">
              <a href="#">
                <img src={login_error} />
              </a>
              <>
                <h4 style={{ margin: "35px 0" }}>
                  We are upgrading our servers at 11pm tonight. Some features
                  may not be available during this time however you can still
                  make calls and send text messages. We apologise for the
                  inconvenience. If the problem persists, please call us on{" "}
                  <a href="tel:+61286601390">(02) 8660 1390</a>
                </h4>
              </>
            </div>
          </div>

          {/* <div className="row">
            <div className="d-flex mt-3 mb-3">
              {serverError ? (
                <button
                  type="button"
                  className="btn btn-primary w-100  "
                  onClick={resetErrorBoundary}
                >
                  Try again
                </button>
              ) : (
                <button
                  type="button"
                  className="btn btn-primary w-100  "
                  onClick={() => navigate("/")}
                >
                  Back To Home
                </button>
              )}
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
}
