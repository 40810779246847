import React from "react";
// import useSetupData from "hooks/useSetupData";
import SetupLayout from "./SetUpLayout";
import { useDispatch, useSelector } from "react-redux";
import { handleConfirm } from "redux/setupStore";
import { useNavigate } from "react-router-dom";
import UploadProfilePhoto from "components/UploadProfilePhoto";
import { useFormik } from "formik";
import { PhoneComponent } from "components/InputFields";
import * as Yup from "yup";
import messages from "utils/messages";
import { inputValidation } from "utils/validations";
export default function SetLoNewMobile() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const setupStore = useSelector((state) => state.setup);

  let {
    data: { lovedOne },
  } = setupStore;

  const ContactSchema = Yup.object().shape({
    phone: Yup.string()
      .required(messages.required.phone)
      .test({
        name: "validator-custom-name",
        // eslint-disable-next-line object-shorthand
        test: function (value) {
          return inputValidation(value, this);
        },
      }),
  });
  const formik = useFormik({
    initialValues: {
      phone: setupStore?.data?.lovedOne?.phone || "",
    },
    validationSchema: ContactSchema,

    onSubmit: (values) => {
      const castValues = ContactSchema.cast(values);
      handleNext(castValues);
    },
  });

  const handleNext = async (values) => {
    let payload = {
      data: {
        lovedOne: {
          ...setupStore.data.lovedOne,
          ...values,
        },
      },
    };

    console.log("payload :>> ", payload);
    dispatch(handleConfirm(payload));
    navigate(`/setup/AllDone`);
  };

  return (
    <SetupLayout
      heading={`${lovedOne.first_name}'s Mobile Number`}
      // backNav="SelectMobile"
      backNav="LoLocation"
    >
      <UploadProfilePhoto handleUpload={false} value={lovedOne.loPrevUrl} />

      <div className="profilTileHeading ">{lovedOne.first_name}</div>

      <div className="contentBody fulogin">
        <div className="row fmsignup">
          <div className="col-lg-12">
            <h2 className="topTileHeading">
              Set {lovedOne.first_name}'s new mobile number to BigPurplePhone
            </h2>
          </div>
          <form onSubmit={formik.handleSubmit}>
            <div className="col-lg-12">
              <div className="mb-3 mt-3">
                <PhoneComponent
                  formik={formik}
                  name={"phone"}
                  label={false}
                  placeholder={"The mobile number you wish to keep"}
                  icon={"phone"}
                />
              </div>
            </div>

            <p>
              This won't take effect until the day of delivery so{" "}
              {lovedOne.first_name} still has{" "}
              {lovedOne.gender == 1 ? " his" : " her"} mobile number until then.
            </p>
            <div className="row">
              <div className="d-flex mt-3 mb-3">
                <button type="submit" className="btn btn-primary w-100  ">
                  Next
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </SetupLayout>
  );
}
