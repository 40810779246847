export const addContact = [
  // { label: "Carer", name: "ca" },
  // { label: "Family and friends", name: "fm" },
  // { label: "Contact", name: "fm" },
  // { label: "Carer", name: "ca" },
  // { label: "Health Professional", name: "hp" },
  {
    label: (
      <>
        Big<b>Purple</b>Phone User (this Contact has their own Big<b>Purple</b>
        Phone too)
      </>
    ),
    name: "lo",
  },
  {
    label:
      "Emergency Contact (they will receive an alert when SOS is activated)",
    name: "ec",
  },
];

export const editContact = [
  { label: "Carer", name: "pfa" },
  { label: "Family Admin", name: "fa" },
  { label: "Contact", name: "fm" },
  {
    label: (
      <>
        Big<b>Purple</b>Phone User
      </>
    ),
    name: "lo",
  },
  // { label: "Contact", name: "fm" },
  // {
  //   label: (
  //     <>
  //       Big<b>Purple</b>Phone User
  //     </>
  //   ),
  //   name: "lo",
  // },

  // { label: "Carer", name: "ca" },
  // { label: "Health Professional", name: "hp" },
  {
    label:
      "Emergency Contact (they will receive an alert when SOS is activated)",
    name: "ec",
  },
];

export const addContact_actual_role = ["fm", "lo"];
export const editContact_actual_role = ["pfa", "fa", "fm"];
// export const editContact_actual_role = ["ca", "fa", "fm"];
// export const designation_role = ["ec","ca", "hp"];
export const designation_role = ["ec", "ca", "hp"];
