import React, { useEffect, useState, useCallback, useRef } from "react";
import { io } from "socket.io-client";
import back_white from "../assets/images/back_white.svg";
import { NavLink, useLocation } from "react-router-dom";
import { Spinner, Modal, Button } from "react-bootstrap";
import { getLovedOneLocation, updateFindMyDevice } from "../api/api";
import { useErrorHandler } from "react-error-boundary";
import { useSelector } from "react-redux";
import { GoogleMap, useLoadScript, MarkerF, Circle } from '@react-google-maps/api';
import axios from "axios";
import { toast } from "react-toastify";
import { formatDateTime, speakText } from "utils/utils";
// import favicon from "assets/images/favicon.png"
import mapPinIcon from "assets/images/mapPinIcon.svg"
import loader from "assets/images/loader.gif"

const libraries = ['places'];
const mapContainerStyle = {
    width: '576px',
    height: '100vh',
};
const center = {
    lat: 26.7990055, // default latitude
    lng: 75.8650035, // default longitude
};

export default function LovedOneLocation() {
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: 'AIzaSyC0dqjAOARHLwsHNGrc7Bru9OgVH8-3GKQ',
        libraries,
    });
    const locationparams = useLocation();

    const queryParams = new URLSearchParams(locationparams.search);
    const lovedOneId = queryParams.get("lovedOneId");
    const lovedOne = useSelector((state) => state.lovedOne.value);
    const [address, setAddress] = useState('');

    const [socket, setSocket] = useState(null);
    const [location, setLocation] = useState(center);
    const handleError = useErrorHandler();

    // State to control the modal visibility
    const [showModal, setShowModal] = useState(false);
    const [date, setDate] = useState("");
    const notificationTimeoutRef = useRef(null);


    const resetNotificationTimeout = () => {
        setInterval(() => {
            updateFindMyDevice({ lovedOneId: lovedOneId, status: "on" });
        }, 60000);
    };

    useEffect(() => {
        const notificationData = {
            lovedOneId: lovedOneId,
            status: "on"
        }
        updateFindMyDevice(notificationData).then((res) => {
        })
        // getLovedOneLocation
        const requestBody = {
            id: lovedOneId
        }
        getLovedOneLocation(requestBody).then((res) => {
            const apiData = {
                lat: res?.data?.data?.latitude,
                lng: res?.data?.data?.longitude
            }
            const formatDate = formatDateTime(res?.data?.data?.updated_at)
            setDate(formatDate);
            setLocation(apiData)

        })

        const socket = io("https://apibigdev.24livehost.com:3002/");
        // const socket = io("https://apibiguatnew.24livehost.com:3001/")
        setSocket(socket);
        socket.on('connection', () => {
        });

        socket.on("reconnect", () => {
        });

        socket.on("reconnect_attempt", (attemptNumber) => {
        });

        socket.emit("joinRoom", { roomId: `lovedOne_${lovedOneId}` });
        socket.on("coordinatesUpdated", (data) => {
            console.log("coordinatesUpdated", data)
            // Handle the received coordinates data
            const newData = {
                lat: data.latitude,
                lng: data.longitude
            }
            const formatDate = formatDateTime(data?.updated_at)
            setDate(formatDate);
            setLocation(newData);
        });

        resetNotificationTimeout();

        // Connect to the WebSocket server when the component mounts
        socket.connect();
        return () => {
            const notificationData = {
                lovedOneId: lovedOneId,
                status: "off"
            }
            updateFindMyDevice(notificationData).then((res) => {
            })

            socket.disconnect(); // Clean up the socket connection when the component unmounts
            clearTimeout(notificationTimeoutRef.current);
        };
    }, [lovedOneId, io]);


    useEffect(() => {
        if (showModal) {
            const { lat, lng } = location
            const fetchAddress = async () => {
                try {
                    const response = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyC0dqjAOARHLwsHNGrc7Bru9OgVH8-3GKQ`);
                    if (response.data.status === 'OK') {
                        const address = response.data.results[0].formatted_address;
                        setAddress(address);
                    } else {
                        setAddress('No address found for the given coordinates.');
                    }
                } catch (error) {
                    setAddress('Error fetching address.');
                }
            };

            fetchAddress();
        }
    }, [showModal]);

    const handleCircleClick = useCallback((event) => {
        const lat = event.latLng.lat();
        const lng = event.latLng.lng();
        setShowModal(true);
        // You can add additional logic here, such as displaying an info window or updating state
    }, []);

    const CopyClipboard = useCallback(() => {
        navigator.clipboard.writeText(address).then(() => {
            toast(`Address copied to clipboard!`, { type: "success" });
        }).catch((err) => {
            console.error('Failed to copy: ', err);
        });
    })

    if (loadError) {
        return <div>Error loading maps</div>;
    }

    if (!isLoaded) {
        // return <div>Loading maps</div>;
        return (
            <div className="loader-container">
                <img src={loader} alt="Loading..." className="custom-loader" />
            </div>
        )
    }
    return (
        <>
            <div>
                <div className="mainPage">
                    <div className="Homeheaderbg text-white">
                        <div className="d-flex align-self-center">
                            <NavLink to="/MenuScreen">
                                <img src={back_white} onClick={() => speakText('Back', true)} />
                            </NavLink>

                            <h2 className="fs20 mb-0 text-center mx-auto">
                                Live Location
                            </h2>
                        </div>
                    </div>
                    <div>
                        <GoogleMap
                            mapContainerStyle={mapContainerStyle}
                            zoom={15}
                            center={location}
                        >
                            <MarkerF
                                position={location}
                                onClick={handleCircleClick}
                                icon={{
                                    url: mapPinIcon,
                                    scaledSize: new window.google.maps.Size(50, 50), // Adjust the size as needed
                                    origin: new window.google.maps.Point(0, 0),
                                    anchor: new window.google.maps.Point(20, 20) // Adjust the anchor point as needed
                                }}
                            />
                        </GoogleMap>
                    </div>
                </div>
            </div>

            <div className="modal-custom-centered">

                <Modal show={showModal} onHide={() => setShowModal(false)}>

                    <Modal.Body>
                        <div className="contentBody contentboxgray text-center positioncenter">
                            <div className="contentbodypadd">
                                <div className="col-md-12">
                                    <div className="popupbox">
                                        <div className="popcontent" style={{ "padding": "30px" }}>
                                            <h2 className="fs24" style={{ "text-align": "left" }}>Location</h2>

                                            <div className="row mt-3">
                                                <div className="col-6 mb-3">
                                                    <div style={{ "text-align": "left" }}>
                                                        <label style={{ "font-weight": "600" }}>Latitude</label>
                                                        <span style={{ "display": "block", "font-size": "16px" }} >{location?.lat}</span>
                                                    </div>
                                                </div>
                                                <div className="col-6 mb-3">
                                                    <div style={{ "text-align": "left" }}>
                                                        <label style={{ "font-weight": "600" }}>Longitude</label>
                                                        <span style={{ "display": "block", "font-size": "16px" }}>{location?.lng}</span>
                                                    </div>
                                                </div>
                                                <div className="col-12 mb-3">
                                                    <div style={{ "text-align": "left" }}>
                                                        <label style={{ "font-weight": "600", "display": "flex", "justify-content": "space-between", "align-items": "center", "margin-bottom": "10px" }}>Address

                                                            <span style={{ "cursor": "pointer" }} onClick={CopyClipboard}>
                                                                <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                                                                    width="24px" height="24px" viewBox="0 0 512.000000 512.000000"
                                                                    preserveAspectRatio="xMidYMid meet">

                                                                    <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                                                                        fill="#000000" stroke="none">
                                                                        <path d="M1163 4625 c-175 -48 -317 -196 -352 -367 -8 -37 -11 -364 -9 -1065
                                3 -1004 3 -1012 24 -1039 39 -53 71 -69 134 -69 63 0 95 16 134 69 21 27 21
                                37 26 1046 5 1009 5 1019 26 1046 11 15 33 37 48 48 27 21 38 21 886 26 848 5
                                859 5 886 26 53 39 69 71 69 134 0 63 -16 95 -69 134 -27 21 -36 21 -889 23
                                -717 2 -871 -1 -914 -12z"/>
                                                                        <path d="M1801 3984 c-169 -45 -301 -180 -346 -351 -23 -87 -22 -2700 0 -2788
                                45 -172 179 -305 352 -350 86 -22 2060 -22 2146 0 173 45 307 178 352 350 13
                                52 15 228 15 1395 0 1167 -2 1343 -15 1395 -45 172 -179 305 -352 350 -84 22
                                -2070 21 -2152 -1z m2125 -330 c15 -11 37 -33 48 -48 21 -27 21 -30 21 -1366
                                0 -1336 0 -1339 -21 -1366 -11 -15 -33 -37 -48 -48 -27 -21 -33 -21 -1046 -21
                                -1013 0 -1019 0 -1046 21 -15 11 -37 33 -48 48 -21 27 -21 32 -24 1339 -1 722
                                0 1326 3 1344 7 40 49 91 90 109 24 11 215 13 1037 11 999 -2 1007 -2 1034
                                -23z"/>
                                                                    </g>
                                                                </svg>
                                                            </span>
                                                        </label>
                                                        <span style={{ "display": "block", "font-size": "16px" }}>{address}</span>
                                                    </div>
                                                </div>

                                                <div className="col-12 mb-3">
                                                    <div style={{ "text-align": "left" }}>
                                                        <label style={{ "font-weight": "600", "display": "flex", "justify-content": "space-between", "align-items": "center" }}>Last Seen </label>
                                                        <span style={{ "display": "block", "font-size": "16px" }}>{date}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <button type="button" onClick={() => setShowModal(false)} className="btn btn-primary w-100 mt-3">OK</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div >
        </>
    );
}
