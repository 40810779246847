import { BrowserRouter as Router, Route } from "react-router-dom";
import WelcomeScreen from "../screens/SetupWalkThrough/WelcomeScreen";
import UploadProfile from "../screens/SetupWalkThrough/UploadProfile";
import ShowProfilePhoto from "../screens/SetupWalkThrough/ShowProfilePhoto";
import ContactDetails from "../screens/SetupWalkThrough/ContactDetails";
import LovedOneName from "../screens/SetupWalkThrough/LovedOneName";
import ShowLoProfilePhoto from "../screens/SetupWalkThrough/ShowLoProfilePhoto";
import UploadLoProfile from "../screens/SetupWalkThrough/UploadLoProfile";
import LoLocation from "../screens/SetupWalkThrough/LoLocation";
import AllDone from "../screens/SetupWalkThrough/AllDone";
import SelectMobile from "../screens/SetupWalkThrough/SelectMobile";
import SelectMobileType from "../screens/SetupWalkThrough/SelectMobileType";
import PortForm from "../screens/SetupWalkThrough/PortForm";
import UploadId from "../screens/SetupWalkThrough/UploadId";
import SetLoNewMobile from "screens/SetupWalkThrough/SetLoNewMobile";
import { components } from "react-select";
import WebApps from "screens/WebApps";

let setupRoutes = [
  {
    component: WelcomeScreen,
    path: "WelcomeScreen",
  },
  {
    component: UploadProfile,
    path: "UploadProfile",
  },
  {
    component: ShowProfilePhoto,
    path: "ShowProfilePhoto",
  },
  ,
  {
    component: ContactDetails,
    path: "ContactDetails",
  },
  {
    component: LovedOneName,
    path: "LovedOneName",
  },
  {
    component: UploadLoProfile,
    path: "UploadLoProfile",
  },
  {
    component: ShowLoProfilePhoto,
    path: "ShowLoProfilePhoto",
  },
  {
    component: LoLocation,
    path: "LoLocation",
  },
  {
    component: SelectMobile,
    path: "SelectMobile",
  },
  {
    component: SelectMobileType,
    path: "SelectMobileType",
  },
  {
    component: PortForm,
    path: "PortForm",
  },
  {
    component: UploadId,
    path: "UploadId",
  },
  {
    component: AllDone,
    path: "AllDone",
  },
  { component: SetLoNewMobile, path: "SetLoNewMobile" },
];

export const SetupRoutes = () => {
  return setupRoutes.map((r, i) => {
    let WalkThroughComponent = r.component;
    return (
      <Route
        path={`/setup/${r.path}`}
        element={<WalkThroughComponent />}
        key={i}
      />
    );
  });
};
