import React, { useState } from "react";
import useSetupData from "../../hooks/useSetupData";

import SetupLayout from "./SetUpLayout";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import bppLogo from "../../assets/images/loginLogo.svg";
import { handleConfirm } from "../../redux/setupStore";
import { useFormik } from "formik";
import { textField } from "components/InputFields";
import * as Yup from "yup";
import messages from "utils/messages";
export default function LovedOneName() {
  // const setupData = useSetupData();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const setupStore = useSelector((state) => state.setup);

  let given_name = `${setupStore.shopifyJson.customer_details.Name}`;
  // let gender = setupStore.shopifyJson.customer_details.Gender == "Male" ? 1 : 2;

  const LovedOneSchema = Yup.object().shape({
    // lovedOne: Yup.object().shape({
    name: Yup.string().required(messages.required.name).trim(),
    gender: Yup.string().required("Please Select Gender"),

    // .test(
    //   "is-confirm",
    //   (d) => `Name Doesn't Match`,
    //   (value) => value == null || value === given_name
    // ),
    // }),
  });

  const formik = useFormik({
    initialValues: {
      name: setupStore?.data?.lovedOne?.name || "",
      gender: setupStore?.data?.lovedOne?.gender || "",
    },
    validationSchema: LovedOneSchema,

    onSubmit: (values) => {
      console.log("values :>> ", values);
      const castValues = LovedOneSchema.cast(values);
      handleNext(castValues);
    },
  });

  const handleNext = async (values) => {
    let name = values.name;
    let gender = values.gender;
    let payload = {
      data: {
        lovedOne: {
          name,
          gender,
          first_name: name.substring(0, name.indexOf(" ")) || name,
          last_name: name.substring(0, name.indexOf(" ") + 1),
        },
      },
    };
    dispatch(handleConfirm(payload));
    navigate("/setup/UploadLoProfile");
  };

  let gender_options = [
    { label: "Male", value: "1" },
    { label: "Female", value: "2" },
    { label: "Prefer Not To Say", value: "3" },
  ];
  const getRadioBoxFields = () =>
    gender_options.map((o, i) => (
      <div key={i}>
        <input
          id={o.value}
          type="radio"
          name="gender"
          onChange={formik.handleChange}
          value={o.value}
          checked={formik.values.gender === o.value}
        />
        <label htmlFor={o.value} className="mx-2">
          {o.label}
        </label>
        <br />
      </div>
    ));
  console.log("formik.errors :>> ", formik.errors);
  return (
    <SetupLayout backNav={"ContactDetails"} heading="My Loved One">
      <div className="contentBody contentbodymedia mtminus">
        <div className="profilebox">
          <div className="userprofile userProfileLogo">
            <img src={bppLogo} className="" alt="" title="" />
          </div>
        </div>
        <div className="contentBody fulogin">
          <div className="row fmsignup">
            <div className="col-lg-12">
              <h2 className="topTileHeading">
                Great! Now let's setup your Loved One's details
              </h2>
            </div>
            <form onSubmit={formik.handleSubmit}>
              <div className="col-lg-12">
                <div className="mb-3 mt-3">
                  {textField(
                    formik,
                    "name",
                    "What does your Loved One like to be called?",
                    "Loved One Name",
                    "name"
                    // "text"
                    // { key: "lovedOne", subKey: "name" }
                  )}
                </div>

                <div className="circlebox">
                  <div className="form-group mb-3 mt-3 addNextRole">
                    {getRadioBoxFields()}
                    {formik.errors.gender && formik.touched.gender ? (
                      <div className="error">{formik.errors.gender}</div>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="d-flex mt-3 mb-3">
                  <button type="submit" className="btn btn-primary w-100  ">
                    Next
                  </button>
                </div>
              </div>{" "}
            </form>
          </div>
        </div>
      </div>
    </SetupLayout>
  );
}
