import React, { useState, useEffect } from "react";
import { useNavigate, Navigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { useQuery } from "react-query";
import { useDispatch } from "react-redux";

import splashLogo from "../assets/images/splashLogo.svg";
import bigPurplePhoneLogo from "../assets/images/bigPurpleLogo-light.svg";

import { changeLovedOne } from "../redux/lovedOne";

export default function SplashScreen() {
  const dispatch = useDispatch();
  const [isWaiting, setIsWaiting] = useState(true);
  const profile = localStorage.getItem("profile")
    ? JSON.parse(localStorage.getItem("profile"))
    : "";
  useEffect(() => {
    setTimeout(() => {
      setIsWaiting(false);
    }, 2000);
  });

  if (profile && !isWaiting) {
    if (profile.lovedOneList) {
      dispatch(changeLovedOne(profile.lovedOneList[0]));
      return <Navigate to="/MenuScreen" state={profile.lovedOneList[0]} />;
    } else {
      return <Navigate to="/LovedOneList" />;
    }
  }

  return (
    <div className="mainPage purBg">
      <div className="logo spleshBlog">
        <a href="#">
          <img src={splashLogo} style={{ width: "230px" }} />
        </a>
      </div>
    </div>
  );
}
