import React, { useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import back_white from "assets/images/back_white.svg";
import fullimgupload from "assets/images/fullimgupload.svg";
import useSelectedLovedOne from "hooks/useSelectedLovedOne";
import LovedOnePhoneName from "components/LovedPhoneName";
import left_arrow from "assets/images/left_arrow.svg";
import right_arrow from "assets/images/right_arrow.svg";
import { addPhotos } from "api/api";
import { Spinner, Button } from "react-bootstrap";
import { useErrorHandler } from "react-error-boundary";
import useLoginUser from "hooks/useLoginUser";
import { useSelector } from "react-redux";
import { speakText } from "utils/utils";
export default function PFAUploadImage() {
  const navigate = useNavigate();
  const location = useLocation();
  const { profile } = useLoginUser();
  const loved_one = useSelectedLovedOne();

  console.log("loved_one in gallery", loved_one)
  const [form, setForm] = useState({
    imgs: Array.from(location.state.photos),
    comment: "",
    preview: location.state.previewUrls,
  });

  const shouldHideBackButton = profile.lovedOneList.length > 0 && (
    profile.lovedOneList[0].relation === 'Self' || profile.lovedOneList[0].lo_id
  );
  console.log("profile", profile)
  console.log("shouldHideBackButton", shouldHideBackButton)
  const [currentIndex, setCurrentIndex] = useState(0);
  const [errors, setError] = useState({});
  const [isSubmitting, setSubmitting] = useState(false);
  const handleError = useErrorHandler();
  console.log("location :>> ", location);
  console.log("loved_one.id", loved_one)
  const settings = useSelector((state) => state.settings);

  const handleAddImages = async () => {
    let data = {
      comment: form.comment,
      role: shouldHideBackButton ? "lo" : "users",
      lovedOneId: loved_one.id,
      documents: form.imgs,
    };

    speakText("Add", true)
    // check if any field has error
    let isValidate = Object.values(errors).every((item) => item == false);
    let user_id;
    if (isValidate) {
      setSubmitting(true);
      if (profile.lovedOneList.length > 0) {
        user_id = profile.lovedOneList[0].id
      }
      // else {
      //   user_id = profile.id;
      // }

      let data = new FormData();
      data.append("comment", form.comment);
      data.append("role", shouldHideBackButton ? "lo" : "users");
      data.append("lovedOneId", loved_one.id);
      data.append("user_id", user_id)
      form.imgs.map((f, i) => data.append("photos", f));
      addPhotos(data)
        .then((res) => {
          setTimeout(() => {
            setSubmitting(false);
            navigate("/PFAGallaryImages");
          }, 3000);
        })
        .catch((error) => {
          return error.response.data.statusCode != 400
            ? handleError(error)
            : "";
        });
    }
  };

  const handlePrev = (e) => {
    e.preventDefault();
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };
  const handleNext = (e) => {
    e.preventDefault();

    if (form.imgs.length - 1 > currentIndex) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const handleChangeComment = (e) => {
    let comment = e.target.value;
    setForm((prev) => ({
      ...prev,
      comment,
    }));
    if (comment.length > 50) {
      setError({
        comment: true,
      });
    } else {
      setError({
        comment: false,
      });
    }
  };

  return (
    <div className="pt80">
      <div className="mainPage">
        <div className="Homeheaderbg text-white headerfixed">
          <div className="d-flex align-self-center">
            <NavLink to="/PFAGallaryImages">
              <img src={back_white} alt="" title="" />
            </NavLink>
            <h2 className="fs20 mb-0 text-center mx-auto">Add Photos</h2>
          </div>
        </div>

        <LovedOnePhoneName loved_one={loved_one} />
        <div className="contentBody contentbodymedia contentboxgray pt-4">
          <div className="contentBody">
            <div className="row">
              <div className="col-12">
                <div className="fullimg">
                  <img
                    src={
                      form.preview[currentIndex]
                        ? form.preview[currentIndex]
                        : fullimgupload
                    }
                    className="bigimgview"
                  />
                  {form.imgs && form.imgs.length > 1 ? (
                    <>
                      {" "}
                      {currentIndex > 0 ? (
                        <a href="#">
                          {" "}
                          <img
                            src={left_arrow}
                            className="leftSlideArrow"
                            onClick={handlePrev}
                          />
                        </a>
                      ) : (
                        " "
                      )}
                      {currentIndex + 1 == form.imgs.length ? (
                        ""
                      ) : (
                        <a href="#">
                          <img
                            src={right_arrow}
                            className="rightSlideArrow"
                            onClick={handleNext}
                          />
                        </a>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                </div>

                <div className="mb-3">
                  <div className="inputIcon">
                    <h5>Add Comment</h5>
                    <textarea
                      id="msz"
                      name="msz"
                      rows="4"
                      width="100%"
                      placeholder="Enter Comment..."
                      maxLength="50"
                      value={form.comment}
                      onChange={handleChangeComment}
                    />
                    {errors.comment ? (
                      <span className="error">
                        Character limit is upto 50 Characters
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>

                <div className="relativeLoader">
                  {isSubmitting ? (
                    <div className="loaderover">
                      <div className="flex_loaderbox">
                        <Spinner animation="border" variant="primary" />
                        <span>
                          Sharing your photos with {loved_one.first_name}
                        </span>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  <div className="d-flex">
                    <button
                      type="button"
                      className="btn btn-primary w-100 me-2 btnwhite textred"
                      onClick={() => navigate("/PFAGallaryImages")}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary w-100 ms-2"
                      onClick={handleAddImages}
                      disabled={isSubmitting}
                    >
                      {/* {isSubmitting ? (
                        <Spinner animation="border" variant="primary" />
                      ) : (
                        "Add"
                      )} */}
                      Add
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
