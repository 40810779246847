import React from "react";
import setupWTFooterLogo from "assets/images/btmLogo.svg";
import backIcon from "assets/images/back_white.svg";
import { Navigate, NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setStatus } from "redux/setupStore";

export default function SetupLayout({
  children,
  backNav,
  heading = "My Profile",
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <div className="mainPage">
      <div className="Homeheaderbg text-white" style={{ minHeight: "250px" }}>
        <div className="d-flex align-self-center">
          {backNav ? (
            <span>
              {" "}
              <img
                src={backIcon}
                onClick={() => {
                  let payload = {
                    status: false,
                  };
                  dispatch(setStatus(payload));
                  navigate(`/setup/${backNav}`);
                }}
              />
            </span>
          ) : (
            ""
          )}

          <h2 className="fs20 mb-0 text-center mx-auto">{heading}</h2>
        </div>
      </div>

      <div className="contentboxgraynotfixedheader">
        <br />
      </div>

      <div className="contentBody contentbodymedia mtminus">
        {children}

        <div className="bigpurplebottom text-center mb-3 mt-3">
          <a href="#">
            <img src={setupWTFooterLogo} />
          </a>
        </div>
      </div>
    </div>
  );
}
