import React, { useState } from "react";
import { useLocation, NavLink, useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import back_white from "../assets/images/back_white.svg";
import user from "../assets/images/user.svg";
import deleteConfirmIcon from "../assets/images/delete_confrm.svg";
import { editLovedOneDetails, logout, removeBigPurplePhone } from "../api/api";
import { useDispatch } from "react-redux";

import { updateLovedOnePhoto } from "../redux/lovedOne";
import UploadProfilePhoto from "components/UploadProfilePhoto";
import { useErrorHandler } from "react-error-boundary";
import { deleteRole } from "utils/localStorage";
import useLoginUser from "hooks/useLoginUser";
import { speakText } from "utils/utils";
import useSelectedLovedOne from "hooks/useSelectedLovedOne";

export default function LOEditProfile() {
  const location = useLocation();
  const navigate = useNavigate();
  const { profile } = useLoginUser();
  const lovedOne = useSelectedLovedOne();


  const [show, setShow] = useState(false);
  const [error, setError] = useState({ msg: "", isError: "" });

  const dispatch = useDispatch();
  console.log("location :>> ", location);
  const [formData, setFormData] = useState({
    name: `${location.state.first_name} ${location.state.last_name}`,
    photo: location.state.photo,
    photoName: "",
    id: location.state.id,
  });
  const handleClose = () => {
    speakText("close", true)
    setShow(false);
  }
  const handleError = useErrorHandler();
  const handleUpload = (photo) => {
    setFormData({
      ...formData,
      photo,
    });
  };
  const handleRemove = () => {
    speakText("Remove", true);
    setShow(true);
  };

  const handleLogout = async () => {
    speakText("Logout", true);
    let data = {
      role: "pfa",
    };
    await logout(data)
      .then((res) => {
        deleteRole();
        localStorage.removeItem("profile");
        localStorage.removeItem("api_token");
        sessionStorage.removeItem("lovedOne");
        localStorage.removeItem("callingUrl");
        localStorage.removeItem("auto_login_id");
        // navigate("/LoginScreen");
        window.location.href = "/";
      })
      .catch((error) => {
        return error.response.data.statusCode != 400 ? handleError(error) : "";
      });
  };

  const onClickManageDevice = () => {
    speakText("Update", true);
    const err = {
      msg: "",
      isError: false,
    };

    if (formData.name.length === 0) {
      err.msg = "Enter name";
      err.isError = true;
    }

    if (err.isError) {
      setError(err);
    } else {
      setError(err);

      let data = new FormData();
      data.append("photo", formData.photo);
      data.append("name", formData.name);
      data.append("lovedOneId", formData.id);
      data.append("prevUrl", location.state.photo);
      editLovedOneDetails(data)
        .then((res) => {
          let name_string = formData.name.split(" ");
          console.log("name_string :>> ", name_string, location.state.backpage);
          let data = {
            ...location.state,
            first_name: name_string[0],
            last_name: name_string[1] ? name_string[1] : "",
            id: formData.id,
          };
          console.log("data in lo profile ->>>>>", data)
          if (res.data.data.photo) {
            data.photo = res.data.data.photo;
          }


          setTimeout(() => {
            dispatch(updateLovedOnePhoto(data));
            navigate(location.state.backpage);
          }, 1500);
        })
        .catch((error) => {
          console.log(error);
          return error.response.data.statusCode != 400
            ? handleError(error)
            : "";
        });
    }
  };

  const onClickDelete = () => {
    speakText("Remove", true)
    removeBigPurplePhone({ lovedOneId: formData.id })
      .then(() => {
        navigate("/LovedOneList");
      })
      .catch((error) => {
        console.log("error");
        throw error;
      });
  };
  console.log("profile", profile)
  console.log("lovedOne", lovedOne)
  return (
    <div className="mainPage">
      <div className="Homeheaderbg text-white" style={{ minHeight: "250px" }}>
        <div className="d-flex align-self-center">
          <NavLink to={location.state.backpage}>
            <img src={back_white} alt="" title="" onClick={() => speakText('Back', true)} />
          </NavLink>
          <h2 className="fs20 mb-0 text-center mx-auto  raleway_font">
            Edit Loved One's Details
          </h2>
        </div>
      </div>

      <div className="contentboxgraynotfixedheader">
        <br />
      </div>

      <div className="contentBody contentbodymedia mtminus">
        <UploadProfilePhoto
          prevUrl={location.state.photo}
          handleUpload={handleUpload}
          showIcon={profile.lo_id ? false : true}
          profile={profile}
        />

        <div className="contentBody">
          <div className="row">
            <div className="col-lg-12">
              <div className="mb-3">
                <label htmlFor="" className="form-label">
                  User Name <span className="error">*</span>
                </label>
                <div className="inputIcon">
                  <input
                    onChange={(e) =>
                      setFormData({ ...formData, name: e.target.value.trim() })
                    }
                    type="text"
                    className="form-control iconInputLeft"
                    defaultValue={formData.name}
                  />
                  <span className="icons">
                    <img src={user} alt="" title="" />
                  </span>
                </div>
                <span style={{ color: "red" }}>{error.msg}</span>
              </div>
            </div>
          </div>
          <div className="row">
            <small className="fs12">
              <span className="pe-2">
                <b>*=Required fields</b>
              </span>
            </small>
          </div>
          <div className="row">
            <div className="d-flex mt-3 mb-4">
              {
                profile.lo_id ? (
                  <button
                    onClick={handleLogout}
                    type="button"
                    className="btn btn-primary w-100 ms-2 btnwhite textred border-red"
                  >
                    Logout
                  </button>
                )
                  : (
                    // <button
                    //   onClick={handleRemove}
                    //   type="button"
                    //   className="btn btn-primary w-100 ms-2 btnwhite textred border-red"
                    // >
                    //   Remove
                    // </button>
                    ""
                  )

              }


              <div style={{ padding: "0.5rem" }}></div>
              <button
                onClick={onClickManageDevice}
                type="button"
                className="btn btn-primary w-100 m2-2 "
              >
                Update
              </button>
            </div>
          </div>

          <Modal centered show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title style={{ width: "100%" }}>
                <h3 className="text-center" style={{ textAlign: "center" }}>
                  Confirmation
                </h3>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p className="mb-3 text-center">
                <img src={deleteConfirmIcon} alt="delete confirmation" />
              </p>

              <p className="text-center">
                Are you sure you want to remove this loved one ?
              </p>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleClose}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-danger"
                onClick={onClickDelete}
              >
                Remove
              </button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </div>
  );
}
