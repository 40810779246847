import { Provider, useSelector } from "react-redux";

import AddNewContact from "screens/AddNewContact";
import ImportContacts from "screens/Add_ImportContacts";

import ChangePassword from "screens/ChangePassword";
import ContactEdit from "screens/ContactEdit";
import ContactUploadSucc from "screens/ContactUploadSucc";
import LOEditProfile from "screens/LOEditProfile";
import LovedOneList from "screens/LovedOneList";
import MenuScreen from "screens/MenuScreen";
import PFABillingDetails from "screens/PFABillingDetails";
import LovedOneLocation from "screens/LovedOneLocation";
import PFAGallaryImages from "screens/PFAphotosGallary/PFAGallaryImages";
import PFAUploadImage from "screens/PFAphotosGallary/PFAUploadImage";
import PFAProfile from "screens/PFAProfile";
import PFASetting from "screens/PFASetting";
import AddContactSuccessfull from "screens/AddContactSuccessfull";
import SearchContact from "screens/SearchContact";
import PFASingleImage from "screens/PFAphotosGallary/PFASingleImage";
import PFAEditProfile from "screens/PFAEditProfile";
import VaccineCertificate from "screens/VaccineCertificate";

import PhoneMenuScreen from "screens/PhoneMenuScreen";
import Radio from "screens/Radio";
import Music from "screens/Music";
import AddCovidCertificate from "screens/AddCovidCertificate";
import PFAManageReminder from "screens/PFAManageReminder";
import PFAManageBirthday from "screens/PFAManageBirthday";
import AddReminder from "screens/AddReminder";
import AddBirthday from "screens/AddBirthday";
import SharePhotos from "screens/PFAphotosGallary/SharePhotos";
import PFAPhotoSelection from "screens/PFAphotosGallary/PFAPhotoSelection";
import PFAVideoCall from "screens/PFAVideoCall";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "components/Error";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import VideoContacts from "screens/VideoContacts";
import Accessibility from "screens/Accessibility";
import WebUrl from "screens/WebUrl";
import PinChange from "screens/PinChange";
import MenuScreenSecond from "screens/MenuScreenSecond";
import WebApps from "screens/WebApps";
import ProtectedRoute from "screens/ProtectedRoutes";
import { components } from "react-select";
import socketTest from "screens/socketTest";
import LoLogout from "screens/LoLogout";
let authRoutes = [
  {
    component: ChangePassword,
    path: "ChangePassword",
  },
  {
    component: LovedOneList,
    path: "LovedOneList",
  },
  {
    component: MenuScreen,
    path: "MenuScreen",
  },
  {
    component: MenuScreenSecond,
    path: "MenuScreenSecond",
  },
  { component: PhoneMenuScreen, path: "PhoneMenuScreen" },

  {
    component: ImportContacts,
    path: "Add_ImportContacts",
  },
  {
    component: SearchContact,
    path: "SearchContact",
  },
  {
    component: ContactEdit,
    path: "ContactEdit",
  },
  {
    component: PFASetting,
    path: "PFASetting",
  },
  {
    component: LoLogout,
    path:"LoLogout",
  },
  {
    component: PFAProfile,
    path: "PFAProfile",
  },
  {
    component: PFABillingDetails,
    path: "PFABillingDetails",
  },
  {
    component: LovedOneLocation,
    path: "LovedOneLocation"
  },
  {
    component: socketTest,
    path: "socketTest"
  },
  {
    component: AddContactSuccessfull,
    path: "AddContactSuccessfull",
  },
  {
    component: ContactUploadSucc,
    path: "ContactUploadSucc",
  },
  {
    component: AddNewContact,
    path: "AddNewContact",
  },
  { component: WebApps, path: "WebApps" },

  { component: LOEditProfile, path: "LOEditProfile" },
  { component: PFAUploadImage, path: "PFAUploadImage" },
  { component: WebUrl, path: "weburl" },
  { component: PinChange, path: "PinChange" },
  { component: PFAGallaryImages, path: "PFAGallaryImages" },
  { component: PFASingleImage, path: "PFAGallaryImage/:id" },
  { component: PFAEditProfile, path: "PFAEditProfile" },
  { component: VaccineCertificate, path: "VaccineCertificate" },
  { component: Accessibility, path: "Accessibility" },
  { component: Radio, path: "Radio" },
  { component: Music, path: "Music" },
  { component: AddCovidCertificate, path: "AddCovidCertificate" },
  { component: PFAManageReminder, path: "PFAManageReminder" },
  { component: PFAManageBirthday, path: "PFAManageBirthday" },
  { component: AddReminder, path: "Reminder" },
  { component: AddBirthday, path: "Birthday" },
  { component: SharePhotos, path: "SharePhotos" },
  { component: PFAPhotoSelection, path: "PFAPhotoSelection" },
  { component: VideoContacts, path: "VideoContacts" },

  { component: PFAVideoCall, path: "PFAVideoCall" },
  // { component: PFAVideoCall, path: "PFAVideoCall/:role/:caller_id/:room_id" },
];

const Authorized = ({ children }) => {
  const user = useSelector((state) => state.user);
  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
    >
      {user.isAuthorized ? children : <Navigate to="/" />}
    </ErrorBoundary>
  );
};

export default function AuthRoutes() {
  return authRoutes.map((r, i) => {
    let AuthComponent = r.component;
    return (
      <Route
        path={`/${r.path}`}
        element={
          <Authorized>
            <AuthComponent />
          </Authorized>
        }
        key={i}
      />
    );
  });
}


// export default function AuthRoutes() {
//   return authRoutes.map((r, i) => {
//     let AuthComponent = r.component;

//     if (r.path === "LovedOneList") {
//       // Apply the ProtectedRoute HOC to the LovedOneList route
//       return (
//         <Route
//           path={`/${r.path}`}
//           element={
//             <ProtectedRoute>
//               <Authorized>
//                 <AuthComponent />
//               </Authorized>
//             </ProtectedRoute>
//           }
//           key={i}
//         />
//       );
//     }

//     // For other routes, apply the Authorized HOC only
//     return (
//       <Route
//         path={`/${r.path}`}
//         element={
//           <Authorized>
//             <AuthComponent />
//           </Authorized>
//         }
//         key={i}
//       />
//     );
//   });
// }