import { GET, PUT } from "./request";
import { wrapper } from "utils/helpers";

export const editThirdPartyList = async (data) => {
  const fn = async () => {
    const response = await PUT("/thirdParty/loItemActive", { data });
    return response;
  };
  return wrapper(fn);
};

export const editAllThirdPartyList = async (data, countryCode) => {
  const fn = async () => {
    console.log("Data line", data)
    const response = await PUT("/thirdParty/loAllItemActive", { data });
    return response;
  };
  return wrapper(fn);
};

export const getThirdPartyList = async (lovedOneId, category_id, countryCode) => {
  let params = {
    id: lovedOneId,
    category_id: category_id,
    role: "pfa",
    country: countryCode
  };
  const fn = async () => {
    const response = await GET("/thirdParty/getThirdPartyList", {
      params,
    });
    return response;
  };

  return wrapper(fn);
};
