const RoleInfo = () => {
  return (
    <>
      <div className="mainPage">
        <div className="contentBody contentbodymedia manageapps">
          <div className="contentBody whichRoleBox">
            <h4>Which role am I?</h4>

            <div className="whichRoleBox mt-3">
              <h5>Loved One </h5>
              <p>The person for whom you bought a BigPurplePhone</p>
            </div>

            <div className="whichRoleBox  mt-3">
              <h5>Family & Friends </h5>
              <p>
                These are people you want to add to your Loved One's phone so they
                can phone, message, video call and share images with your
                Loved One,{" "}
              </p>
            </div>

            <div className="whichRoleBox  mt-3">
              <h5>Carer</h5>
              <p>
                This is person who is employed to care for your Loved One on a day
                to day basis.
              </p>
            </div>

            <div className="whichRoleBox  mt-3">
              <h5>Health Professional </h5>
              <p>This may be a doctor, physio or other medical professional.</p>
            </div>

            <div className="whichRoleBox  mt-3">
              <h5> Family Administrators</h5>
              <p>
                You can set up 4 other people to help you with administering
                your Loved One's phone. They can do all that you can do with the
                exception that you handle the billing for your Loved One's phone.{" "}
              </p>
            </div>

            <div className="whichRoleBox  mt-3">
              <h5>Primary Family Administrator </h5>
              <p>
                This is you because you purchased the BigPurplePhone on behalf
                of your Loved One. You can delegate this role another person if you
                choose and the other person agrees. There is only one of these
                per Loved One.
              </p>
            </div>

            <div className="whichRoleBox  mt-3">
              <h5> Emergency Contact </h5>
              <p>
                {" "}
                If your Loved One hits the Emergency button Emergency contacts are
                sent an alert to advise them that 000 has been dialled and their
                location.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RoleInfo;
