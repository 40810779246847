import React, { useState } from "react";
import useSetupData from "hooks/useSetupData";
import SetupLayout from "./SetUpLayout";
import { useDispatch, useSelector } from "react-redux";
import { handleConfirm } from "redux/setupStore";
import { useNavigate } from "react-router-dom";
import UploadProfilePhoto from "components/UploadProfilePhoto";
import infoIcon from "assets/images/info_icons.svg";
import { useFormik } from "formik";
import { textField, selectField, PhoneComponent } from "components/InputFields";
import CustomPasswordInput from "components/CustomPasswordInput";

import * as Yup from "yup";
import messages from "utils/messages";
import CustomModal from "components/Modal/modal";
import RoleInfo from "./RoleInfo";
import { inputValidation } from "utils/validations";
import { passwordRegex } from "utils/regex";
import useLoginUser from "hooks/useLoginUser";
import useSelectedLovedOne from "hooks/useSelectedLovedOne";
// import { isUserExist } from "api/auth";

export default function ContactDetails() {
  const setupData = useSetupData();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { profile } = useLoginUser();
  const setupStore = useSelector((state) => state.setup);
  const [isOpenModal, setOpenModal] = useState(false);
  const lovedOne = useSelectedLovedOne();
  const relation_options = [
    { name: "Your Partner", value: "Partner" },
    { name: "Your Mother", value: "Mother" },
    { name: "Your Father", value: "Father" },
    { name: "Your Mother In Law", value: "Mother-In-Law" },
    { name: "Your Father In Law", value: "Father-In-Law" },
    { name: "Your Grandmother", value: "Grandmother" },
    { name: "Your Grandfather", value: "Grandfather" },
    { name: "Your Sister", value: "Sister" },
    { name: "Your Brother", value: "Brother" },
    { name: "Your Friend", value: "Friend" },
    { name: "Your Client", value: "Client" },
    { name: "Other (Please Specify)", value: "Other" },
  ];

  const radio_options = [
    { name: "Loved One (1)", value: "lo", disabled: true },
    { name: "Family & Friends", value: "fm", disabled: true },
    { name: "Carer", value: "ca" },
    { name: "Health Professional", value: "hp" },
    { name: "Family Administrator (up to 4)", value: "fa", disabled: true },
    {
      name: "Primary Family Administrator(1 - you)",
      value: "pfa",
      checked: true,
      disabled: true,
    },

    { name: "Emergency Contact", value: "ec" },
  ];

  const {
    customer: { first_name, last_name, email },
  } = setupData;
  // let phone_property =
  //   setupStore.shopifyJson.customer_details.YourContactNumber;
  // console.log("phone_property :>> ", phone_property);
  console.log("email :>> ", email);
  let given_name = `${first_name} ${last_name}`;
  // let given_contact = phone_property.slice(-9);
  // console.log("given_contact :>> ", given_contact);
  const ContactSchema = Yup.object().shape({
    // name: Yup.string()
    //   .required(messages.required.name)
    //   .trim()
    //   .test(
    //     "is-confirm",
    //     (d) => `Name Doesn't Match`,
    //     (value) =>
    //       value == null || value.toLowerCase() === given_name.toLowerCase()
    //   ),
    // email: Yup.string()
    //   .email(messages.email)
    //   .required(messages.required.email)
    //   .test(
    //     "is-confirm",
    //     (d) => `Email Doesn't Match`,
    //     (value) => value == null || value.toLowerCase() === email.toLowerCase()
    //   ),
    password: Yup.string()
      .required(messages.required.password)
      .matches(passwordRegex, messages.invalidPassword),

    confirmPassword: Yup.string()
      .required(messages.required.confirmPassword)
      .oneOf([Yup.ref("password"), null], "Passwords must match"),
    relation: Yup.string().required(messages.required.relation),
    // .test(
    //   "is-confirm",
    //   (d) => "Relation doesn't match",
    //   (value) => {
    //     console.log("value :>> ", value);

    //     return (
    //       value == null ||
    //       value === `${setupStore.shopifyJson.customer_details.relation}`
    //     );
    //   }
    // ),
    // role: Yup.string().required(messages.required.name),
    other: Yup.string().when("relation", {
      is: "Other",
      then: Yup.string().required(messages.required.relationInput).trim(),
    }),
    role: Yup.array()
      .min(1, messages.atleastOne.role)
      .of(Yup.string().required(messages.required.role))
      .required(messages.required.role),

    phone: Yup.string()
      .required(messages.required.phone)
      .test({
        name: "validator-custom-name",
        // eslint-disable-next-line object-shorthand
        test: function (value) {
          return inputValidation(value, this);
        },
      }),
    // .test(
    //   "isExist",
    //   "This mobile is already registered.",
    //   (value, { createError }) =>
    //     value
    //       ? isUserExist({ email, mobile: value }).then((res) => {
    //           console.log("res.data isExist :>> ", res.data.data.status);
    //           if (!res.data.data.status) {
    //             console.log("this :>> ", this);
    //             return createError({ message: res.data.data.message });
    //           } else {
    //             return true;
    //           }
    //         })
    //       : true
    // ),
  });

  let data_relation = setupStore.data.relation;
  let initial_relation = data_relation
    ? relation_options.findIndex((r) => r.value == data_relation) !== -1
      ? data_relation
      : "Other"
    : "";

  const formik = useFormik({
    initialValues: {
      email: setupStore?.data?.email || email,
      password: setupStore?.data?.password || "",
      confirmPassword: setupStore?.data?.confirmPassword || "",
      phone: setupStore?.data?.phone || "",
      role: setupStore?.data?.role || ["pfa", "ec"],
      relation: initial_relation,
      other: initial_relation == "Other" ? data_relation : "",
    },
    validationSchema: ContactSchema,

    onSubmit: (values) => {
      const castValues = ContactSchema.cast(values);
      handleSave(castValues);
    },
  });

  const handleSave = (values) => {
    if (values.relation == "Other") {
      values.relation = values.other;
      // delete values.other;
    }
    let payload = {
      data: { ...values },
    };
    dispatch(handleConfirm(payload));
    navigate("/setup/LovedOneName");
  };

  const handleModal = (status) => () => {
    console.log("status :>> ", status);
    setOpenModal(status);
  };
  return (
    <SetupLayout backNav={"ShowProfilePhoto"}>
      <div className="">
        <UploadProfilePhoto
          handleUpload={false}
          value={setupStore?.data?.prevUrl}
          profile={lovedOne}
          // profile={profile.lovedOneList[0]}
        />
        <div className="fulogin">
          <div className="row fmsignup">
            <div className="col-lg-12">
              <h2 className="topTileHeading">
                Next we need to
                <br />
                confirm your <br />
                contact details
              </h2>
              {/* <p>Now, please confirm your contact details.</p> */}
              <p>
                Importantly, when your Loved One presses their emergency button you
                will receive an alert at the mobile number you provide below.
              </p>
            </div>
            <form onSubmit={formik.handleSubmit}>
              {/* <div className="col-lg-12">
                <div className="mb-3 mt-3">
                  {textField(
                    formik,
                    "name",
                    false,
                    "Name",
                    "name",
                    "text",
                    false,
                    true
                  )}
                </div>
              </div> */}
              {/* <div className="col-lg-12">
                <div className="mb-3 mt-3">
                  {textField(
                    formik,
                    "email",
                    false,
                    "Email Address",
                    "email",
                    "email",
                    false,
                    true
                  )}
                </div>
              </div> */}
              <div className="col-lg-12">
                <div className="mb-3 mt-3">
                  <PhoneComponent
                    formik={formik}
                    name={"phone"}
                    label={false}
                    placeholder={"Mobile"}
                    icon={"phone"}
                  />
                </div>
              </div>
              <div className="col-lg-12">
                <div className="mb-3 mt-3">
                  <CustomPasswordInput
                    formik={formik}
                    name="password"
                    // label="Password"
                    placeholder="Password"
                  />
                </div>
              </div>
              <div className="col-lg-12">
                <div className="mb-3 mt-3">
                  <CustomPasswordInput
                    formik={formik}
                    name="confirmPassword"
                    // label="Confirm Password"
                    placeholder="Confirm Password"
                  />
                </div>
              </div>
              <div className="col-lg-12">
                <div className="mb-3 mt-3 leftArrowSelect">
                  {selectField(
                    formik,
                    "relation",
                    false,
                    "Your Loved One's relationship to you",
                    relation_options,
                    "downArrow"
                  )}
                </div>
              </div>
              {formik.values.relation == "Other" ? (
                <div className="col-lg-12">
                  <div className="mb-3 mt-3">
                    {textField(
                      formik,
                      "other",
                      false,
                      "Other (please specify your....?)s",
                      false
                    )}
                  </div>
                </div>
              ) : (
                ""
              )}
              <div className="col-lg-12">
                <div className="mb-4">
                  <h5 className="pt-3 pb-2 fsmed">
                    Add a tick next to your role:{" "}
                    <a href="#">
                      <i>
                        <img
                          src={infoIcon}
                          alt=""
                          title=""
                          width="18"
                          onClick={handleModal(true)}
                        />
                      </i>
                    </a>
                  </h5>
                  <div className="circlebox">
                    <div className="form-group mb-0 addNextRole">
                      {radio_options.map((option, i) => (
                        <div
                          className={`${
                            option.disabled && !option.checked
                              ? "disable-role"
                              : ""
                          } ${
                            formik.values.role.includes("ec") &&
                            option.value == "ec"
                              ? "emgRadio"
                              : ""
                          }`}
                        >
                          <input
                            type="checkbox"
                            name="role"
                            onChange={formik.handleChange}
                            className="mx-2"
                            value={option.value}
                            id={option.name}
                            disabled={option.disabled}
                            // checked={option.value === formik.values.role}
                            checked={
                              formik.values.role.includes(option.value) ||
                              option.checked
                            }
                          />
                          <label for={option.name}>{option.name}</label>
                        </div>
                      ))}
                      {formik.errors["role"] && formik.touched["role"] ? (
                        <div className="error">{formik.errors["role"]}</div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="d-flex mt-3 mb-3">
                  <button type="submit" className="btn btn-primary w-100  ">
                    Save
                  </button>
                </div>
              </div>{" "}
            </form>
          </div>
        </div>
      </div>
      <CustomModal
        toggleModal={handleModal}
        isOpen={isOpenModal}
        title={false}
        description={<RoleInfo />}
        prompt={false}
      />
    </SetupLayout>
  );
}
