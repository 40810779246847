import { useState, useEffect } from "react";
import { useNavigate, NavLink } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import back_white from "assets/images/back_white.svg";
import user_small from "assets/images/user_small.svg";
import noContactImage from "assets/images/no-contact1.svg";
import bigPurplePhoneLogo from "assets/images/bigPurpleLogo-dark.svg";

import { getVerifiedContactList } from "api/api";
import useSelectedLovedOne from "hooks/useSelectedLovedOne";
import AutoSuggest from "components/AutoSuggest";
import useLoginUser from "hooks/useLoginUser";
import { useErrorHandler } from "react-error-boundary";
import { speakText } from "utils/utils";
import { useSelector } from "react-redux";
import loader from "assets/images/loader.gif"
export default function VideoContacts() {
  const navigate = useNavigate();
  const lovedOne = useSelectedLovedOne();
  const [isLoading, setLoading] = useState(false);
  const [showList, setShowList] = useState("");
  const [list, setList] = useState([]);
  const user = useLoginUser();
  const handleError = useErrorHandler();
  const settings = useSelector((state) => state.settings);
  useEffect(() => {
    setLoading(true);
    if (lovedOne && lovedOne.id) {
      let params = { lovedOneId: lovedOne?.id };
      getVerifiedContactList(params)
        .then((data) => {
          setLoading(false);

          let lo = { ...lovedOne };
          lo.role = "loved_one";
          lo.name = lovedOne.first_name;
          data.unshift(lo);

          let contacts = data.filter(
            (ct) => !(ct.id == user.profile.id && ct.role == "users")
          );
          contacts = contacts.map((d) => {
            return { ...d, checked: false, ring: true };
          });
          setList(contacts);
          setShowList(contacts);
        })
        .catch((error) => {
          console.log("error :>> ", error);
          return error.response.data.statusCode != 400
            ? handleError(error)
            : "";
        });
    }
  }, [lovedOne && lovedOne.id]);

  const filteredList = (updated_list) => {
    setShowList(updated_list);
  };

  const onClickContact = (item, index, checked) => {
    console.log("item :>> ", item, checked);
    if (checked) {

      speakText(item.name, true)
    }
    let updated_list = [...showList];
    updated_list[index].checked = checked;
    setShowList([...updated_list]);

    let updated_full_list = [...list];
    let list_index = updated_full_list.findIndex(
      (list) => list.id == item.id && list.role == item.role
    );
    updated_full_list[list_index].checked = checked;
    setList([...updated_full_list]);
  };

  const handleStartVideoCall = () => {
    let selectedUsers = list.filter((u) => u.checked);
    console.log("selectedUsers", selectedUsers)
    speakText('Start Call', true)
    navigate("/PFAVideoCall", { state: selectedUsers });
  };

  const [excludedId, setExcludedId] = useState(null);

  // Use useEffect to fetch logged in from localStorage on component mount
  useEffect(() => {
    const profile = JSON.parse(localStorage.getItem('profile'));
    if (profile && profile?.pfa_id) {
      setExcludedId(profile.pfa_id); // Set the excludedId from localStorage
    }
  }, []);

  return (
    <div>
      <div className="mainPage">
        <div className="Homeheaderbg">
          <div className="">
            <NavLink to="/MenuScreen">
              <img src={back_white} alt="" onClick={() => speakText('Back', true)} />
            </NavLink>
          </div>
          <div className="customer-info">
            <h1 className="w-100">
              <span>Select Contacts</span>

              <span
                className={` start-video-call ${showList.length && showList.some((li) => li.checked == true)
                  ? ""
                  : "v-hidden"
                  }`}
              >
                <button className="btnVidioCall" onClick={handleStartVideoCall}>
                  Start Call

                </button>
              </span>
            </h1>
          </div>

          <div className="customer-info">
            <div className="col-md-6"></div>
            <div className="col-md-6"></div>
          </div>
        </div>

        <div className="parent">
          <div className="contentboxgray11">
            <br />
          </div>
        </div>

        <div className="contentBody contentbodymedia mtminus ">
          <div className="contentBody mb-5">
            <div className="row justify-content-center">
              <AutoSuggest
                data={list}
                filteredList={filteredList}
                findKey={"name"}
              />

              {isLoading ? (
                // <Spinner animation="border" variant="primary" />
                <div className="loader-container">
                  <img src={loader} alt="Loading..." className="custom-loader" />
                </div>
              ) : showList.length ? (
                showList.map((item, index) => {
                  // Skip rendering if item.id is equal to logged in member
                  if (item?.id === excludedId) {
                    return null;
                  }

                  return (
                  <div
                    key={`${item.id}_${item.role}`}
                    className="media boxmediawhite"
                  >
                    <img
                      className="align-self-center me-3"
                      src={item.photo ? `${item.photo}` : user_small}
                      alt=""
                    />
                    <div className="media-body">
                      <h5 className="mt-0 mb-0">
                        {item.name}
                        {item.video_call_disabled ? (
                          <p className="fs-6">
                            The video call feature is disabled for this Loved
                            one
                          </p>
                        ) : (
                          ""
                        )}
                        <div className="form-check checkBoxBlog">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value={item.checked}
                            checked={item.checked}
                            id={`${item.id}_${item.role}`}
                            onChange={(e) =>
                              onClickContact(item, index, e.target.checked)
                            }
                            disabled={item.video_call_disabled}
                          />
                          <label
                            className="form-check-label"
                            for={`${item.id}_${item.role}`}
                          ></label>
                        </div>
                      </h5>
                    </div>
                  </div>
                )})
              ) : (
                <div className=" text-center nocontact">
                  <img src={noContactImage} alt="no contact" />
                  <h3 className="pt-4">No Contacts Found</h3>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="bigpurplebottom text-center bgWhite">
          <img src={bigPurplePhoneLogo} alt="footer" />
        </div>
      </div>
    </div>
  );
}
