export const setProfile = (data) => {
  localStorage.setItem("profile", JSON.stringify(data));
};

export const getProfile = () => {
  return JSON.parse(localStorage.getItem("profile"));
};

export const setToken = (token) => {
  localStorage.setItem("api_token", token);
};

export const getToken = () => {
  return localStorage.getItem("api_token");
};

export const clearLocalStorage = () => {
  localStorage.clear();
};

export const setAutoLoginUserId = (id) => {
  return localStorage.setItem("auto_login_id", id);
}

export const getAutoLoginUserid = () => {
  return localStorage.getItem("auto_login_id");
}

export const RemoveAutoLoginUserId = () => {
  return localStorage.removeItem("auto_login_id");
}

export const setPassword = (password) => {
  console.log("setPassword", password)
  return localStorage.setItem("password", password);
};
export const getPassword = () => {
  return localStorage.getItem("password");
}

export const removePassword = () => {
  return localStorage.removeItem("password")
}

export const setRole = (role) => {
  console.log("setPassword", role)
  return localStorage.setItem("role", role);
};
export const getRole = () => {
  return localStorage.getItem("role");
}

export const deleteRole = () => {
  return localStorage.removeItem("role")
}