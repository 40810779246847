import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function NonAuthorizedSplash() {
  const navigate = useNavigate();

  useEffect(() => {
    // navigate("/LoginScreen");
    window.location = "/LoginScreen";
    // Please don't remove this comment. as per client request currently we comment the set timeout function
    // setTimeout(() => {
    //   navigate("/LoginScreen");
    // }, 11000);
  }, []);
  return (
    <div className="mainPage ">
      {/* // Please don't remove this comment. as per client request currently we comment the set timeout function */}
      {/* <div className="headerOuter">
        <video
          autoPlay
          id="myVideo"
          className="videoOuter"
          muted
          loop
          playsInline
          src="/video_splash.mp4"
          type="video/mp4"
          webkit-playsinline
        >
        </video>
      </div> */}
    </div>
  );
}
