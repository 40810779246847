import React from "react";
import { NavLink } from "react-bootstrap";

import backWhite from "../../assets/images/back_white.svg";
import uploadPhoto from "../../assets/images/upload-photo.svg";
import photo1 from "../../assets/images/photo1.svg";
import photo2 from "../../assets/images/photo2.svg";
import photo3 from "../../assets/images/photo3.svg";
import photo4 from "../../assets/images/photo4.svg";
import photo5 from "../../assets/images/photo5.svg";
import photo6 from "../../assets/images/photo6.svg";

export default function PFAPhotoSelection() {
  // useEffect(() => {
  //   getGallaryPhotos(lovedone.id)
  //     .then((response) => {
  //       console.log(response.data.data);
  //       setList(response.data.data);
  //     })
  //     .finally(() => {
  //       setIsLoading(false);
  //     });
  // }, []);

  return (
    <div className="pt80">
      <div className="mainPage">
        <div className="Homeheaderbg headerfixed text-white">
          <div className="d-flex align-self-center">
            <NavLink to="/MenuScreen">
              <img src={backWhite} alt="" />
            </NavLink>
            <h2 className="fs20 mb-0 text-center mx-auto">photos</h2>
          </div>
        </div>
        <div className="parent">
          <div className="contentboxgray11">
            <br />
          </div>
        </div>
        <div className="contentBody contentbodymedia ">
          <div className="">
            <div className="row justify-content-center">
              <div className="media boxmediablue text-white">
                <a href="#" className="anchoroveray"></a>
                <img
                  className="align-self-center me-3"
                  src={uploadPhoto}
                  alt=""
                  title=""
                />
                <div className="media-body">
                  <h5 className="mt-0">
                    <b>Add New Photos</b>
                  </h5>
                </div>
              </div>
            </div>

            <div className="row imageupload text-center">
              <div className="col-6">
                <div className="photosBlog">
                  <img src={photo1} alt="" title="" />
                  <div className="imgSelectCheck">
                    <div className="circlebox ">
                      <div className="form-group mb-0">
                        <input type="checkbox" id="one" />
                        <label htmlFor="one"></label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-6">
                <div className="photosBlog">
                  <img src={photo2} alt="" title="" />
                  <div className="imgSelectCheck">
                    <div className="circlebox ">
                      <div className="form-group mb-0">
                        <input type="checkbox" id="two" />
                        <label htmlFor="two"></label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-6">
                <div className="photosBlog">
                  <img src={photo3} alt="" title="" />
                  <div className="imgSelectCheck">
                    <div className="circlebox ">
                      <div className="form-group mb-0">
                        <input type="checkbox" id="thr" />
                        <label htmlFor="thr"></label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-6">
                <div className="photosBlog">
                  <img src={photo4} alt="" title="" />
                  <div className="imgSelectCheck">
                    <div className="circlebox ">
                      <div className="form-group mb-0">
                        <input type="checkbox" id="for" />
                        <label htmlFor="for"></label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-6">
                <div className="photosBlog">
                  <img src={photo5} alt="" title="" />
                  <div className="imgSelectCheck">
                    <div className="circlebox ">
                      <div className="form-group mb-0">
                        <input type="checkbox" id="pha" />
                        <label htmlFor="pha"></label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-6">
                <div className="photosBlog">
                  <img src={photo6} alt="" title="" />
                  <div className="imgSelectCheck">
                    <div className="circlebox ">
                      <div className="form-group mb-0">
                        <input type="checkbox" id="cx" />
                        <label htmlFor="cx"></label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <button
                type="button"
                // onClick={onClickUpdate}
                className="btn btn-primary w-100 mt-3 mb-3"
              >
                Send
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
