import React, { useState, useEffect } from "react";
import useSetupData from "../../hooks/useSetupData";

import SetupLayout from "./SetUpLayout";
import { useDispatch, useSelector } from "react-redux";
import { handleConfirm, setStatus } from "../../redux/setupStore";
import { useNavigate } from "react-router-dom";
import UploadProfilePhoto from "../../components/UploadProfilePhoto";
export default function UploadLoProfile() {
  const setupData = useSetupData();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const setupStore = useSelector((state) => state.setup);

  let given_name = `${setupStore.data.lovedOne.first_name}`;
  const handleUpload = (photo, loPrevUrl) => {
    let payload = {
      status: false,
    };
    dispatch(setStatus(payload));
    if (photo) {
      navigate("/setup/ShowLoProfilePhoto", { state: { photo, loPrevUrl } });
    }
  };
  return (
    <SetupLayout heading="My Loved One" backNav={"LovedOneName"}>
      <div className="contentBody contentbodymedia mtminus">
        <UploadProfilePhoto handleUpload={handleUpload} />

        <div className="contentBody fulogin">
          <div className="row fmsignup">
            <div className="col-lg-12 mb-5">
              <h2 className="topTileHeading">
                Upload {given_name}'s profile picture
              </h2>

              <p>Please upload your LovedOne's profile picture.</p>

              <p>You can always change it later.</p>
            </div>
          </div>
        </div>
      </div>
    </SetupLayout>
  );
}
