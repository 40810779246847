import React from "react";

import selctContact from "../assets/images/selctContact.svg";
import { useNavigate, useLocation } from "react-router-dom";

export default function ContactUploadSucc() {
  const navigate = useNavigate();
  const onClickUploaded = () => {
    navigate("/SearchContact");
  };
  // const location = useLocation();
  // console.log(location);

  return (
    <div className="mainPage loginPage">
      <div className="successful positioncenter">
        <div className="card  text-center">
          <div className="bluebox">
            <div className="circlemark">
              <img src={selctContact} />
            </div>
            <h5 className="text-white pt-4 mb-0">Contacts</h5>
          </div>
          <div className="card-body">
            <p className="card-text">
              Uploaded Successfully.
              <br />
              {/* {location.state} */}
            </p>

            <button
              type="button"
              onClick={onClickUploaded}
              className="btn btn-primary w-100 mt-3"
            >
              OK
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
