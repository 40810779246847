// export default function serviceWorker() {
//     let swUrl = `${process.env.PUBLIC_URL}/sw.js`
//     navigator.serviceWorker.register(swUrl).then(response => {
//         console.log("service worker done")
//     })
// }

// export const baseUrl = process.env.REACT_APP_UAT_BASEURL;
// export const baseUrl = process.env.REACT_APP_LIVE_URL;
// export const baseUrl = process.env.REACT_APP_LOCAL_BASEURL;
export const baseUrl = process.env.REACT_APP_LIVE_URL;
// export const baseUrl = process.env.REACT_APP_DEV_BASEURL;
// export const csvUrl = process.env.REACT_APP_CSV_DEV_SAMPLE_URL;
export const csvUrl = process.env.REACT_APP_CSV_SAMPLE_URL;
