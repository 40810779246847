import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { Spinner, Modal } from "react-bootstrap";
import back_white from "assets/images/back_white.svg";
import customer from "assets/images/customer.svg";
import menu_phone from "assets/images/phoneApp.svg";
import menu_sos from "assets/images/menu_sos.svg";
import menu_accessibility from "assets/images/menu_accessibility.svg";
import menu_video_call from "assets/images/videoApp.svg";
import menu_messages from "assets/images/videoMessageApp.svg";
import menu_camera from "assets/images/cameraApp.svg";
import menu_photos from "assets/images/photoApp.svg";
import menu_weather from "assets/images/weatherApp.svg";
import menu_bpp from "assets/images/icon_bpp.svg";
import menu_radio from "assets/images/radioApp.svg";
import menu_games from "assets/images/gameApp.svg";
import menu_news from "assets/images/newsApp.svg";
import menu_music from "assets/images/musicApp.svg";
import menu_vaccination from "assets/images/vaccine.svg";
import menu_help from "assets/images/menu_help.svg";
import menu_whatsup from "assets/images/WhatsappPWA.svg";
import custom_browser from "assets/images/custom_browser.svg";
import menu_covid_check_in from "assets/images/covid-checkin.svg";
import noContactImage from "assets/images/no-contact1.svg";
import bigPurplePhoneLogo from "assets/images/bigPurpleLogo-light.svg";
import editCircle from "assets/images/editCircle.png";
import Billing from "assets/images/billing.svg";
import Support from "assets/images/supportVideo.svg";
import Contacts from "assets/images/contact.svg";
import Birthday from "assets/images/birthdaysAnniversaries.svg";
import deleteConfirmIcon from "assets/images/delete_confrm.svg";
import Reminders from "assets/images/reminders.svg";
import useSelectedLovedOne from "hooks/useSelectedLovedOne";
import { getLOMenuEditable, editLovedOneMenu } from "api/api";
import { useErrorHandler } from "react-error-boundary";
import { getSettings, updateSettings } from "../api/api";
import CustomModal from "components/Modal/modal";
import IphonePWAPopup from "components/IphonePWAPopup";
import { getUncheckedPhotoCount } from "api/gallery";

const ImageComponent = {
  menu_phone: menu_phone,
  menu_video_call: menu_video_call,
  menu_messages: menu_messages,
  menu_camera: menu_camera,
  menu_photos: menu_photos,
  menu_weather: menu_weather,
  icon_bpp: menu_bpp,
  menu_radio: menu_radio,
  menu_games: menu_games,
  menu_news: menu_news,
  menu_sos: menu_sos,
  menu_accessibility: menu_accessibility,
  menu_whatsup: menu_whatsup,
  custom_browser: custom_browser,
  menu_music: menu_music,
  menu_vaccination: menu_vaccination,
  menu_covid_check_in: menu_covid_check_in,
  menu_help: menu_help,
  Billing,
  Support,
  Contacts,
  Birthday,
  Reminders,
};

export default function MenuScreenSecond() {
  const lovedOne = useSelectedLovedOne();
  const navigate = useNavigate();

  const [menuHeading, setMenu] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showSosModal, setShowSosModal] = useState(false);
  const [settings, setSettings] = useState({});
  const [uncheckedPhoto, setUncheckedPhoto] = useState(0);

  const handleError = useErrorHandler();
  useEffect(() => {
    setLoading(true);
    if (lovedOne && lovedOne.id) {
      getLOMenuEditable(lovedOne.id)
        .then((result) => {
          console.log("result :>> ", result);
          setMenu(result.data.data);
          getUncheckedPhotoCount(lovedOne.id).then((res) =>
            setUncheckedPhoto(res.data.data)
          );
          getSettings(lovedOne?.id ?? "")
            .then((data) => {
              setLoading(false);
              setSettings(data);
            })
            .catch((error) => {
              return error.response.data.statusCode != 400
                ? handleError(error)
                : "";
            });
        })
        .catch((error) => {
          setMenu([]);
          console.log(error);
          return error.response.data.statusCode != 400
            ? handleError(error)
            : "";
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [lovedOne && lovedOne.id]);

  const handleModal =
    (status = false, item = false) =>
    () => {
      if (item.primary) {
        return false;
      }
      setShowModal(status ? item : false);
    };
  const handleSosModal =
    (status = false, item = false) =>
    () => {
      if (item.primary) {
        return false;
      }
      console.log("item", item);
      setShowSosModal(status ? item : false);
    };
  const handleSosCheck = (showModal) => {
    console.log("showModal", showModal);
    handleSettings(showModal ? 1 : 0);
    setShowSosModal(false);
  };
  const handleCheck = (item) => async (event) => {
    let data = {
      hidden: event.target.checked ? 0 : 1,
      id: item.id,
    };
    editLovedOneMenu(data)
      .then((res) => {
        setShowModal(false);
        getLOMenuEditable(lovedOne.id)
          .then((result) => {
            setMenu(result.data.data);
          })
          .catch((error) => {
            setMenu([]);
            console.log(error);
            return error.response.data.statusCode != 400
              ? handleError(error)
              : "";
          });
      })
      .catch((error) =>
        error.response.data.statusCode != 400 ? handleError(error) : ""
      )
      .finally(() => {
        setLoading(false);
      });
  };
  const handleSettings = (value) => {
    console.log(value, "value:::::");
    value = settings.sos_button ? 0 : 1;
    setSettings({ ...settings, sos_button: value });
    updateSettings({ ...settings, lovedOneId: lovedOne?.id, sos_button: value })
      .then((data) => {})
      .catch((error) => {
        return error.response.data.statusCode != 400 ? handleError(error) : "";
      });
  };
  let hideMenu = ["Games", "News"];
  let hideToggle = ["Phone"];
  if (isLoading) return <Spinner animation="border" variant="primary" />;
  return (
    <>
      <IphonePWAPopup />
      <div className="mainPage">
        <div className="Homeheaderbg">
          <div className="d-flex">
            <NavLink to="/LovedOneList">
              <img src={back_white} />
            </NavLink>
            <div className="loginLogo">
              <img src={bigPurplePhoneLogo} className="w-100" />
            </div>
          </div>
          <div className="customer-info">
            <div className="col-md-6">
              <h3 className="m-0">Manage</h3>
              <h1>{lovedOne?.first_name}'s</h1>
              <h3>
                Big<b>Purple</b>Phone
              </h3>
            </div>
            <div className="pe-3">
              <div className="imgspace">
                <img
                  alt=""
                  src={lovedOne?.photo ? lovedOne.photo : customer}
                  className="imgbig"
                />

                <img
                  src={editCircle}
                  className="imgEditSmall"
                  alt=""
                  onClick={() =>
                    navigate("/LOEditProfile", {
                      state: { ...lovedOne, backpage: "/MenuScreen" },
                    })
                  }
                />
              </div>
            </div>
          </div>
        </div>
        <div className="parent">
          <div className="contentboxgray11">
            <br />
          </div>
        </div>

        <div className="contentBody contentbodymedia manageapps">
          <div className="contentBody">
            <div className="row justify-content-center">
              <div className="col-md-12">
                <h5 className="menuHeaderText">
                  Here you can make video calls with {lovedOne?.first_name} and
                  their friends and family, add contacts and set up{" "}
                  {lovedOne?.first_name}'s Big<b>Purple</b>Phone just the way
                  they like it! You can also disable features, set reminders,
                  share photos and more. Scroll down for options.
                </h5>

                {Object.keys(menuHeading).length ? (
                  Object.keys(menuHeading).map(function (heading, i) {
                    return menuHeading[heading].map((item, index) =>
                      hideMenu.includes(item.name) ? (
                        ""
                      ) : (
                        <div key={item.id}>
                          {index == 0 ? (
                            <h1 className="sectionHeader menuScreen-heading">
                              {heading}
                            </h1>
                          ) : (
                            ""
                          )}
                          <div className="justify-content-center">
                            <div
                              className={`media boxmediawhite ${
                                (item.hidden && item.name != "SOS") ||
                                (item.name == "SOS" && !settings.sos_button)
                                  ? "boxMediaGray"
                                  : ""
                              }`}
                              style={{ display: "block" }}
                            >
                              {item.name === "Support" ? (
                                <a
                                  href={`${item.slug}`}
                                  target="_blank"
                                  className="position-relative d-inline-block"
                                >
                                  <img
                                    className="align-self-center "
                                    src={ImageComponent[item.icon_name]}
                                    alt={item.name}
                                    title={item.name}
                                  />
                                </a>
                              ) : item.name === "Messages" ? (
                                <a
                                  href={
                                    lovedOne?.mobile && !item.hidden
                                      ? `sms:${lovedOne.mobile}`
                                      : "javascript:void()"
                                  }
                                  className={item.hidden ? "noPointer" : ""}
                                >
                                  <img
                                    className="align-self-center "
                                    src={ImageComponent[item.icon_name]}
                                    alt={item.name}
                                    title={item.name}
                                  />
                                </a>
                              ) : (
                                <NavLink
                                  to={`${item.hidden ? "#" : item.slug}`}
                                  className={`${
                                    item.hidden ? "noPointer" : ""
                                  } position-relative d-inline-block`}
                                >
                                  {uncheckedPhoto && item.name == "Photos" ? (
                                    <label className="imgNoCircleLable">
                                      {uncheckedPhoto}
                                    </label>
                                  ) : (
                                    ""
                                  )}
                                  <img
                                    className="align-self-center "
                                    src={ImageComponent[item.icon_name]}
                                    alt={item.name}
                                    title={item.name}
                                  />
                                </NavLink>
                              )}

                              {item.toggle &&
                              !hideToggle.includes(item.name) &&
                              item.name !== "SOS" ? (
                                <div
                                  className="form-check form-switch"
                                  style={{ top: "40px" }}
                                >
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id={item.id}
                                    onChange={
                                      item.hidden
                                        ? handleCheck(item)
                                        : handleModal(true, item)
                                    }
                                    checked={item.hidden ? false : true}
                                    disabled={
                                      item.name == "Games" ||
                                      item.name == "News"
                                        ? true
                                        : false
                                    }
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor={item.id}
                                  ></label>
                                </div>
                              ) : item.name == "SOS" ? (
                                <div
                                  className="form-check form-switch"
                                  style={{ top: "40px" }}
                                >
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    checked={settings.sos_button ? true : false}
                                    // onChange={(event) => {
                                    //   if (settings.sos_button) {
                                    //     console.log('on')
                                    //     handleSosModal(true, item)
                                    //   }
                                    //   else {
                                    //     console.log('off')
                                    //     handleSettings(event.target.value)
                                    //   }
                                    // }}

                                    onChange={
                                      settings.sos_button
                                        ? handleSosModal(true, item)
                                        : () => handleSettings(0)
                                    }
                                    id={item.id}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor={item.id}
                                  ></label>
                                </div>
                              ) : (
                                ""
                              )}

                              <div
                                className="media-body"
                                style={{
                                  paddingLeft: "15px",
                                  marginRight: "0",
                                }}
                              >
                                <h5 className="mt-0 mb-0"> {item.name}</h5>
                                <p
                                  className="subTitleBlog"
                                  dangerouslySetInnerHTML={{
                                    __html: item.hidden
                                      ? `${
                                          item.icon_name == "menu_video_call"
                                            ? "Video Calls"
                                            : item.name
                                        } are disabled`
                                      : item.text,
                                  }}
                                />
                              </div>
                            </div>

                            <CustomModal
                              toggleModal={handleModal}
                              isOpen={showModal}
                              title={"Confirmation"}
                              description={
                                <>
                                  <p className="mb-3 text-center">
                                    <img
                                      src={deleteConfirmIcon}
                                      alt="delete confirmation"
                                    />
                                  </p>
                                  <p className="text-center">
                                    This will disable this feature on{" "}
                                    {lovedOne?.first_name}'s Big<b>Purple</b>
                                    Phone. Are you sure?{" "}
                                  </p>
                                </>
                              }
                              prompt={{
                                title: "OK",
                                cancel: "Cancel",
                                handle: handleCheck(showModal),
                              }}
                            />
                            <CustomModal
                              toggleModal={handleSosModal}
                              isOpen={showSosModal}
                              title={"Confirmation"}
                              description={
                                <>
                                  <p className="mb-3 text-center">
                                    <img
                                      src={deleteConfirmIcon}
                                      alt="delete confirmation"
                                    />
                                  </p>
                                  <p className="text-center">
                                    This will disable this feature on{" "}
                                    {lovedOne?.first_name}'s Big<b>Purple</b>
                                    Phone. Are you sure?{" "}
                                  </p>
                                </>
                              }
                              prompt={{
                                title: "OK",
                                cancel: "Cancel",
                                handle: () => handleSosCheck(showModal),
                              }}
                            />
                          </div>
                        </div>
                      )
                    );
                  })
                ) : (
                  <div className=" text-center nocontact">
                    <img src={noContactImage} alt="no contact" />
                    <h3 className="pt-4">No results found</h3>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
