import React, { useState, useEffect } from "react";
import useSetupData from "hooks/useSetupData";
import SetupLayout from "./SetUpLayout";
import { useDispatch, useSelector } from "react-redux";
import { handleConfirm } from "redux/setupStore";
import { useLocation, useNavigate } from "react-router-dom";
import UploadProfilePhoto from "components/UploadProfilePhoto";
import screenshot from "assets/images/screenShot.svg";
import { setupWalkThrough } from "api/api";
import { Spinner } from "react-bootstrap";
import useLoginUser from "hooks/useLoginUser";
import useSelectedLovedOne from "hooks/useSelectedLovedOne";

export default function AllDone() {
  const location = useLocation();
  const setupData = useSetupData();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState();
  const { profile } = useLoginUser();
  // const lovedOne = useSelectedLovedOne();
  const loved_one = useSelectedLovedOne();
  const setupStore = useSelector((state) => state.setup);
  let {
    data: { lovedOne },
  } = setupStore;
  console.log("setupStore :>> ", setupStore.data);

  useEffect(() => {
    let data = new FormData();
    for (const keys of Object.keys(setupStore.data)) {
      console.log("keys :>> ", keys);
      let value = setupStore.data[keys];
      if (keys == "lovedOne") {
        value = JSON.stringify(setupStore.data[keys]);
      }
      data.append(keys, value);
    }

    setLoading(true);
    setupWalkThrough(data)
      .then((res) => {
        console.log("res.data :>> ", res.data);
        setLoading(false);
      })
      .catch((er) => {
        console.log("er :>> ", er);
        setLoading(false);
      });
  }, []);

  return (
    <SetupLayout heading="My Mobile number">
      <UploadProfilePhoto handleUpload={false} value={lovedOne.loPrevUrl} profile={loved_one} />

      <div className="profilTileHeading ">{lovedOne.first_name}</div>
      {isLoading ? (
        <div className="contentBody fulogin text-center">
          <Spinner animation="border" variant="primary" />
        </div>
      ) : (
        <div className="contentBody fulogin">
          <div className="row fmsignup">
            <div className="col-lg-12">
              <h2 className="topTileHeading mb-4">You're all done!!</h2>
            </div>
          </div>
          <div className="row fmsignup urDone">
            <div className="col-6">

              What happens next:<br />
              You can start inviting Friends and Family to {lovedOne.first_name}
              's network! Sign in on the next screen to Add Contacts to {" "}
              {lovedOne.first_name}'s BigPurplePhone.
              {/* <ol>
                <li>
                  
                </li>
              </ol> */}
            </div>
            <div className="col-6">
              <img src={screenshot} alt="screenshot" />
            </div>
          </div>
          <div className="row">
            <div className="d-flex mt-3 mb-3">
              <button
                type="button"
                onClick={() => navigate("/")}
                className="btn btn-primary w-100  "
              >
                Next
              </button>
            </div>
          </div>
          {/* <div className="row">
            <div className="col-md-12 mb-3 text-center">
              <a href="#" className="doItLater">
                Do it later
              </a>
            </div>
          </div> */}
        </div>
      )}
    </SetupLayout>
  );
}
