import React from "react";
import useSetupData from "hooks/useSetupData";
import SetupLayout from "./SetUpLayout";
import { useDispatch, useSelector } from "react-redux";
import { handleConfirm } from "redux/setupStore";
import { useLocation, useNavigate } from "react-router-dom";
import UploadProfilePhoto from "components/UploadProfilePhoto";

export default function ShowLoProfilePhoto() {
  const location = useLocation();
  const setupData = useSetupData();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const setupStore = useSelector((state) => state.setup);
  console.log("location.state :>> ", location.state);
  const handleNext = async () => {
    let payload = {
      key: "lovedOnePhoto",
      data: {
        loPhoto: location.state?.photo || setupStore?.data?.loPhoto,
        lovedOne: {
          ...setupStore.data.lovedOne,
          // photo: location.state?.photo || setupStore?.data?.lovedOne?.photo,
          loPrevUrl:
            location.state?.loPrevUrl || setupStore?.data?.lovedOne?.loPrevUrl,
        },
      },
    };
    dispatch(handleConfirm(payload));
    // navigate("/setup/LoLocation");
    navigate(`/setup/AllDone`);
  };

  return (
    <SetupLayout heading="My Loved One" backNav={"UploadLoProfile"}>
      <div className="contentBody contentbodymedia mtminus">
        <UploadProfilePhoto
          handleUpload={false}
          value={
            location.state?.loPrevUrl || setupStore?.data?.lovedOne?.loPrevUrl
          }
        />

        <div className="profilTileHeading">
          {setupStore.data.lovedOne.first_name}
        </div>

        <div className="contentBody fulogin">
          <div className="row fmsignup">
            <div className="col-lg-12">
              <h2 className="topTileHeading">Great work!</h2>
              <p style={{ minHeight: "180px" }}></p>
            </div>
          </div>
          <div className="row">
            <div className="d-flex mt-3 mb-3">
              <button
                type="button"
                onClick={handleNext}
                className="btn btn-primary w-100  "
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </SetupLayout>
  );
}
