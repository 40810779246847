import React, { useState } from "react";
import { useNavigate, NavLink, useLocation } from "react-router-dom";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import back_white from "../assets/images/back_white.svg";
import LovedOnePhoneName from "../components/LovedPhoneName";
import { addAnnualReminder, updateAnnualReminder } from "../api/api";
import useSelectedLovedOne from "../hooks/useSelectedLovedOne";
import dayjs from "dayjs";
import "react-datepicker/dist/react-datepicker.css";
import { useErrorHandler } from "react-error-boundary";
export default function AddBirthday() {
  const location = useLocation();
  const navigate = useNavigate();
  const loved_one = useSelectedLovedOne();
  const [event_date, setEventDate] = useState(
    location.state
      ? dayjs(location.state.event_date).format("YYYY-MM-DD")
      : dayjs().format("YYYY-MM-DD")
  );

  const [event, setEvent] = useState(location.state?.event || 1);
  const [message, setMessage] = useState(
    location.state ? location.state.message : ""
  );

  const [errors, setError] = useState({});
  const handleError = useErrorHandler();

  const handleBirthday = async () => {
    let data = {
      lovedOneId: loved_one.id,
      message,
      event_date,
      event,
    };
    let is_error = { ...errors };

    if (data.message == "") {
      is_error.message = true;
      setError({ ...errors, message: "Please enter Title" });
    }

    // check if any field has error
    let isValidateFields = Object.values(is_error).every(
      (item) => item == false
    );
    if (isValidateFields) {
      let callApi = addAnnualReminder;
      if (location.state) {
        callApi = updateAnnualReminder;
        data.id = location.state.id;
      }
      callApi(data)
        .then((result) => {
          if (result.data) {
            navigate("/PFAManageBirthday");
          }
        })
        .catch((error) => {
          return error.response.data.statusCode != 400
            ? handleError(error)
            : "";
        });
    }
  };

  const handleChangeMessage = (e) => {
    let t = e.target.value;
    setMessage(t);

    if (t.length > 25) {
      setError({
        message: "Character limit is upto 25 Characters",
      });
    } else {
      setError({
        message: false,
      });
    }
  };

  const handleChange = (event) => {
    let formatted_time = event.format("YYYY-MM-DD");
    setEventDate(formatted_time);
  };
  const handleSelectEvent = (ev) => {
    setEvent(ev.target.value);
  };

  return (
    <div className="pt80">
      <div className="mainPage">
        <div className="Homeheaderbg headerfixed text-white">
          <div className="d-flex align-self-center">
            <NavLink to="/PFAManageBirthday">
              <img src={back_white} alt="" title="" />
            </NavLink>
            <h2 className="fs20 mb-0 text-center mx-auto">
              Add Birthday/Anniversary
            </h2>
          </div>
        </div>
        <LovedOnePhoneName loved_one={loved_one} />

        <div className="contentBody contentboxgray  contentbodymedia">
          <div className="row justify-content-center timer pt-5 ">
            <div className="birthdayPopup">
              <label htmlFor="event_date" className="form-label">
                Select Event
              </label>

              <div className="form-group mb-0">
                <input
                  type="radio"
                  id={1}
                  value={1}
                  name="selectEvent"
                  onChange={handleSelectEvent}
                  checked={1 == event}
                />
                <label className="radioLabel" htmlFor={1}>
                  Birthday
                </label>
                <input
                  type="radio"
                  id={2}
                  value={2}
                  name="selectEvent"
                  onChange={handleSelectEvent}
                  checked={2 == event}
                />
                <label className="radioLabel" htmlFor={2}>
                  Anniversary
                </label>
              </div>
            </div>
          </div>

          <div className="row justify-content-center timer pt-5 ">
            <div className="birthdayPopup">
              <label htmlFor="event_date" className="form-label">
                Select Date
              </label>
              <Datetime
                timeFormat={false}
                className="addBirthday"
                onChange={handleChange}
                value={event_date}
                dateFormat={"YYYY-MM-DD"}
                closeOnSelect
              />
            </div>
          </div>

          <div className="mb-3">
            <div className="inputIcon">
              <h5>Title</h5>
              <textarea
                id="msz"
                name="msz"
                rows="2"
                width="100%"
                placeholder="Enter Here.."
                onChange={handleChangeMessage}
                value={message}
              ></textarea>
              {errors.message ? (
                <span className="error">{errors.message}</span>
              ) : (
                ""
              )}
            </div>

            <div className="mb-3">
              <div className="d-flex mt-5 mb-3">
                <button
                  type="button"
                  onClick={() => {
                    navigate("/PFAManageBirthday");
                  }}
                  className="btn btn-primary w-100 me-2 btnwhite textred"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  onClick={handleBirthday}
                  className="btn btn-primary w-100 ms-2 "
                >
                  {location.state ? "Update" : "Add"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
