import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import back_white from "../assets/images/back_white.svg";
import reminder from "../assets/images/reminder.svg";
import { getReminder, changeReminderStatus, deleteReminder } from "../api/api";
import useSelectedLovedOne from "../hooks/useSelectedLovedOne";
import { Spinner, Modal } from "react-bootstrap";
import LovedOnePhoneName from "../components/LovedPhoneName";
import noContactImage from "../assets/images/no-contact1.svg";
import { useErrorHandler } from "react-error-boundary";
import deleteConfirmIcon from "assets/images/delete_confrm.svg";
import deleteIcon from "assets/images/delete.svg";
import { speakText } from "utils/utils";
import loader from "assets/images/loader.gif"



export default function PFAManageReminder() {
  const loved_one = useSelectedLovedOne();
  const navigate = useNavigate();
  const [reminders, setReminders] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  console.log("loved_one :>> ", loved_one);
  const handleError = useErrorHandler();
  useEffect(() => {
    setLoading(true);
    if (loved_one && loved_one.id) {
      getReminder({ lovedOneId: loved_one.id })
        .then((res) => {
          const { data, status } = res;
          setReminders(data.data);
          setLoading(false);
        })
        .catch((error) => {
          setReminders([]);
          setLoading(false);
          return error.response.data.statusCode != 400
            ? handleError(error)
            : "";
        });
    }
  }, [loved_one && loved_one.id]);
  const getDay = (index) => {
    switch (index) {
      case 0:
        return "M";
      case 1:
        return "T";
      case 2:
        return "W";
      case 3:
        return "T";
      case 4:
        return "F";
      case 5:
        return "S";
      case 6:
        return "S";
      default:
        return "M";
    }
  };

  const handleChange = async (event) => {
    const { id, checked } = event.target;
    let data = {
      lovedOneId: loved_one.id,
      status: checked,
      id,
    };
    changeReminderStatus(data)
      .then((res) => console.log("res:>>", res))
      .catch((error) => {
        return error.response.data.statusCode != 400 ? handleError(error) : "";
      })
  };
  const handelDelete = async () => {
    let data = {
      lovedOneId: loved_one.id,
      id: showModal,
    };
    deleteReminder(data)
      .then((res) => {
        setShowModal(0);
        let newList = reminders.filter((reminder) => {
          if (reminder.id != data.id) {
            return reminder;
          }
        });
        setReminders(newList)
      })
      .catch((error) => {
        return error.response.data.statusCode != 400 ? handleError(error) : "";
      })
  }
  const handleModal =
    (status = false, item = false) =>
      () => {
        if (item.primary) {
          return false;
        }
        setShowModal(status ? item : false);
      };
  let list = reminders.length ? (
    reminders.map((reminder, i) => (
      <div className="boxwhite newUIReminer">
        <h5
          className="mb-0 fsmed"
          style={{ minHeight: "20px" }}
          onClick={() => navigate("/Reminder", { state: reminder })}
        >
          {reminder.reminder}
        </h5>

        <div className="form-check form-switch">
          <button
            style={{ border: 0, backgroundColor: "#fff" }}
            onClick={() => setShowModal(reminder.id)}>
            <img src={deleteIcon} height="20" width="20" style={{ marginBottom: "10px" }} />
          </button>
          <input
            className="form-check-input"
            type="checkbox"
            id={reminder.id}
            defaultChecked={reminder.is_active}
            onChange={handleChange}
          />
          <label className="form-check-label" htmlFor={reminder.id}></label>
        </div>

        <div
          className="centerdays"
          onClick={() => navigate("/Reminder", { state: reminder })}
        >
          <h5 className="fsreg">{reminder.title}</h5>
          <div className="days">
            {reminder.days?.map((day, index) => (
              <h5 key={index} className={` ${day ? "dayselectclr" : ""}`}>
                {getDay(index)}
              </h5>
            ))}
          </div>
        </div>
      </div>
    ))
  ) : (
    <div className=" text-center nocontact">
      <img src={noContactImage} alt="no contact" />
      <h3 className="pt-4">No Reminder Found</h3>
    </div>
  );

  if (isLoading)
    return (
      <div
        style={{
          minHeight: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {/* <Spinner animation="border" variant="primary" size="100px" /> */}
        <img src={loader} alt="Loading..." className="custom-loader" />

      </div>
    );
  return (
    <>
      <div className="">
        <div className="mainPage">
          <div className="Homeheaderbg ">
            <div className="">
              <NavLink to="/MenuScreen">
                <img src={back_white} onClick={() => speakText('Back', true)}/>
              </NavLink>
            </div>
            <div className="customer-info">
              <div className="col-md-12">
                <h1>Add/Manage</h1>
                <h3>Reminders</h3>
              </div>
            </div>
          </div>
          <LovedOnePhoneName loved_one={loved_one} className="mtminus" />

          <div className="contentBody contentboxgray  contentbodymedia">
            <div className="row justify-content-center">
              <div className="col-md-12">
                <p className="mb-0 mt-3 menuHeaderText">
                  Set reminders such as medication and appointment reminders and{" "}
                  {loved_one?.first_name} will receive a notification.
                </p>
                <div className="media boxmediablue text-white mt-4">
                  <NavLink to="/Reminder" className="anchoroveray"></NavLink>
                  <img
                    className="align-self-center me-3"
                    src={reminder}
                    alt=""
                    title=""
                  />
                  <div className="media-body">
                    <h5 className="mt-0">
                      <b>Add New Reminders</b>
                    </h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="">
              <h5 className="pt-3 pb-1 fsmed">Manage Reminders</h5>
              {list}
            </div>
          </div>
        </div>
      </div>
      <Modal
        centered
        show={showModal}
        onHide={handleModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title style={{ width: "100%" }}>
            <h3 className="text-center">Confirmation</h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="mb-3 text-center">
            <img
              src={deleteConfirmIcon}
              alt="delete confirmation"
            />
          </p>

          <p className="text-center">
            Are you sure you want to delete this reminder?
          </p>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-primary"
            onClick={handleModal(false)}
          >
            Cancel
          </button>
          <button
            type="button"
            className="btn btn-danger"
            onClick={handelDelete}
          >
            Ok
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
