import React, { useState, useEffect } from "react";
import useSetupData from "../../hooks/useSetupData";

import SetupLayout from "./SetUpLayout";
import { useDispatch, useSelector } from "react-redux";
import { handleConfirm } from "redux/setupStore";
import { useLocation, useNavigate } from "react-router-dom";
import UploadProfilePhoto from "components/UploadProfilePhoto";

export default function ShowProfilePhoto() {
  const location = useLocation();
  const setupData = useSetupData();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const setupStore = useSelector((state) => state.setup);
  console.log("location.state :>> ", location.state);
  const handleNext = async () => {
    let payload = {
      data: {
        photo: location.state?.photo || setupStore?.data?.photo,
        prevUrl: location.state?.prevUrl || setupStore?.data?.prevUrl,
      },
    };
    dispatch(handleConfirm(payload));
    navigate("/setup/ContactDetails");
  };

  return (
    <SetupLayout backNav={"UploadProfile"}>
      <div className="nextBtnBotm">
        <UploadProfilePhoto
          handleUpload={false}
          value={location.state?.prevUrl || setupStore?.data?.prevUrl}
        />

        <div className="contentBody fulogin">
          <div className="row fmsignup">
            <div className="col-lg-12 mb-5">
              <h2 className="topTileHeading mb-0 pb-0">Nice profile pic,</h2>
              <h2 className="topTileHeading">
                {setupData?.customer?.first_name}!
              </h2>
              <p>
                When you add contacts in BigPurplePhone always add a profile pic
                so your loved one has a nice image to click on when contacting
                friends and family.
              </p>
            </div>
          </div>
        </div>
        <div className="fullBtnBottomFix">
          <button
            type="button"
            onClick={handleNext}
            className="btn btn-primary w-100  "
          >
            Next
          </button>
        </div>
      </div>
    </SetupLayout>
  );
}
