import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { onMessageListener } from "../utils/firebase";
// import useLoginUser from "../hooks/useLoginUser";

export default function NotificationComponent() {
  const navigate = useNavigate();
  const profile = localStorage.getItem("profile")
    ? JSON.parse(localStorage.getItem("profile"))
    : "";

  if (profile) {
    onMessageListener()
      .then((payload) => {
        let { data } = payload;
        let url = "MenuScreen";
        if (data.type == "videoCall") {
          url = `PFAVideoCall/${data.caller_role}/${data.caller_id}/${
            data.room_id
          }?d=${JSON.stringify(data)}`;
          navigate(`/${url}`, { state: payload.data });
        } else if (data.type == "missedVideoCall") {
          toast(data.title, { type: "info" });
          navigate(`/${url}`,{ state: payload.data });
        } else if (data.type == "sharePhoto") {
          toast(data.title, { type: "info" });
        }
      })
      .catch((err) => console.log("failed: ", err));
  }

  return null;
}
