import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { changeLovedOne } from "../redux/lovedOne";
import { useDispatch } from "react-redux";

export default function useSelectedLovedOne() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const lovedOne = useSelector((state) => state.lovedOne.value);
  const user = useSelector((state) => state.user);
  useEffect(() => {
    // console.log("lovedOne :>> ", lovedOne, Object.keys(lovedOne).length);
    let isLovedOne = Object.keys(lovedOne).length;

    if (!Object.keys(lovedOne).length) {
      let lovedOneList = user?.profile?.lovedOneList;
      if (user.isAuthorized && !isLovedOne) {
        if (sessionStorage.getItem("lovedOne")) {
          lovedOneList = [JSON.parse(sessionStorage.getItem("lovedOne"))];
          dispatch(changeLovedOne(lovedOneList[0]));
        }
      }
      console.log("lovedOneList :>> ", lovedOneList);
      if (lovedOneList && lovedOneList.length) {
        if (lovedOneList.length > 1) {
          navigate("/LovedOneList");
        } else {
          dispatch(changeLovedOne(lovedOneList[0]));
          // navigate("/MenuScreen", { state: lovedOneList[0] });
        }
      } else {
        navigate("/LovedOneList");
      }
    }
  }, []);

  return lovedOne;
}


// import { useEffect } from "react";
// import { useSelector } from "react-redux";
// import { useNavigate } from "react-router-dom";
// import { changeLovedOne } from "../redux/lovedOne";
// import { useDispatch } from "react-redux";

// export default function useSelectedLovedOne() {
//   const dispatch = useDispatch();
//   const navigate = useNavigate();

//   const lovedOne = useSelector((state) => state.lovedOne.value);
//   const user = useSelector((state) => state.user);

//   console.log("loved one in hook", lovedOne)

//   useEffect(() => {
//     // Check if there is a selected loved one already
//     if (!Object.keys(lovedOne).length) {
//       // No selected loved one, check user profile
//       let lovedOneList = user?.profile?.lovedOneList || [];

//       if (user.isAuthorized && lovedOneList.length > 0) {
//         console.log("if")
//         // If user has loved ones, select the first one by default
//         dispatch(changeLovedOne(lovedOneList[0]));
//         // navigate("/MenuScreen", { state: lovedOneList[0] });
//       } else if (user.isAuthorized && !lovedOneList.length && user.profile.self_managed) {
//         console.log("else if")
//         // If user is self-managed LO, navigate directly to MenuScreen
//         dispatch(changeLovedOne(user.profile));
//         // navigate("/MenuScreen", { state: user.profile });
//       } else {
//         // Navigate to LovedOneList to add or select a loved one
//         // navigate("/LovedOneList");
//       }
//     }
//   }, [lovedOne, user]);
//   // if (lovedOne.length > 0) {

//   //   return lovedOne;
//   // }
//   // else {
//   //   return user.profile
//   // }

//   if (!Object.keys(lovedOne).length) {
//     return user.profile;
//   } else {
//     return lovedOne;
//   }
// }

