import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import splashLogo from "assets/images/splashLogo.svg";
import { useDispatch, useSelector } from "react-redux";
import { setData } from "redux/setupStore";
import { data } from "./shopifyOrder";
import { getShopifyOrder } from "api/api";
export default function Authenticate() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const user = useSelector((state) => state.user);

  useEffect(() => {
    if (user.profile) {
      navigate("/");
    } else {
      // setTimeout(() => {
      //   dispatch(setData(data.order));
      //   navigate("/setup/WelcomeScreen");
      // }, [3000]);
      getShopifyOrder(params)
        .then((res) => {
          // console.log("res :>> ", res.data);
          // dispatch(setData(res.data.data));
          let shopifyJson = res.data.data;
          let properties = shopifyJson.line_items[0].properties.length
            ? shopifyJson.line_items[0].properties
            : shopifyJson.line_items[1].properties;
          console.log("properties :>> ", properties);

          shopifyJson.customer_details = properties.length
            ? properties.reduce((obj, item) => {
                let key = item.name.replaceAll(" ", "");
                return {
                  ...obj,
                  [key]: item.value,
                };
              }, {})
            : "";
          dispatch(setData(shopifyJson));
          navigate("/setup/WelcomeScreen");
        })
        .catch((er) => {
          navigate("/");
        });
    }
  }, []);

  return (
    <div className="mainPage purBg">
      <div className="logo spleshBlog">
        <a href="#">
          <img src={splashLogo} style={{ width: "230px" }} />
        </a>
      </div>
    </div>
  );
}
