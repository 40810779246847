import React from "react";
// import useSetupData from "hooks/useSetupData";
import SetupLayout from "./SetUpLayout";
import { useDispatch, useSelector } from "react-redux";
import { handleConfirm } from "redux/setupStore";
import { useNavigate } from "react-router-dom";
import UploadProfilePhoto from "components/UploadProfilePhoto";
import { useFormik } from "formik";
import * as Yup from "yup";
import messages from "utils/messages";

export default function SelectMobileType() {
  // const setupData = useSetupData();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const setupStore = useSelector((state) => state.setup);

  let {
    data: { lovedOne },
  } = setupStore;
  const radio_options = [
    { name: "Post paid", value: "postpaid" },
    { name: "Prepaid", value: "prepaid" },
  ];

  const ContactSchema = Yup.object().shape({
    mobile_type: Yup.string().required(messages.atleastOne.option),
  });

  const formik = useFormik({
    initialValues: {
      mobile_type: setupStore?.data?.lovedOne?.mobile_type || "",
    },
    validationSchema: ContactSchema,

    onSubmit: (values) => {
      handleNext(values);
    },
  });

  const handleNext = async (values) => {
    let payload = {
      data: {
        lovedOne: {
          ...setupStore.data.lovedOne,
          ...values,
        },
      },
    };
    dispatch(handleConfirm(payload));
    navigate(`/setup/PortForm`);
  };
  return (
    <SetupLayout
      heading={`${lovedOne.first_name}'s Mobile Number`}
      backNav="SelectMobile"
    >
      <UploadProfilePhoto handleUpload={false} value={lovedOne.loPrevUrl} />

      <div className="profilTileHeading ">{lovedOne.first_name}</div>

      <div className="contentBody fulogin">
        <div className="row fmsignup">
          <div className="col-lg-12">
            <h2 className="topTileHeading">
              Port {lovedOne.first_name}'s old <br />
              mobile number to BigPurplePhone
            </h2>
            <p>
              Was {lovedOne.first_name}'s previous account a post paid or
              prepaid account?
            </p>
          </div>

          <div className="col-lg-12">
            <div className="mb-4 mt-3">
              <form className="circlebox" onSubmit={formik.handleSubmit}>
                <div className="form-group mb-0 addNextRole">
                  {radio_options.map((option, i) => (
                    <>
                      <input
                        type="radio"
                        name="mobile_type"
                        onChange={formik.handleChange}
                        className="mx-2"
                        value={option.value}
                        id={option.name}
                        checked={option.value === formik.values.mobile_type}
                        key={i}
                      />
                      <label for={option.name}>{option.name}</label>
                      <br />
                    </>
                  ))}
                  {formik.errors["mobile_type"] &&
                  formik.touched["mobile_type"] ? (
                    <div className="error">{formik.errors["mobile_type"]}</div>
                  ) : null}
                </div>

                {/* {formik.values.mobile_type == "postpaid" ? (
                  <div className="col-lg-12">
                    <p>
                      You will need a copy of the phone bill to proceed.{" "}
                      <u> Save for later?</u>
                    </p>
                  </div>
                ) : (
                  ""
                )} */}
                <div className="row">
                  <div className="d-flex mt-3 mb-3">
                    <button
                      type="submit"
                      // onClick={handleNext}
                      className="btn btn-primary w-100  "
                    >
                      Next
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </SetupLayout>
  );
}
