import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { Modal, Spinner } from "react-bootstrap";
import backWhite from "assets/images/back_white.svg";
import noContactImage from "assets/images/no-contact1.svg";
import useLovedOne from "hooks/useSelectedLovedOne";
import { getGallaryPhotos, getLoGalleryPhotos } from "api/api";
import { toast } from "react-toastify";
import LovedOnePhoneName from "components/LovedPhoneName";
import UploadProfilePhoto from "components/UploadProfilePhoto";
import { useErrorHandler } from "react-error-boundary";
import useLoginUser from "hooks/useLoginUser";
import { useSelector } from "react-redux";
import { speakText } from "utils/utils";
import loader from "assets/images/loader.gif"
import useSelectedLovedOne from "hooks/useSelectedLovedOne";


function PFAGallaryImages({ lovedOne }) {
  const navigate = useNavigate();
  const settings = useSelector((state) => state.settings);
  const { profile } = useLoginUser();
  const loved_one = useSelectedLovedOne();
  const [list, setList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const handleError = useErrorHandler();

  const checkLo = profile.lovedOneList.length > 0 && (
    profile.lovedOneList[0].relation === 'Self' || profile.lovedOneList[0].lo_id
  );
  console.log("profile", profile)

  const photoGallery = () => {
    let user_id;
    // if (checkLo) {
    // getLoGalleryPhotos({
    //   page: 1,
    //   data: 2000,
    //   lovedOneId: lovedOne?.id,
    //   role: "lo"
    // })
    //   .then((response) => {
    //     let data = response.data.data;
    //     console.log("galery data", data)
    //     setIsLoading(false);
    //     setList([...data]);
    //   })
    //   .catch((error) => {
    //     return error.response.data.statusCode != 400 ? handleError(error) : "";
    //   });
    // }
    // else {
    if (profile.lovedOneList.length > 0) {
      user_id = profile.lovedOneList[0].id
    }
    // else {
    //   user_id = profile.id;
    // }
    console.log("user_id", user_id, profile)

    getGallaryPhotos({
      user_id: user_id,
      lovedOneId: lovedOne.id,
      // role: checkLo ? "lo" : "users"
      role: "users"
    })
      .then((response) => {
        let data = response.data.data;
        console.log("galery data", data)
        setIsLoading(false);
        setList([...data]);
      })
      .catch((error) => {
        return error.response.data.statusCode != 400 ? handleError(error) : "";
      });
    // }
  };

  useEffect(() => {
    photoGallery();
  }, []);

  console.log("List", list)

  const handleUploadImage = async (photos, previewUrls) => {
    try {
      console.log("photos", photos)
      // speakText
      // const data = await utils.imgsInputFilePicker();

      if (photos.length > 0) {
        navigate("/PFAUploadImage", { state: { photos, previewUrls } });
      } else {
        toast("Please select only image", { type: "error" });
      }
    } catch (error) {
      toast(error, { type: "error" });
    }
  };

  const getPhotoList = () => {
    if (isLoading)
      return (
        // <div>
        //   < img src={loader} alt="Loading..." className="custom-loader" />
        // </div>
        ""
      );

    if (list.length === 0) {
      return (
        <div className=" text-center nocontact">
          <img src={noContactImage} alt="no contact" />
          <h3 className="pt-4">No Photos Found</h3>
        </div>
      );
    }

    return list.map((item, i) => (
      console.log("item", item),
      <div key={item.id} className="col-6">
        <img
          src={item.photo}
          alt=""
          title=""
          onClick={() => {
            navigate(`/PFAGallaryImage/${item.id}`, {
              state: {
                role: item.shared_by_role

              }
            });
          }}
        />
      </div>
    ));
  };
  const getByPlaceholderText = () => {
    if (isLoading)
      return (
        <div className="text-center">
          < img src={loader} alt="Loading..." className="custom-loader" />
        </div>
      );
  }

  return (
    <div className="">
      <div className="mainPage">
        <div className="Homeheaderbg ghBottom">
          <div className="">
            <NavLink to="/MenuScreen">
              <img src={backWhite} alt="" onClick={() => speakText('Back', settings.select_to_speak)} />
            </NavLink>
          </div>
          <div className="customer-info">
            <div className="col-md-10">
              <h1 className="raleway_font">Add/Manage</h1>
              <h1 className="raleway_font">Photos</h1>
            </div>
          </div>
        </div>
        <LovedOnePhoneName loved_one={lovedOne} />{" "}
        <div className="contentBody contentboxgray ">
          <div className="contentbodypadd pt-4">
            <div className="row justify-content-center customprofilegallerybox">
              <UploadProfilePhoto gallery handleUpload={handleUploadImage} profile={loved_one} />

            </div>
            {getByPlaceholderText()}
            <div className="row imageupload text-center">{getPhotoList()}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default function PFAGallaryScreen() {
  const lovedOne = useLovedOne();

  if (!Object.keys(lovedOne).length) return null;

  return <PFAGallaryImages lovedOne={lovedOne} />;
}
