export const nameRegex = /^[a-z A-Z0-9]{1,50}$/;
// export const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;

// ^(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,}$

// export const passwordRegex =
//   /^(?=.*[A-Za-z ])(?=.*\d)(?=.*[@$!%*^'":()#,_=+<>./;?&-])[A-Za-z \d@$!%^'":()*#,_=+<>./;?&-]{6,}$/;
export const passwordRegex =
  /^(?=.*?[a-zA-Z])(?=.*?[0-9])(?=.*?[!@#$%^&=:";',./<>?}{*_+|-]).{8,}$/;
export const phoneRegex = /^[0,6]\d{10}$/;
export const emailRegex = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/;
export const numericRegex = /^-?\d+$/;
export const positiveNumericRegex = /^\d+$/;

export const regex = new RegExp(/^[a-zA-Z]{1,50}$/);
export const pattern = new RegExp(/^[0-9\b]+$/);
