// const test = (value) => {
//   // You can add any logic here to generate a dynamic message
//   // console.log("value sef:>> ", value);
//   value = value ? value.replaceAll(" ", "") : "";
//   let inputStartsWith = value ? value.charAt(0) : 0;
//   let input_length = inputStartsWith == 6 ? 11 : 10;
//   if (value.substring(0, 2) != 61 && inputStartsWith != 0) {
//     return this.createError({
//       message: `Phone number should be starts with 61 or 0`,
//       path: "phone", // Fieldname
//     });
//   } else if (value.length != input_length) {
//     return this.createError({
//       message: `Phone number should be ${input_length} digits`,
//       path: "phone", // Fieldname
//     });
//   } else {
//     return true;
//   }
// };
import PhoneInput, {
  formatPhoneNumber,
  formatPhoneNumberIntl,
  isValidPhoneNumber,
} from "react-phone-number-input";

export const inputValidation = (value, yup = false, isContact = false) => {
  let err = {};
  // value = value ? value.replaceAll(" ", "") : "";
  // let inputStartsWith = value ? value.charAt(0) : 0;
  // let input_length = inputStartsWith == 6 ? 11 : 10;
  console.log("value yup:>> ", value);
  console.log("!!!!!!", isValidPhoneNumber(value))
  if (value == undefined || !value) {
    return {
      isError: true,
      phone: "Please Enter Mobile Number",
    };
  } else if (value && yup && !isValidPhoneNumber(value)) {
    console.log("error hai yup ", isValidPhoneNumber(value));
    let message = `Please Enter Valid Mobile Number`
    if (isContact) {
      message = "Please enter a valid mobile number, and if entering a landline enter the area code.";
    }
    if (yup) {
      return yup.createError({
        message,
        path: "phone", // Fieldname
      });
    } else {
      return {
        isError: true,
        phone: message,
      };
    }
    // } else if (value.length != input_length) {
    //   if (yup) {
    //     return yup.createError({
    //       message: `Phone number should be ${input_length} digits`,
    //       path: "phone", // Fieldname
    //     });
    //   } else {
    //     return {
    //       isError: true,
    //       phone: `Phone number should be ${input_length} digits`,
    //     };
    //   }
    // } else if (value.substring(0, 2) != 61 && inputStartsWith != 0) {
    //   if (yup) {
    //     return yup.createError({
    //       message: `Phone number should be starts with 61 or 0`,
    //       path: "phone", // Fieldname
    //     });
    //   } else {
    //     return {
    //       isError: true,
    //       phone: "Phone number should be starts with 61 or 0",
    //     };
    //   }
  } else {
    console.log("else part yup:>> ");
    if (yup) {
      return true;
    } else {
      return err;
    }
  }
};

// export const textMatch = Yup
