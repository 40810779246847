import React, { useState } from "react";
import { useNavigate, NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import back_white from "../assets/images/back_white.svg";
// import adminUser from "../assets/images/admin-user.svg";
// import editProfile from "../assets/images/editProfile.svg";

import CustomTextField from "../components/CustomTextField";
import { updateProfileDetails } from "../redux/user";
import useLoginUser from "../hooks/useLoginUser";
import { nameRegex } from "../utils/regex";
// import * as utils from "../utils/utils";
import messages from "../utils/messages";
import { updatePFAUserDetails } from "../api/api";
import UploadProfilePhoto from "components/UploadProfilePhoto";
import { useErrorHandler } from "react-error-boundary";

import { textField } from "components/InputFields";
import { useFormik } from "formik";
import * as Yup from "yup";
import useSelectedLovedOne from "hooks/useSelectedLovedOne";
// import CustomPasswordInput from "components/CustomPasswordInput";

export default function PFAEditProfile() {
  const navigate = useNavigate();
  const user = useLoginUser();
  const dispatch = useDispatch();
  const { profile } = useLoginUser();

  console.log("profile in 31", profile)
  // const lovedOne = useSelectedLovedOne();

  const [form, setForm] = useState({
    name: user.profile.name,
    photo: user.profile.photo,
    photoName: "",
  });
  const [formError, setFormError] = useState({ isError: false, name: "" });

  const handleError = useErrorHandler();

  const handleUpload = (photo) => {
    setForm({
      ...form,
      photo,
    });
  };
  const handleSubmitProfile = (values) => {
    let data = new FormData();
    if (form.photo) {
      data.append("photo", form.photo);
    }
    data.append("name", values.name);
    data.append("email", values.email);
    data.append("role", "pfa");

    updatePFAUserDetails(data)
      .then((res) => {
        setTimeout(() => {
          let dispatchedData = {
            name: values.name,
            email: values.email,
          };

          if (form.photo && res.data.data) {
            dispatchedData.photo = res.data.data;
          }
          dispatch(updateProfileDetails(dispatchedData));
          navigate("/PFAProfile");
        }, 2000);
      })
      .catch((error) => {
        return error.response.data.statusCode != 400 ? handleError(error) : "";
      });
  };

  const ProfileSchema = Yup.object().shape({
    name: Yup.string().required(messages.required.name),
    email: Yup.string()
      .email(messages.invalidEmail)
      .required(messages.required.email),
  });
  const formik = useFormik({
    initialValues: {
      name: user.profile.name,
      email: user.profile.email,
      role: "pfa",
    },
    validationSchema: ProfileSchema,

    onSubmit: (values) => {
      const castValues = ProfileSchema.cast(values);
      console.log("castValues", castValues);

      handleSubmitProfile(castValues);
    },
    enableReinitialize: true,
  });
  return (
    <div className="">
      <div className="mainPage">
        <div className="Homeheaderbg text-white" style={{ minHeight: "250px" }}>
          <div className="d-flex align-self-center">
            <NavLink to="/PFAProfile">
              <img src={back_white} alt="" title="" />
            </NavLink>
            <h2 className="fs20 mb-0 text-center mx-auto">Edit Profile</h2>
          </div>
        </div>

        <div className="contentboxgraynotfixedheader">
          <br />
        </div>

        <div className="contentBody contentbodymedia mtminus">
          <UploadProfilePhoto
            prevUrl={user.profile.photo}
            handleUpload={handleUpload}
            // profile={profile.lovedOneList[0]}
            profile={profile}
          />

          <div className="contentBody">
            <form onSubmit={formik.handleSubmit}>
              <div className="row">
                <div className="col-lg-12">
                  <div className="mb-3">
                    {/* <CustomTextField
                    type="name"
                    label="Name"
                    value={form.name}
                    onChange={(e) =>
                      setForm((prev) => ({ ...prev, name: e.target.value }))
                    }
                  />
                  <span style={{ color: "red" }}>{formError.name}</span> */}

                    {textField(
                      formik,
                      "name",
                      false,
                      "Name",
                      "name",
                      "text",
                      50,
                      false,
                      true
                    )}
                  </div>

                  <div className="mb-3 mt-4">
                    {textField(
                      formik,
                      "email",
                      false,
                      "Email Address",
                      "email",
                      "email",
                      50,
                      false,
                      true
                    )}
                  </div>
                  <div className="">
                    <small className="fs12">
                      <span className="pe-2">
                        <b>*=Required fields</b>
                      </span>
                    </small>
                  </div>
                  <button
                    // onClick={onClickUpdate}
                    type="submit"
                    className="btn btn-primary w-100 mt-3"
                  >
                    Update
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
