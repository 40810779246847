import React, { useState, useEffect } from "react";
import { useNavigate, NavLink, useLocation } from "react-router-dom";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import back_white from "../assets/images/back_white.svg";
import { toast } from "react-toastify";
import LovedOnePhoneName from "../components/LovedPhoneName";
import { addReminder, updateReminder } from "../api/api";
import useSelectedLovedOne from "../hooks/useSelectedLovedOne";
import dayjs from "dayjs";
import { useErrorHandler } from "react-error-boundary";
export default function AddReminder() {
  const location = useLocation();
  const navigate = useNavigate();
  const loved_one = useSelectedLovedOne();
  const initial_reminder = location.state
    ? location.state.reminder
    : dayjs().format("hh:mm A");

  const initial_hour = initial_reminder.substr(0, 2);
  const initial_minute = initial_reminder.substr(3, 2);
  const [reminder, setReminder] = useState(initial_reminder);
  const [hour, setHour] = useState(initial_hour);
  const [minute, setMinute] = useState(initial_minute);
  const [title, setTitle] = useState(
    location.state ? location.state.title : ""
  );
  const [days, setDays] = useState(
    location.state ? location.state.days : [0, 0, 0, 0, 0, 0, 0]
  );
  const [errors, setError] = useState({});
  const handleError = useErrorHandler();
  const handleReminder = async () => {
    let data = {
      lovedOneId: loved_one.id,
      title,
      reminder,
      days: JSON.stringify(days),
    };
    let is_error = { ...errors };
    if (days.every((d) => d === 0)) {
      let current_time = dayjs().format("hh:mm A");
      let current_date = dayjs().format("YYYY-MM-YY");
      const fromdateString = `${current_date} ${reminder}`
        .replace(/-/g, "/")
        .replace("T", " ");
      const todateString = `${current_date} ${current_time}`
        .replace(/-/g, "/")
        .replace("T", " ");
      console.log("fromdateString", fromdateString);
      console.log("todateString", todateString);
      const from_time = dayjs(fromdateString); //set by reminder
      const to_time = dayjs(todateString); //current time
      console.log("set by remindertime :>> ", from_time);
      console.log("current time :>> ", to_time);
      const diff = from_time.diff(to_time, "minutes", true);
      console.log("diff time should be more than 0:>> ", diff);
      is_error.time = diff > 0 ? false : true;
    }
    console.log("is_error :>> ", is_error);
    // check if any field has error
    let isValidateFields = Object.values(is_error).every(
      (item) => item == false
    );
    if (isValidateFields) {
      let callApi = addReminder;
      if (location.state) {
        callApi = updateReminder;
        data.id = location.state.id;
      }
      callApi(data)
        .then((result) => {
          console.log(result);
          navigate("/PFAManageReminder");
        })
        .catch((error) => {
          return error.response.data.statusCode != 400
            ? handleError(error)
            : "";
        });
    } else {
      if (is_error.time) {
        toast("Can't set Reminder for time in the past", { type: "error" });
      }
    }
  };

  const getDay = (index) => {
    switch (index) {
      case 0:
        return "M";
      case 1:
        return "T";
      case 2:
        return "W";
      case 3:
        return "T";
      case 4:
        return "F";
      case 5:
        return "S";
      case 6:
        return "S";
      default:
        return "M";
    }
  };

  console.log("hour :>> ", hour);
  console.log("minute :>> ", minute);
  const handleReminderChange = (time) => {
    let reminder_time = "";

    if (time && typeof time == "object") {
      reminder_time = time.format("hh:mm A");
    } else {
      reminder_time = time;
    }

    let c_hour = reminder_time.substr(0, 2);
    let c_minute = reminder_time.substr(3, 2);
    setReminder(reminder_time);
    setHour(c_hour);
    setMinute(c_minute);
  };
  const handleChangeTitle = (e) => {
    let t = e.target.value;
    setTitle(t);

    if (t.length > 25) {
      setError({
        title: true,
      });
    } else {
      setError({
        title: false,
      });
    }
  };

  const handleReminderInputChange = (event) => {
    const { name, value } = event.target;
    let time_str = reminder.split(" ");
    if (name == "hour") {
      let h = Math.max(0, Math.min(12, Number(value)));

      time_str[0] = `${h}:${minute}`;

      setHour(h);
    } else {
      let m = Math.max(0, Math.min(59, Number(value)));
      console.log("value :>> ", value);
      if (m < 9) {
        m = `0${m}`;
      }
      time_str[0] = `${hour}:${m}`;
      setMinute(m.toString());
    }

    setReminder(`${time_str[0]} ${time_str[1]}`);
  };
  console.log("minute :>> ", minute);
  return (
    <div className="pt80">
      <div className="mainPage">
        <div className="Homeheaderbg headerfixed text-white">
          <div className="d-flex align-self-center">
            <NavLink to="/PFAManageReminder">
              <img src={back_white} alt="" title="" />
            </NavLink>
            <h2 className="fs20 mb-0 text-center mx-auto">Add Reminders</h2>
          </div>
        </div>

        <LovedOnePhoneName loved_one={loved_one} />

        <div className="contentBody contentboxgray  contentbodymedia">
          <div className="d-flex reminderInput pt-3">
            <div className="timeBlog ml5">
              <label>
                <b>Enter Hour</b>
              </label>
              <input
                name="hour"
                className="form-control "
                value={hour}
                onChange={handleReminderInputChange}
                type="number"
                min="1"
                max="12"
              />{" "}
            </div>
            <div className="timeBlog ml5">
              <label>
                <b>Enter Minute</b>
              </label>
              <input
                type="number"
                name="minute"
                className="form-control"
                onChange={handleReminderInputChange}
                value={minute}
              />
            </div>
          </div>
          <Datetime
            open={true}
            dateFormat={false}
            onChange={handleReminderChange}
            value={reminder}
          />
          <div className="mb-3">
            <div className="inputIcon">
              <h5>Title</h5>
              <textarea
                onChange={handleChangeTitle}
                id="msz"
                name="msz"
                rows="2"
                width="100%"
                placeholder="Enter Here.."
                maxLength="30"
                value={title}
              ></textarea>
              {errors.title ? (
                <span className="error">
                  Character limit is upto 25 Characters
                </span>
              ) : (
                ""
              )}
            </div>
            {/* ======================================Days Select================================== */}
            <div className="mb-3 mt-4">
              <div className="inputIcon">
                <h5>Repeat</h5>
                <div className="circledays mt-3">
                  {days.map((day, i) => {
                    return (
                      <div
                        onClick={() => {
                          const newDays = days.map((selected, idex) => {
                            if (idex === i) {
                              return selected ? 0 : 1;
                            } else {
                              return selected;
                            }
                          });

                          setDays(newDays);
                        }}
                        key={i}
                        className={`day1 ${day ? "dayselect" : ""}`}
                      >
                        {getDay(i)}
                      </div>
                    );
                  })}
                </div>
                <div className="d-flex mt-5 mb-3">
                  <button
                    type="button"
                    onClick={() => {
                      navigate("/PFAManageReminder");
                    }}
                    className="btn btn-primary w-100 me-2 btnwhite textred"
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    onClick={handleReminder}
                    className="btn btn-primary w-100 ms-2 "
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- <div className="bottomLogo">
            <a href="#"><img src="assets/images/bigPurplePhoneLogo.svg"></a>
         </div>--> */}
      </div>
      {/* <!-- Js --> */}
      {/* <script src="assets/js/bootstrap.min.js"></script> */}
    </div>
  );
}
