import { toast } from "react-toastify";

export const wrapper = async (fn, showError = false) => {
  try {
    return await fn();
  } catch (error) {
    if (showError) {
      toast(error.response.data.message, { type: "error" });
    }
    throw error;
  }
};
