import { useEffect, useState } from "react";
import help from "assets/images/help3cx.png";

import { getWebSetting } from "api/api";

const HelpSupport = () => {
  const [html, setHtml] = useState("");
  useEffect(() => {
    getWebSetting().then((res) => {
      const { data, statusCode } = res.data;
      console.log("data :>> ", data);
      if (data.length) {
        setHtml(data[0]["3cx_html"]);
      }
    });
  }, []);
  // useEffect(() => {
  //   if (document && html) {
  //     appendSdkScript();
  //   }
  // }, [html]);

  // const appendSdkScript = () => {
  //   const script = document.createElement("script");
  //   script.id = "tcx-callus-js";
  //   script.src =
  //     //dev // "https://downloads-global.3cx.com/downloads/livechatandtalk/v1/callus.js";
  //     "https://cdn.3cx.com/livechat/v1/callus.js";
  //   script.async = true;
  //   script.defer = true;
  //   // script.crossOrigin = "anonymous";
  //   script.onload = () => true;
  //   document.body.append(script);
  // };

  return (
    <div className="help-support">
      <a href="tel:+61291886998">
        <img src={help} />
      </a>
      {/* <span dangerouslySetInnerHTML={{ __html: html }} /> */}
    </div>
  );
};

export default HelpSupport;
