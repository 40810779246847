import React, { useEffect } from "react";
import HelpSupport from "components/HelpSupport.js";

export default function LOHelpSupport() {
  return (
    <body className="pt80">
      <div className="mainPage">
        <div className="Homeheaderbg headerfixed text-white">
          <div className="d-flex align-self-center">
            <h2 className="fs20 mb-0 text-center mx-auto">Help/Support</h2>
          </div>
        </div>
        <div className="parent">
          <div className="contentboxgray11">
            <br />
          </div>
        </div>

        <div className="contentBody contentbodymedia">
          <div className="contentBody">
            <h3 className="fsregular">Connect With Us</h3>
            <div className="row pt-3 support">
              <div className="col-lg-12">
                <b>
                  Please click on the image below to be connected to the
                  BigPurplePhone Customer Care Team or call us on{" "}
                  <a href="tel:+61286601390">(02) 8660 1390.</a>
                </b>
              </div>

              <HelpSupport />
            </div>
          </div>
        </div>
      </div>
    </body>
  );
}
